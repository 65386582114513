import { connect } from 'react-redux';
import * as tradeInComponentActionCreators from '../../../store/actionCreators';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

import { ManualEntryUI, IManualEntryUIStateProps, IManualEntryUIDispatchProps, IManualEntryProps } from './ManualEntryUI';

// utils
import { isShowroomExperience } from '../../../utils/configUtils';
import { STEPPER_ROUTES } from '../../../utils';
import {
    getExternalTradeInConditions,
    getTradeInSelectors,
    getUniqueEstimateOption,
    processStepperRoutes
} from '../../../utils/tradeInDecisionLogic';
import * as tradeInRoutes from '../../../utils/routes';

// interfaces/types
import { IRootProps } from '../../../components/RootProps';

const mapStateToProps = (state: any, ownProps: IRootProps): IManualEntryUIStateProps => {
    const value = tradeInSelectors.getTradeInValue(state.tradeInComponent);
    const source = tradeInSelectors.getValueSource(state.tradeInComponent);
    const comments = tradeInSelectors.getValueComments(state.tradeInComponent);
    const isSalesView = isShowroomExperience(ownProps.config);
    const stepperRoutes = processStepperRoutes(state, STEPPER_ROUTES.detailsSubmitForAppraisal, isSalesView);
    const tradeInSubaruGtpFlowError = tradeInSelectors.getTradeInSubaruGtpFlowError(state);
    const externalTradeInConditions = getExternalTradeInConditions(state);
    const tradeInAssertionSelectors = getTradeInSelectors(state, isSalesView);
    const uniqueEstimateOption = getUniqueEstimateOption(tradeInAssertionSelectors, isSalesView);
    const backRoute =
        externalTradeInConditions.isPartialTrade || uniqueEstimateOption.hasUniqueOption
            ? tradeInRoutes.TRADE_VEHICLE_INFO
            : tradeInRoutes.TRADE_IN_DECISION;

    return {
        value,
        source,
        comments,
        stepperRoutes,
        backRoute,
        tradeInSubaruGtpFlowError
    };
};

const mapDispatchToProps = (dispatch: any): IManualEntryUIDispatchProps => {
    dispatch(tradeInComponentActionCreators.shopperInManualEntry());
    return {
        next: (payload: IManualEntryProps) => {
            dispatch(tradeInComponentActionCreators.updateTradeValueSource(payload));
            dispatch(tradeInComponentActionCreators.updateTradeInValuation());
            dispatch(tradeInComponentActionCreators.saveOrUpdateTradeIn());
            dispatch(tradeInComponentActionCreators.valuePageClikedTradein());
        },
        goToRoute: (route: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(route));
        },
        removeTrade: () => {
            dispatch(tradeInComponentActionCreators.removeTradeIn());
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN));
        }
    };
};

const ManualEntry = connect(mapStateToProps, mapDispatchToProps)(ManualEntryUI);

export default ManualEntry;

// externals
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as leadFormSelectors from '../../../store/mmd/leadForm';
import * as widgetSelectors from '../../../store/mmd/widgetSelectors';
import * as mmdSelectors from '../../../store/mmd/mmd';

// actions
import * as tradeInActionCreators from '../../../store/actionCreators';

// interfaces/types
import type { EquipmentOptionsUIStateProps, EquipmentOptionsUIDispatchProps } from './EquipmentOptionsUI';
import { IRootProps } from '../../../components';

// components
import EquipmentOptionsUI from './EquipmentOptionsUI';

// utils
import { isShowroomExperience } from '../../../utils/configUtils';
import { processStepperRoutes } from '../../../utils/tradeInDecisionLogic';
import * as tradeInRoutes from '../../../utils/routes';
import { STEPPER_ROUTES } from '../../../utils';

const excludeOptions = {
    Engine: true,
    Transmission: true,
    DriveTrain: true
};

export const mapStateToProps = (state: any, ownProps: IRootProps): EquipmentOptionsUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as any;
    const vehicle = tradeInSelectors.getTradeInVehicle(tradeIn);
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);
    const tradeSaveFailure = tradeInSelectors.isTradeSaveFailed(state);
    const tradeSavePending = tradeInSelectors.isTradeSavePending(state);
    const paymentFailure = tradeInSelectors.getTradePaymentFailed(state);
    const paymentCalculating = tradeInSelectors.getIsPaymentCalculating(state);
    const vehicleOptionsClone = cloneDeep(vehicleOptions);
    const shouldIncludeTradeIn = tradeInSelectors.getAmountAppliedToFinancing(tradeIn);
    const vehicleOptionsArray = Object.keys(vehicleOptionsClone)
        .map((key) => ({ key, value: vehicleOptionsClone[key] }))
        .filter((section) => !excludeOptions[section.key])
        .sort((a, b) => {
            if (a.key > b.key) return 1;
            else if (a.key < b.key) return -1;
            else return 0;
        })
        .map((section) => {
            section.value.options = section.value.options.map((option, index) => ({
                ...option,
                originalIndex: index
            }));

            section.value.options.sort((a, b) => {
                if (a.key > b.key) return 1;
                else if (a.key < b.key) return -1;
                else return 0;
            });

            return section;
        });

    const conflict = tradeInSelectors.getTradeInOptionInConflict(tradeIn);
    const windowAsAny = window as any;
    const isIE = windowAsAny.detectIE && windowAsAny.detectIE();
    const hasLeadFormSubmitted = leadFormSelectors.isLeadFormSubmittedSuccess(state);

    const useWaitForEstimateResultLogic =
        mmdSelectors.getGlobalSelector().additionalSelectors?.configSelectors?.useWaitForEstimateResultLogic?.(state) || false;
    const waitingForEstimateSuccess = useWaitForEstimateResultLogic && tradeInSelectors.isEquipOptionsEstimate1InProgress(tradeIn);

    const isSalesView = isShowroomExperience(ownProps.config);
    const stepperRoutes = processStepperRoutes(state, STEPPER_ROUTES.detailsKbbEquipment, isSalesView);

    return {
        vehicleOptionsArray,
        isDealerUser: widgetSelectors.isDealerUser(state),
        vehicle,
        conflict,
        isIE,
        hasLeadFormSubmitted,
        stepperRoutes,
        tradeSaveFailure,
        tradeSavePending,
        shouldIncludeTradeIn,
        paymentFailure,
        paymentCalculating,
        waitingForEstimateSuccess
    };
};

export const mapDispatchToProps = (dispatch: any): EquipmentOptionsUIDispatchProps => ({
    onValueChange: (categoryName, optionValueId, isChecked) => {
        dispatch(tradeInActionCreators.changeEquipmentOption(categoryName, optionValueId, isChecked));
    },
    next: () => {
        dispatch(tradeInActionCreators.applyTradeInEquipmentOptions('PAGETWO'));
        dispatch(tradeInActionCreators.saveOrUpdateTradeIn());
    },
    goToRoute: (route: string) => {
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(route));
    },
    dispatchAnalytics: (type, description) => {
        dispatch(tradeInActionCreators.dispatchAnalytics(type, description));
    },
    showVehicleEquipment: (showAll: boolean) => {
        dispatch(tradeInActionCreators.toggleShowAllVehicleEquipment(showAll));
    },
    removeTradeIn: () => {
        dispatch(tradeInActionCreators.removeTradeIn());
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN));
    }
});

const EquipmentOptions = connect(mapStateToProps, mapDispatchToProps)(EquipmentOptionsUI);

export default EquipmentOptions;

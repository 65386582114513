// externals
import { connect } from 'react-redux';

// libraries
import { Resources } from '@makemydeal/dr-activities-common';
import { urlBuilder } from '@makemydeal/dr-common-utils';

// utils
import * as tradeInRoutes from '../../../utils/routes';
import * as tradeInDecisionLogic from '../../../utils/tradeInDecisionLogic';
import { isShowroomExperience } from '../../../utils/configUtils';
import { handleNavigationToManualEntry } from '../../../utils/tradeInDecisionLogic';
import { tradeInMethod } from '../../../utils/tradeInDecisionLogic';

// components
import TradeInDecisionUI, { ITradeInDecisionUIStateProps, ITradeInDecisionUIDispatchProps } from './TradeInDecisionUI';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as dealerSelectors from '../../../store/mmd/dealerSelectors';

import featureToggles from '../../../store/featureToggles';

// interfaces/types
import { IRootProps } from '../../../components/RootProps';
import { SelectedOption, TradeInAssertionSelectors, TradeInSubaruGtpFlowErrorType } from '../../../types';

// config
import config from '../../../store/mmd/config';

const mapStateToProps = (state: any, ownProps: IRootProps): ITradeInDecisionUIStateProps => {
    const headerImageKBB = urlBuilder.buildFromConfig(config.staticImages, Resources.KBB_LOGO_HD);
    const headerImageGTP = urlBuilder.buildFromConfig(config.staticImages, Resources.SUBARU_GTP_LOGO);
    const isDevHelperEnabled = featureToggles.isDevHelperEnabled(state);
    const dealerName = dealerSelectors.getDealerName(state);

    const isSubaruGtpEligible = tradeInSelectors.isSubaruGtpEligible(state);
    const tradeIn = tradeInSelectors.getTradeInComponent(state);
    const {
        year,
        model: { name },
        vin,
        mileage
    } = tradeInSelectors.getTradeInVehicle(tradeIn);
    const amountOwed = tradeInSelectors.getTradeInAmountOwed(tradeIn);
    const shouldIncludeTradeIn = tradeInSelectors.getAmountAppliedToFinancing(tradeIn);
    const eligibilityFields = {
        amountOwed,
        mileage,
        shouldIncludeTradeIn,
        vin,
        fallbackAction: undefined,
        isUniqueEstimateOption: false,
        isSummaryNextScreen: true
    };
    let pageTitle = 'Add a Trade-In';
    let pageSubTitle = null;
    let pageSubtitleLink = null;

    if (isSubaruGtpEligible) {
        pageTitle = `Your ${year} ${name} is eligible for the Subaru Guaranteed Trade-In Program`;
        pageSubTitle = 'Or you can choose another method to find the value of your vehicle.';
    }

    const tradeInSubaruGtpFlowError = tradeInSelectors.getTradeInSubaruGtpFlowError(state);

    switch (tradeInSubaruGtpFlowError?.errorType) {
        case TradeInSubaruGtpFlowErrorType.MILEAGE_ERROR: {
            pageTitle = `Your ${year} ${name} is not eligible for the Subaru Guaranteed Trade-In Program`;
            pageSubTitle = 'The mileage entered may not qualify for GTP at this time, please contact your retailer.';
            pageSubtitleLink = '';
            break;
        }
        case TradeInSubaruGtpFlowErrorType.API_ERROR: {
            pageTitle = `Your ${year} ${name} is not eligible for the Subaru Guaranteed Trade-In Program`;
            pageSubTitle = `${
                tradeInSubaruGtpFlowError?.errorMessage ||
                'The VIN entered is not a valid VIN or could not be found in Subaru systems. Please check for spaces or special characters in the entry field.'
            } `;
            pageSubtitleLink = 'Go Back and Retry';
            break;
        }
        case TradeInSubaruGtpFlowErrorType.LEASE_ERROR: {
            pageTitle = `Your ${year} ${name} is not eligible for the Subaru Guaranteed Trade-In Program`;
            pageSubTitle =
                'Active leased vehicles do not qualify for the program. If you have questions, you can contact our dealership or ';
            pageSubtitleLink = 'Go Back and Retry';
            break;
        }
    }

    const isShopperEnteredTradeEnabled = featureToggles.isShopperEnteredTradeEnabled(state);
    const isSalesView = isShowroomExperience(ownProps.config);
    const tradeInAssertionSelectors: TradeInAssertionSelectors = tradeInDecisionLogic.getTradeInSelectors(state, isSalesView);
    const { isShowAppraisalValueScreenSalesView, isTradeInValuationProviderGTP } = tradeInAssertionSelectors;
    const optionsToShow: tradeInMethod[] = tradeInDecisionLogic.getOptionsToShow(tradeInAssertionSelectors, isSalesView);
    const { selectedOptionValue, selectedOptionIndex }: SelectedOption = tradeInDecisionLogic.getSelectedOption(
        state,
        optionsToShow,
        isTradeInValuationProviderGTP,
        isSalesView
    );

    return {
        pageTitle,
        pageSubTitle,
        pageSubtitleLink,
        headerImageKBB,
        headerImageGTP,
        isDevHelperEnabled,
        isShopperEnteredTradeEnabled,
        optionsToShow,
        selectedOption: selectedOptionValue,
        selectedOptionIndex,
        dealerName,
        isSalesView,
        isShowAppraisalValueScreenSalesView,
        eligibilityFields,
        isSubaruGtpEligible,
        tradeInSubaruGtpFlowError
    };
};

export const mapDispatchToProps = (dispatch: any): ITradeInDecisionUIDispatchProps => {
    return {
        goToKBBEstimate: (option: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_CONDITION));
            dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(option));
        },
        goToICOFlow: (option: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN_ICO));
            dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(option));
        },
        goToGTPFlow: (option: string, elegibilityFields) => {
            dispatch(tradeInComponentActionCreators.subaruEligibilitySummaryClick(elegibilityFields));
            dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(option));
        },
        goToTradeManualEntryFlow: (option: string, isSalesView, isShowAppraisalValueScreenSalesView: boolean) => {
            handleNavigationToManualEntry(isSalesView, isShowAppraisalValueScreenSalesView, dispatch);
        },
        trackOption: (option: string, isShopperEnteredTradeEnabled: boolean) => {
            dispatch(tradeInComponentActionCreators.sendTradeDecisionChangedAnalytics(option, isShopperEnteredTradeEnabled));
        },
        dispatchImpressionTag: () => {
            dispatch(tradeInComponentActionCreators.sendTradeDecisionDisplayedAnalytics());
        },
        updateSource: (source) => {
            dispatch(tradeInComponentActionCreators.updateTradeInSource(source));
        },
        goToRoute: (route: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(route));
        },
        subaruGoBackAndRetry: () => {
            dispatch(tradeInComponentActionCreators.resetTradeInSubaruGtpFlowError());
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_INFO));
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        },
        removeTrade: () => {
            dispatch(tradeInComponentActionCreators.removeTradeIn());
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN));
        },
        goBack: () => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_INFO));
        }
    };
};

const TradeInDecision = connect(mapStateToProps, mapDispatchToProps)(TradeInDecisionUI);

export default TradeInDecision;

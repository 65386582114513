// externals
import { push } from 'connected-react-router';
import { RSAA } from 'redux-api-middleware';

// libraries
import { BaseMiddleware, ITradeInState, navigateTo } from '@makemydeal/dr-activities-common';
import { logNewRelic, NrSpaApiEnum } from '@makemydeal/dr-activities-common';
import { onCloseLeadFormModal, onLeadFormSubmissionContinue, setLeadFormError } from '@makemydeal/dr-activities-lead-form';
import { getExternalAnalyticsPayload, validator } from '@makemydeal/dr-platform-shared';

// interfaces/types
import type {
    IHandleTradeInSearchNextAction,
    ISubaruEligibilitySummaryCheckFailureAction,
    ISubaruEligibilitySummaryCheckSuccessAction,
    ISubaruEligibilitySummaryClickAction,
    ISubaruEligibilityAction
} from './actionCreators';
import type { ActivityStateTree } from '../types/ActivityStateTree';
import type { IConfigTradeIn } from '../types/IConfigTradeIn';

// consts/enums
import { TradeInSubaruGtpFlowErrorType } from '../types';
import { Actions as types, analyticsActions } from './actions';
import { CALLSITE_SEARCH_2 } from '../utils/constants';
import { RouteValues } from '../utils/constants';
import { TradeInEvaluationStep } from './actionCreators';
import * as tradeInRoutes from '../utils/routes';

// actions
import * as actions from './actionCreators';

// selectors
import { getConnectionId } from './mmd/connectionSelectors';
import { getNextCardDetails } from './mmd/navigationCard';
import {
    getDealerTradeInValuationProvider,
    isDealerTradeInLeadFormForListingSiteEnabled,
    isDealerTradeInLeadFormForWebsiteEnabled,
    isSubaruGtpEnabled,
    microSiteEnabled
} from './mmd/dealerSelectors';
import { getHasBeenSent, getCurrentOfferType, isMonthlyPaymentInvalid } from './mmd/offerSelectors';
import { getSponsor, isDealerUser } from './mmd/widgetSelectors';
import { getVehicleVin } from './mmd/vehicle';
import { goToSummaryLeadForm, goToNext, submitDealSummary, submitOffer } from './actionCreators';
import { Selectors } from './selectors';
import { setGlobalSelector } from './mmd/mmd';
import * as featureToggleSelectors from './featureToggles';
import * as globalConfigFeatureToggleSelectors from './globalConfigFeatureToggles';
import * as leadFormSelectors from './mmd/leadForm';
import * as tradeInComponentSelectors from './mmd/tradeIn';

// actions
import * as tradeInActionCreators from './actionCreators';

// utils
import { createCdkTradeRequest, createTradeRequest, createOemCdkTradeRequest, removeTradeRequest } from '../utils/apiClient';
import { hasOptionsData, getTradeInValuation, hasVehicleOptChanged } from '../utils/tradeUtils';
import * as browserHelper from '../utils/browserHelper';
import { TRADE_IN_DECISION } from '../utils/routes';

// config
import { setGlobalConfig } from './mmd/config';

type IStateTree = any;

const actionWhiteList = [
    types.APPLY_AMOUNT_OWED_AND_UPDATE_VALUE,
    types.APPLY_TRADE_IN_EQUIPMENT_OPTIONS,
    types.APPLY_TRADE_IN_SELECTION,
    types.CHOOSE_TRADE_IN,
    types.COMPLETE_TRADE_IN,
    types.COMPLETION_PAGE_EDIT_CLICKED,
    types.COMPLETION_TERMS_DETAILS_CLICKED,
    types.GO_TO_TRADE_IN_FLOW,
    types.HANDLE_TRADE_IN_SEARCH_NEXT,
    types.HANDLE_TRADE_IN_SEARCH_SET_VEHICLE,
    types.HANDLE_TRADE_IN_SET_VEHICLE_NAVIGATION,
    types.LEAD_FORM_SUBMISSION_SUCCESS,
    types.LOOKUP_ICO_TRADE_FAILURE,
    types.LOOKUP_ICO_TRADE_SUCCESS,
    types.OEM_TRADE_IN_SEND_FAILURE,
    types.OEM_TRADE_IN_SEND_SUCCESS,
    types.OEM_TRADE_IN_UPDATE,
    types.OFFER_SUBMISSION_SUCCESS,
    types.PAYMENT_FAILURE,
    types.PAYMENT_RECEIVED,
    types.REDESIGN_TRADE_IN_FINANCE_TYPE_CHANGED,
    types.REMOVE_TRADE_IN,
    types.ROUTE_TRADE_IN,
    types.SAVE_COUNTEROFFER,
    types.SKIP_TRADE_IN,
    types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_FAILURE,
    types.SUBARU_ELIGIBILITY_CHECK_REQUEST,
    types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_SUCCESS,
    types.SUBARU_ELIGIBILITY_SUMMARY_CLICK,
    types.SUBARU_ELIGIBILITY_CHECK_FAILURE,
    types.SUBARU_ELIGIBILITY_CHECK_SUCCESS,
    types.SUBARU_ELIGIBILITY_CLICK,
    types.SUBARU_ELIGIBILITY_FALLBACK,
    types.SUBARU_ELIGIBILITY_FALLBACK_SALESVIEW_NO_APPRAISAL,
    types.SUBARU_GTP_RULES_CLICKED,
    types.SUMMARY_EDIT_TRADE_IN_CLICKED,
    types.SUMMARY_REMOVE_TRADE_IN_CLICKED,
    types.SUMMARY_START_TRADE_IN_CLICKED,
    types.TRADE_IN_ADD_ICO_VALUE,
    types.TRADE_IN_ANALYTICS,
    types.TRADE_IN_CONDITION_APPLIED,
    types.TRADE_IN_CONDITION_SELECTED,
    types.TRADE_IN_DECISION_CLICKED,
    types.TRADE_IN_DECISION_PAGE_DISPLAYED,
    types.TRADE_IN_DECISION_TYPE_CHANGED,
    types.TRADE_IN_EQUIPMENT_DISPLAY,
    types.TRADE_IN_ESTIMATE_DISPLAYED,
    types.TRADE_IN_ESTIMATE_RECEIVED,
    types.TRADE_IN_ESTIMATION_SUCCESS,
    types.TRADE_IN_FINANCE_BALANCE_CHANGED,
    types.TRADE_IN_FINANCE_TYPE_CHANGED,
    types.TRADE_IN_ICO_ACTIVE_LOAN,
    types.TRADE_IN_ICO_BALANCE_CARD_DISPLAYED,
    types.TRADE_IN_ICO_BALANCE_CHANGED,
    types.TRADE_IN_ICO_BALANCE_CLICKED,
    types.TRADE_IN_ICO_DISPLAYED,
    types.TRADE_IN_ICO_EXIT_CLICKED,
    types.TRADE_IN_LEADFORM_SUBMITTED,
    types.TRADE_IN_LOAN_STATUS_APPLIED,
    types.TRADE_IN_MAKES_FAILURE,
    types.TRADE_IN_MODELS_FAILURE,
    types.TRADE_IN_OPENED,
    types.TRADE_IN_REMOVE_SUCCESS,
    types.TRADE_IN_SAVE_OR_UPDATE,
    types.TRADE_IN_SEARCHTYPE_CLICKED,
    types.TRADE_IN_SEARCH_CLICKED,
    types.TRADE_IN_SEND_FAILURE,
    types.TRADE_IN_SEND_SUCCESS,
    types.TRADE_IN_SKIPPED,
    types.TRADE_IN_TRIMS_FAILURE,
    types.TRADE_IN_VEHICLE_MAKE_CHANGED,
    types.TRADE_IN_VEHICLE_MILEAGE_CHANGED,
    types.TRADE_IN_VEHICLE_MODEL_CHANGED,
    types.TRADE_IN_VEHICLE_OWNERSHIP_CHANGED,
    types.TRADE_IN_VEHICLE_TRIM_CHANGED,
    types.TRADE_IN_VEHICLE_YEAR_CHANGED,
    types.TRADE_IN_VEHICLE_ZIPCODE_CHANGED,
    types.TRADE_IN_YEARS_FAILURE,
    types.TRADE_IN_VALUE_PAGE_CLICKED,
    types.UPDATE_TRADE_IN,
    types.UPDATE_TRADE_IN_AMOUNT_FINANCED,
    types.UPDATE_TRADE_IN_AMOUNT_OWED,
    types.UPDATE_TRADE_IN_CURRENT_LOCATION,
    types.UPDATE_TRADE_IN_EQUIPMENT_OPTION,
    types.UPDATE_TRADE_IN_VALUATION,
    types.UPDATE_VEHICLE_AND_REDIRECT_TO_TRADE_IN,
    types.SKIP_TRADE_IN,
    types.HANDLE_TRADE_IN_SET_VEHICLE_NAVIGATION
];

const userIsInTradeInFlow = (config) => {
    return (
        Object.keys(tradeInRoutes)
            .map((key) => tradeInRoutes[key])
            .indexOf(config.utils.getCurrentLocation()) > -1
    );
};

const actionInWhiteList = (action) => actionWhiteList.find((name) => name === action.type);

const userIsOnTradeInSummary = (config) => {
    return (
        config.utils.getCurrentLocation() === tradeInRoutes.TRADE_SUMMARY ||
        config.utils.getCurrentLocation() === tradeInRoutes.TRADE_IN_ICO_SUCCESS
    );
};

const completeTradeDispatches = (state, store, config) => {
    const tradeInValuation = getTradeInValuation(state);
    const tradeInObj = tradeInComponentSelectors.getTradeInForOffer(state.tradeInComponent);
    store.dispatch(actions.updateTradeIn(tradeInObj));
    store.dispatch(config.offerActionCreators.updatedTradeIn(tradeInValuation));
    store.dispatch(actions.updateTradeInCompletedState());
};

const saveOrUpdateTradeIn = (state, store, config, selectors) => {
    const tradeServiceEnabled = featureToggleSelectors.isTradeServiceEnabled(state);
    const isTradeUpdateRequired = tradeInComponentSelectors.isTradeUpdateRequired(state);
    const isAmdInitDevoEnabled = featureToggleSelectors.isAmdInitDevoEnabled(state);
    const { routeSelectors } = selectors.additionalSelectors;
    const salesView = routeSelectors.isDealerShowroomExperience(state);
    const ddpView = routeSelectors.isDDPPage(state);
    const vin = getVehicleVin(state);

    const handleNoVin = !vin;
    const shouldSkipTradeCreateWithoutVin = salesView || ddpView || (isAmdInitDevoEnabled && !salesView && !ddpView);
    const shouldSkipTradeCreate = handleNoVin && shouldSkipTradeCreateWithoutVin;

    if (tradeServiceEnabled && !shouldSkipTradeCreate) {
        const actionType = isTradeUpdateRequired ? 'update' : 'add';
        const isTradeCdkCrudEnabled = featureToggleSelectors.isTradeCdkCrudEnabled(state);
        const request = isTradeCdkCrudEnabled ? createCdkTradeRequest(state) : createTradeRequest(state, actionType);

        store.dispatch({
            [RSAA]: request
        });
    } else {
        completeTradeDispatches(state, store, config);
    }
};

const updateOemTradeIn = (state, store) => {
    const request = createOemCdkTradeRequest(state);

    store.dispatch({
        [RSAA]: request
    });
};

const dispatchActionWithAnalytics = (type: string, description: string, store) => {
    const analyticsAction = {
        type,
        meta: {
            middleware: {
                analytics: {
                    name: description
                }
            }
        }
    };
    store.dispatch(analyticsAction);
};

export class Middleware extends BaseMiddleware {
    constructor(
        public selector: Selectors,
        public config: IConfigTradeIn,
        public baseRoute?: string,
        public analyticsAction?: (store: any, key: string, desc: string) => void
    ) {
        super(selector, analyticsAction);
        setGlobalSelector(selector);
        setGlobalConfig(config);
    }

    private isP202RedesignEnabled(state) {
        const selectorPropIsP202RedesignEnabled = this.selector.additionalSelectors.configSelectors.isP202RedesignEnabled(state);
        const isP202RedesignEnabled =
            selectorPropIsP202RedesignEnabled || globalConfigFeatureToggleSelectors.isP202RedesignEnabled();
        return isP202RedesignEnabled;
    }

    public sendRelayMessage = (name: string, tradeInComponent, state): void => {
        const toggles = featureToggleSelectors.getFeatureToggleState(state);
        if (toggles.isTradeInRelayEnabled && this.isP202RedesignEnabled(state)) {
            const tradeIn = tradeInComponentSelectors.getTradeInForOffer(tradeInComponent);
            const vehicle = this.selector.additionalSelectors.offerSelectors.getAnalyticsPayloadVehicleDetails(state);
            const offerType = getCurrentOfferType(state);
            const relayMsgPayload = {
                eventAction: 'DR_TRADEIN_ACTION',
                eventData: {
                    ...tradeIn,
                    dealType: offerType,
                    dr: getExternalAnalyticsPayload(vehicle)
                },
                eventName: name // Ex. TRADE_IN_VEHICLE_MODEL_CHANGED
            };

            this.config.hostActions.relayTradeInEvents(relayMsgPayload);
        }
    };

    public middlewareFunction = (store: any, _next: any, action: any) => {
        this.handleTradeInLeadActions(store, action, this.config);

        if (!actionInWhiteList(action)) {
            return;
        }
        const state = store.getState();
        if (!this.isActivityEnabled(state)) {
            return;
        }
        const tradeInComponent = state.tradeInComponent;
        const tradeInObj = tradeInComponentSelectors.getTradeInForOffer(tradeInComponent);
        const offerType = getCurrentOfferType(state);
        const sponsor = getSponsor(state).toString();
        const isListingDealer = validator.isListingSite(sponsor);
        const isWebSiteDealer = !isListingDealer;
        const isTradeInLeadFormFeatureEnabled = featureToggleSelectors.isTradeInLeadFormSDPEnabled(state);
        const isLeadFormEnabled =
            isTradeInLeadFormFeatureEnabled &&
            ((isWebSiteDealer && isDealerTradeInLeadFormForWebsiteEnabled(state)) ||
                (isListingDealer && isDealerTradeInLeadFormForListingSiteEnabled(state)));

        const isLeadFormSubmittedSuccess = leadFormSelectors.isLeadFormSubmittedSuccess(state);

        const isTradeLeadformValEnabled = featureToggleSelectors.isTradeinLeadformValuationEnabled(state);
        const currentRoute = this.config.utils.getCurrentLocation();
        const { additionalSelectors } = this.selector;

        const handleTradeInRouting = () => {
            const didTradeInPreviouslyFail =
                (tradeInComponent.tradePaymentFailed && offerType !== 'cash') || tradeInComponent.tradeSaveFailed;
            const includeleadFormRoute = isTradeLeadformValEnabled && currentRoute === tradeInRoutes.TRADE_IN_LEADFORM;
            const shouldNavigateToDealSummary =
                (currentRoute === tradeInRoutes.TRADE_EQUITY || includeleadFormRoute) && !didTradeInPreviouslyFail;
            if (shouldNavigateToDealSummary) {
                const hasLeadBeenSent = getHasBeenSent(state);
                if (isLeadFormEnabled) {
                    if (hasLeadBeenSent) {
                        store.dispatch(submitDealSummary(RouteValues.TRADE_IN));
                    } else {
                        store.dispatch(submitOffer(true));
                    }
                    store.dispatch(actions.updateTradeInCompletedState());
                } else {
                    if (!hasLeadBeenSent) {
                        if (microSiteEnabled(state)) {
                            store.dispatch(push(RouteValues.MENU_PRODUCTS));
                        } else {
                            store.dispatch(goToSummaryLeadForm());
                        }
                    } else {
                        store.dispatch(submitDealSummary(RouteValues.TRADE_IN));
                    }
                }
            } else {
                store.dispatch(actions.updateTradeInPaymentStatus(false));
            }
        };

        switch (action.type) {
            case types.TRADE_IN_ANALYTICS: {
                dispatchActionWithAnalytics(action.payload.key, action.payload.description, store);
                break;
            }
            // analytics actions
            case types.TRADE_IN_OPENED: {
                dispatchActionWithAnalytics('DR_TRADEIN_OPENED', 'TradeIn flow has been entered.', store);
                break;
            }
            case types.TRADE_IN_ESTIMATE_RECEIVED: {
                setTimeout(() => {
                    dispatchActionWithAnalytics('DR_TRADEIN_ESTIMATE_RECEIVED', 'TradeIn estimate received.', store);
                }, 0);
                break;
            }
            case types.TRADE_IN_FINANCE_TYPE_CHANGED: {
                dispatchActionWithAnalytics('DR_TRADEIN_FINANCE_TYPE_CHANGED', 'TradeIn finance type changed.', store);
                break;
            }
            case types.REDESIGN_TRADE_IN_FINANCE_TYPE_CHANGED: {
                const { shouldIncludeTradeIn } = (action && action.payload) || {};
                const description = `Balance owed updated to ${shouldIncludeTradeIn ? 'YES' : 'NO'}`;
                dispatchActionWithAnalytics('DR_TRADEIN_FINANCE_TYPE_CHANGED', description, store);
                break;
            }
            case types.CHOOSE_TRADE_IN: {
                dispatchActionWithAnalytics('DR_TRADEIN_OPENED', 'TradeIn flow has been entered.', store);
                break;
            }
            case types.APPLY_TRADE_IN_SELECTION: {
                setTimeout(() => {
                    dispatchActionWithAnalytics(
                        'DR_TRADEIN_VEHICLE_SELECTIONS_APPLIED',
                        'TradeIn vehicle selections applied.',
                        store
                    );
                }, 0);
                break;
            }
            case types.TRADE_IN_LOAN_STATUS_APPLIED: {
                dispatchActionWithAnalytics('DR_TRADEIN_LOAN_STATUS_APPLIED', 'TradeIn loan status applied.', store);
                break;
            }
            case types.TRADE_IN_CONDITION_APPLIED: {
                dispatchActionWithAnalytics('DR_TRADEIN_CONDITION_APPLIED', 'TradeIn condition applied.', store);
                break;
            }
            case types.SKIP_TRADE_IN: {
                dispatchActionWithAnalytics('DR_TRADEIN_SKIPPED', 'TradeIn skipped', store);
                break;
            }
            case types.TRADE_IN_SKIPPED: {
                dispatchActionWithAnalytics('DR_SKIP_STEP_CLICKED', 'User clicked skip [CURRENTROUTE]', store);
                break;
            }
            case types.APPLY_TRADE_IN_EQUIPMENT_OPTIONS: {
                let description;
                if (this.isP202RedesignEnabled(state)) {
                    description = `TradeIn equipment options ${action.payload} applied.`;
                } else {
                    description = 'TradeIn equipment options applied.';
                }
                dispatchActionWithAnalytics('DR_TRADEIN_EQUIPMENT_OPTIONS_APPLIED', description, store);
                if (action.payload === 'PAGETWO') {
                    setTimeout(() => {
                        dispatchActionWithAnalytics(
                            'DR_TRADEIN_OPTIONS_CLICKED',
                            'TradeIn KBB estimate next button clicked',
                            store
                        );
                    }, 0);
                }
                break;
            }
            case types.SUBARU_ELIGIBILITY_SUMMARY_CLICK: {
                const actionTyped = action as ISubaruEligibilitySummaryClickAction;
                const dealerId = this.selector.additionalSelectors.dealerSelectors.getDealerState(state).dealerId;
                store.dispatch(
                    tradeInActionCreators.callSubaruEligibilitySummaryCheckEndpoint(
                        actionTyped.payload.vin,
                        actionTyped.payload.mileage,
                        dealerId
                    )
                );
                dispatchActionWithAnalytics(
                    analyticsActions.DR_GTRADE_TRADEIN_ELIGIBILITY_CLOSED,
                    'Subaru Eligibility Requested',
                    store
                );
                break;
            }
            case types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_SUCCESS: {
                const actionTyped = action as ISubaruEligibilitySummaryCheckSuccessAction;
                const isSubaruGTPV2ServiceEnabled = globalConfigFeatureToggleSelectors.isSubaruGTPV2ServiceEnabled();
                const vehicleMileage = actionTyped.meta.originalParams.mileage;
                const maxMiles = actionTyped.payload?.maxMiles;
                const isEligible = (maxMiles && vehicleMileage < maxMiles) || isSubaruGTPV2ServiceEnabled;
                store.dispatch(tradeInActionCreators.setTradeInIsSubaruGtpEligible(isEligible));
                if (isEligible) {
                    const tradeInObj = tradeInComponentSelectors.getTradeInForOffer(state.tradeInComponent);
                    store.dispatch(actions.updateTradeIn(tradeInObj));

                    const tradeInValuationValues = getTradeInValuation(state);
                    store.dispatch(this.config.offerActionCreators.updatedTradeIn(tradeInValuationValues));

                    store.dispatch(actions.saveOrUpdateTradeIn());
                } else {
                    store.dispatch(
                        actions.setTradeInSubaruGtpFlowError({
                            errorType: TradeInSubaruGtpFlowErrorType.MILEAGE_ERROR
                        })
                    );
                    store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN_DECISION));
                }
                break;
            }
            case types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_FAILURE: {
                const actionTyped = action as ISubaruEligibilitySummaryCheckFailureAction;
                store.dispatch(
                    actions.setTradeInSubaruGtpFlowError({
                        errorMessage: actionTyped.payload?.response?.message,
                        errorType: TradeInSubaruGtpFlowErrorType.API_ERROR
                    })
                );
                store.dispatch(tradeInActionCreators.setTradeInIsSubaruGtpEligible(false));
                store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN_DECISION));
                break;
            }
            case types.TRADE_IN_EQUIPMENT_DISPLAY: {
                dispatchActionWithAnalytics('DR_TRADEIN_EQUIPMENT_DISPLAY', `TradeIn equipment: ${action.payload}`, store);
                break;
            }
            case types.UPDATE_TRADE_IN_AMOUNT_OWED: {
                dispatchActionWithAnalytics('DR_TRADEIN_AMOUNT_OWED_CHANGED', 'TradeIn amount owed added.', store);
                break;
            }
            case types.TRADE_IN_LEADFORM_SUBMITTED: {
                dispatchActionWithAnalytics(
                    'DR_TRADEIN_LEADFORM_SUBMITTED',
                    'TradeIn flow has a successfull offer submission.',
                    store
                );
                break;
            }
            default: {
                break;
            }
        }

        // Trade-in Analytics
        switch (action.type) {
            case types.TRADE_IN_SEARCHTYPE_CLICKED: {
                dispatchActionWithAnalytics('DR_TRADEIN_SEARCHTYPE_CLICKED', `User clicked to search by ${action.payload}`, store);
                break;
            }
            case types.TRADE_IN_SEARCH_CLICKED: {
                dispatchActionWithAnalytics('DR_TRADEIN_SEARCH_CLICKED', 'Continued with NO SEARCH', store);
                break;
            }
            case types.TRADE_IN_VEHICLE_YEAR_CHANGED: {
                const actionName = 'DR_TRADEIN_VEHICLE_YEAR_CHANGED';
                dispatchActionWithAnalytics(actionName, 'TradeIn vehicle YEAR changed', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_VEHICLE_MAKE_CHANGED: {
                const actionName = 'DR_TRADEIN_VEHICLE_MAKE_CHANGED';
                dispatchActionWithAnalytics(actionName, 'TradeIn vehicle MAKE changed', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_VEHICLE_MODEL_CHANGED: {
                const actionName = 'DR_TRADEIN_VEHICLE_MODEL_CHANGED';
                dispatchActionWithAnalytics(actionName, 'TradeIn vehicle MODEL changed', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_VEHICLE_TRIM_CHANGED: {
                const actionName = 'DR_TRADEIN_VEHICLE_TRIM_CHANGED';
                dispatchActionWithAnalytics(actionName, 'TradeIn vehicle TRIM changed', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_VEHICLE_OWNERSHIP_CHANGED: {
                const { ownershipType } = tradeInObj || {};
                const actionName = 'DR_TRADEIN_VEHICLE_OWNERSHIP_CHANGED';
                setTimeout(() => {
                    dispatchActionWithAnalytics(
                        actionName,
                        `TradeIn vehicle OWNERSHIP changed: ${ownershipType.toUpperCase()}`,
                        store
                    );
                }, 0);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_VEHICLE_MILEAGE_CHANGED: {
                const actionName = 'DR_TRADEIN_VEHICLE_MILEAGE_CHANGED';
                dispatchActionWithAnalytics(actionName, 'TradeIn vehicle MILEAGE changed', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_VEHICLE_ZIPCODE_CHANGED: {
                const actionName = 'DR_TRADEIN_VEHICLE_ZIPCODE_CHANGED';
                dispatchActionWithAnalytics(actionName, 'TradeIn vehicle ZIPCODE changed', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_FINANCE_BALANCE_CHANGED: {
                const actionName = 'DR_TRADEIN_FINANCE_BALANCE_CHANGED';
                dispatchActionWithAnalytics(actionName, 'TradeIn vehicle BALANCE changed', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }

            case types.TRADE_IN_VALUE_PAGE_CLICKED: {
                setTimeout(() => {
                    dispatchActionWithAnalytics(
                        'DR_TRADEIN_VALUE_PAGE_CLICKED',
                        'TradeIn submit for appraisal next button clicked',
                        store
                    );
                }, 0);
                break;
            }
            case types.TRADE_IN_CONDITION_SELECTED: {
                const actionName = 'DR_TRADEIN_CONDITION_SELECTED';
                dispatchActionWithAnalytics(actionName, `Vehicle condition updated to ${action.payload.toUpperCase()}`, store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.COMPLETION_PAGE_EDIT_CLICKED: {
                dispatchActionWithAnalytics(
                    'DR_COMPLETION_PAGE_EDIT_CLICKED',
                    `Completion edit clicked for ${this.config.utils.getCurrentFlowName()}`,
                    store
                );
                if (
                    additionalSelectors.dealerSelectors.isSubaruGtpEnabled(state) &&
                    tradeInComponentSelectors.inTradeInSubaruGtpFlow(state) &&
                    tradeInComponentSelectors.checkIsSubaruGtpEligible(state)
                ) {
                    store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_SUBARU_ELIGIBILITY));
                } else if (tradeInComponentSelectors.isManualEntryFlow(state)) {
                    store.dispatch(actions.routeToManualEntry(tradeInRoutes.TRADE_MANUAL_ENTRY));
                } else {
                    store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_INFO));
                }

                break;
            }
            case types.COMPLETION_TERMS_DETAILS_CLICKED: {
                dispatchActionWithAnalytics(
                    'DR_COMPLETION_TERMS_DETAILS_CLICKED',
                    `Deal details clicked from ${this.config.utils.getCurrentFlowName()}`,
                    store
                );
                break;
            }
            case types.GO_TO_TRADE_IN_FLOW: {
                const actionTyped = action as actions.IGoToTradeInFlowAction;
                const { isSubaruGTPRetry = false, option } = actionTyped.payload || {};
                const tradeInSubaruGtpFlowError = tradeInComponentSelectors.getTradeInSubaruGtpFlowError(state);
                if (tradeInSubaruGtpFlowError) {
                    store.dispatch(actions.resetTradeInSubaruGtpFlowError());
                    if (isSubaruGTPRetry) {
                        store.dispatch(actions.setInTradeInSubaruGtpFlow());
                        store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_SUBARU_ELIGIBILITY));
                    } else {
                        store.dispatch(actions.resetInTradeInSubaruGtpFlow());
                        store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_INFO));
                    }
                } else {
                    store.dispatch(
                        actions.updateTradeInCurrentLocation(
                            isSubaruGTPRetry ? tradeInRoutes.TRADE_SUBARU_ELIGIBILITY : tradeInRoutes.TRADE_IN_SEARCH
                        )
                    );
                    store.dispatch(actions.sendTradeDecisionClickedAnalytics(option));
                }
                break;
            }
            case types.HANDLE_TRADE_IN_SEARCH_NEXT: {
                const subaruGtpIsEnabled = additionalSelectors.dealerSelectors.isSubaruGtpEnabled(state);
                const isSubaruGtpEligible = this.selector.checkIsSubaruGtpEligible(state);
                if (subaruGtpIsEnabled) {
                    store.dispatch(actions.setTradeInIsSubaruGtpEligible(isSubaruGtpEligible));
                }
                if (subaruGtpIsEnabled && isSubaruGtpEligible) {
                    store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_SUBARU_ELIGIBILITY));
                } else {
                    store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_INFO));
                    store.dispatch(actions.skipTradeInSearch());
                }
                break;
            }
            case types.HANDLE_TRADE_IN_SEARCH_SET_VEHICLE: {
                const typedAction = action as IHandleTradeInSearchNextAction;
                const { item } = typedAction.payload;
                const tradeIn = state.tradeInComponent;
                const vehicle = { ...this.selector.getTradeInVehicle(tradeIn) };
                if (item.yearId) {
                    vehicle.year = item.yearId.toString();
                }
                if (item.vin) {
                    vehicle.vin = item.vin;
                }
                if (item.makeId) {
                    vehicle.make = {
                        id: item.makeId.toString(),
                        name: item.makeName
                    };
                }
                if (item.modelId) {
                    vehicle.model = {
                        id: item.modelId.toString(),
                        name: item.modelName
                    };
                }
                if (item.trimId) {
                    vehicle.trim = {
                        id: item.trimId.toString(),
                        name: item.trimName,
                        vehicleId: item.vehicleId.toString()
                    };
                } else {
                    vehicle.trim = {
                        id: '',
                        name: '',
                        vehicleId: ''
                    };
                }
                store.dispatch(actions.updateVehicle(vehicle));
                store.dispatch(actions.handleSetVehicleNavigation());

                break;
            }
            case types.HANDLE_TRADE_IN_SET_VEHICLE_NAVIGATION: {
                const vehicle = this.selector.getTradeInVehicle(state.tradeInComponent);
                store.dispatch(actions.fetchTradeInMakes(vehicle.year, undefined));
                store.dispatch(actions.fetchTradeInModels(vehicle.year, vehicle.make.id, undefined, CALLSITE_SEARCH_2));
                store.dispatch(actions.fetchTradeInTrims(vehicle.year, vehicle.make.id, vehicle.model.id, undefined));
                break;
            }
            case types.TRADE_IN_DECISION_TYPE_CHANGED: {
                dispatchActionWithAnalytics(
                    'DR_TRADEIN_DECISION_TYPE_CHANGED',
                    `User selected ${action.payload.option} tradein`,
                    store
                );
                break;
            }
            case types.TRADE_IN_DECISION_CLICKED: {
                dispatchActionWithAnalytics('DR_TRADEIN_DECISION_CLICKED', `Continued with ${action.payload} TRADEIN`, store);
                break;
            }
            case types.TRADE_IN_DECISION_PAGE_DISPLAYED: {
                dispatchActionWithAnalytics('DR_TRADEIN_DECISION_PAGE_DISPLAYED', 'Tradein decision page has been entered', store);
                break;
            }
            case types.TRADE_IN_ICO_DISPLAYED: {
                dispatchActionWithAnalytics('DR_TRADEIN_ICO_DISPLAYED', 'ICO tradein workflow has been entered', store);
                break;
            }
            case types.TRADE_IN_ICO_EXIT_CLICKED: {
                dispatchActionWithAnalytics('DR_TRADEIN_ICO_EXIT_CLICKED', 'User exited ICO to standard tradein', store);
                break;
            }
            case types.TRADE_IN_ADD_ICO_VALUE: {
                const actionName = 'DR_TRADEIN_ADD_ICO_VALUE';
                dispatchActionWithAnalytics(actionName, 'ICO value added to deal', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_ICO_BALANCE_CARD_DISPLAYED: {
                dispatchActionWithAnalytics('DR_TRADEIN_ICO_BALANCE_CARD_DISPLAYED', 'ICO loan balance card displayed', store);
                break;
            }
            case types.TRADE_IN_ICO_ACTIVE_LOAN: {
                const actionName = 'DR_TRADEIN_ICO_ACTIVE_LOAN';
                dispatchActionWithAnalytics(actionName, `User selected ${action.payload} for active loan`, store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_ICO_BALANCE_CHANGED: {
                const actionName = 'DR_TRADEIN_ICO_BALANCE_CHANGED';
                dispatchActionWithAnalytics(actionName, 'Active loan balance amount updated', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                break;
            }
            case types.TRADE_IN_ICO_BALANCE_CLICKED: {
                dispatchActionWithAnalytics('DR_TRADEIN_ICO_BALANCE_CLICKED', `Continued with ${action.payload} LOAN`, store);
                break;
            }
            case types.TRADE_IN_ESTIMATE_DISPLAYED: {
                const actionName = 'DR_TRADEIN_ESTIMATE_RECEIVED';
                dispatchActionWithAnalytics(actionName, 'TradeIn estimate received.', store);
                this.sendRelayMessage(actionName, tradeInComponent, state);
                const tradeIn = tradeInComponentSelectors.getTradeInForOffer(tradeInComponent);
                const vehicle = this.selector.additionalSelectors.offerSelectors.getAnalyticsPayloadVehicleDetails(state);
                const offerType = getCurrentOfferType(state);
                const relayMsgPayload = {
                    eventAction: 'DR Action',
                    eventData: {
                        dealType: offerType,
                        tradeInSource: tradeIn.source,
                        dr: getExternalAnalyticsPayload(vehicle)
                    },
                    eventName: 'DR_TRADEIN_ESTIMATE_DISPLAYED'
                };
                this.config.hostActions.relayTradeInEvents(relayMsgPayload);
                break;
            }
            default:
                break;
        }

        switch (action.type) {
            // new relic actions
            case types.ROUTE_TRADE_IN: {
                logNewRelic('tradeInNavigationCardSelected', 'Trade In NavCard Clicked', NrSpaApiEnum.PAGE_ACTION);
                break;
            }
            case types.CHOOSE_TRADE_IN: {
                logNewRelic('chooseTradeIn', 'TradeIn flow has been entered.', NrSpaApiEnum.PAGE_ACTION);
                break;
            }
            case types.UPDATE_TRADE_IN: {
                logNewRelic('addTradeIn', 'TradeIn has been applied.', NrSpaApiEnum.PAGE_ACTION);
                break;
            }
            case types.TRADE_IN_YEARS_FAILURE: {
                logNewRelic('tradeInYearsFailure', 'Failing getting YEARS', NrSpaApiEnum.ERROR);
                break;
            }
            case types.TRADE_IN_MAKES_FAILURE: {
                logNewRelic('tradeInMakesFailure', 'Failing getting MAKES', NrSpaApiEnum.ERROR);
                break;
            }
            case types.TRADE_IN_MODELS_FAILURE: {
                logNewRelic('tradeInModelFailure', 'Failing getting MODELS', NrSpaApiEnum.ERROR);
                break;
            }
            case types.TRADE_IN_TRIMS_FAILURE: {
                logNewRelic('tradeInTrimsFailure', 'Failing getting TRIMS', NrSpaApiEnum.ERROR);
                break;
            }
            case types.COMPLETE_TRADE_IN: {
                logNewRelic('completeTradeIn', 'Trade in Complete', NrSpaApiEnum.PAGE_ACTION);
                break;
            }
            case types.SKIP_TRADE_IN: {
                logNewRelic('skipTradeIn', 'Trade has been skipped', NrSpaApiEnum.PAGE_ACTION);
                break;
            }
            default: {
                break;
            }
        }

        switch (action.type) {
            // component actions
            case types.APPLY_TRADE_IN_EQUIPMENT_OPTIONS: {
                const useHeaderFooterState =
                    this.isP202RedesignEnabled(state) && globalConfigFeatureToggleSelectors.useHeaderFooterState();
                const isPageOne = action.payload === 'PAGEONE';
                const applyTradeInEquipmentOptions = useHeaderFooterState
                    ? !isPageOne && hasOptionsData(tradeInComponent)
                    : hasOptionsData(tradeInComponent);
                if (applyTradeInEquipmentOptions) {
                    const step = isPageOne ? TradeInEvaluationStep.EquipmentOptionStep1 : undefined;
                    store.dispatch(actions.performTradeInValuation(tradeInComponent, step));
                }
                break;
            }
            case types.UPDATE_TRADE_IN_EQUIPMENT_OPTION:
                store.dispatch(actions.validateTradeInOptions(tradeInComponent));
                break;
            case types.TRADE_IN_ESTIMATION_SUCCESS: {
                const useHeaderFooterState =
                    this.isP202RedesignEnabled(state) && globalConfigFeatureToggleSelectors.useHeaderFooterState();
                if (useHeaderFooterState) {
                    const tradeInValuation = getTradeInValuation(state);
                    store.dispatch(this.config.offerActionCreators.updatedTradeIn(tradeInValuation));
                }
                break;
            }
            case types.UPDATE_TRADE_IN_CURRENT_LOCATION:
                if (userIsInTradeInFlow(this.config)) {
                    store.dispatch(push(action.payload.nextRoute));
                }
                break;
            case types.SUMMARY_START_TRADE_IN_CLICKED:
                store.dispatch(actions.routeToTradeInStart());
                break;
            case types.SUMMARY_EDIT_TRADE_IN_CLICKED:
                store.dispatch(actions.routeToTradeInStart());
                break;
            case types.SUMMARY_REMOVE_TRADE_IN_CLICKED:
                store.dispatch(actions.removeTradeIn());
                if (this.isP202RedesignEnabled(state) && userIsOnTradeInSummary(this.config)) {
                    store.dispatch(actions.routeToTradeInStart());
                }
                break;
            case types.TRADE_IN_SEND_SUCCESS: {
                if (this.isP202RedesignEnabled(state)) {
                    break;
                }

                if (!browserHelper.storageAvailable('localStorage')) break;

                const vin = getVehicleVin(state);
                const connectionId = getConnectionId(state);
                const tradeInValuationProvider = getDealerTradeInValuationProvider(state);
                const shouldResetPreference = tradeInComponentSelectors.isTradeInCompleted(state);

                if (shouldResetPreference) {
                    browserHelper.preferenceStorage(vin, connectionId, tradeInValuationProvider);
                    const preference = { vin, connectionId, tradeInValuationProvider };
                    store.dispatch(actions.syncRememberedTradePreference(preference));
                }

                if (isTradeLeadformValEnabled) {
                    handleTradeInRouting();
                }

                break;
            }

            case types.REMOVE_TRADE_IN: {
                const request = removeTradeRequest(state);
                store.dispatch({
                    [RSAA]: request
                });
                break;
            }

            case types.TRADE_IN_REMOVE_SUCCESS: {
                const tradeInValuation = {
                    amountOwed: 0,
                    shouldIncludeTradeIn: true,
                    value: 0
                };
                store.dispatch(this.config.offerActionCreators.updatedTradeIn(tradeInValuation));

                this.sendRelayMessage(types.DR_TRADEIN_REMOVED, tradeInComponent, state);
                break;
            }

            // case types.types.TRADE_IN_SEND_SUCCESS // TODO: Explore this option if deal update is not the target
            case types.OFFER_SUBMISSION_SUCCESS: {
                if (action.payload?.currentRoute === tradeInRoutes.TRADE_SUMMARY) {
                    const isTradeUpdateRequired = tradeInComponentSelectors.isTradeUpdateRequired(state);
                    const actionName = isTradeUpdateRequired ? types.DR_TRADEIN_UPDATED : types.DR_TRADEIN_ADDED;

                    this.sendRelayMessage(actionName, tradeInComponent, state);
                }
                break;
            }

            case types.SAVE_COUNTEROFFER: {
                const state: IStateTree = store.getState();
                if (tradeInComponentSelectors.hasTradeIn(state)) {
                    saveOrUpdateTradeIn(state, store, this.config, this.selector);
                }
                break;
            }

            case types.UPDATE_TRADE_IN_VALUATION: {
                const isTradeValuationReset = tradeInComponentSelectors.isTradeValuationReset(state);

                const updateAppState = () => {
                    const tradeInValuationValues = getTradeInValuation(state);
                    store.dispatch(actions.updateTradeIn(tradeInObj));
                    store.dispatch(this.config.offerActionCreators.updatedTradeIn(tradeInValuationValues));
                };

                if (this.isP202RedesignEnabled(state)) {
                    updateAppState();
                    return;
                }

                if (!isTradeValuationReset) {
                    updateAppState();
                } else {
                    store.dispatch(actions.updateResetValuationFlag(false));
                    handleTradeInRouting();
                }
                break;
            }

            case types.PAYMENT_RECEIVED: {
                const isICO = tradeInComponentSelectors.isICO(state);
                const hasTrade = tradeInComponentSelectors.hasTradeIn(state);
                if (this.isP202RedesignEnabled(state) && isICO && hasTrade) {
                    saveOrUpdateTradeIn(state, store, this.config, this.selector);
                    return;
                }

                if (this.isP202RedesignEnabled(state)) {
                    return;
                }
                const actionForPayment = action.payload && action.payload.actionForPayment;
                const currentTradeInLocation = tradeInComponentSelectors.getTradeInCurrentLocationRoute(state);
                if (
                    actionForPayment === types.UPDATED_TRADE_IN &&
                    (currentTradeInLocation === tradeInRoutes.TRADE_EQUITY ||
                        currentTradeInLocation === tradeInRoutes.TRADE_IN_LEADFORM ||
                        currentTradeInLocation === tradeInRoutes.TRADE_VEHICLE_INFO)
                ) {
                    saveOrUpdateTradeIn(state, store, this.config, this.selector);
                    store.dispatch(actions.updateTradeInCompletedState());
                } else {
                    handleTradeInRouting();
                }

                break;
            }

            case types.PAYMENT_FAILURE: {
                if (this.isP202RedesignEnabled(state)) {
                    return;
                }

                if (!isMonthlyPaymentInvalid(state)) {
                    handleTradeInRouting();
                }
                break;
            }

            case types.LEAD_FORM_SUBMISSION_SUCCESS: {
                if (this.isP202RedesignEnabled(state)) {
                    return;
                }

                const { icoTradeFlow: icoTradeFlow, isTradeInIcoEnabled } = tradeInComponentSelectors;
                let routeTo =
                    isTradeInIcoEnabled(state) && icoTradeFlow(state)
                        ? tradeInRoutes.TRADE_IN_ICO
                        : tradeInRoutes.TRADE_VEHICLE_INFO;

                switch ((action.payload || {}).currentRoute) {
                    case tradeInRoutes.TRADE_VALUATION:
                    case tradeInRoutes.TRADE_IN_LEADFORM:
                        routeTo = tradeInRoutes.TRADE_EQUITY;
                        break;
                    default:
                        break;
                }

                if (routeTo) {
                    store.dispatch(actions.updateTradeInCurrentLocation(routeTo));
                } else {
                    store.dispatch(goToNext(RouteValues.TRADE_IN));
                }

                if (isLeadFormSubmittedSuccess) {
                    store.dispatch(onCloseLeadFormModal());
                }
                break;
            }

            case types.LOOKUP_ICO_TRADE_SUCCESS: {
                const { yearId, makeId, makeName, modelId, modelName } = action.payload.vehicle;
                const payload = {
                    year: yearId,
                    make: makeName,
                    makeId,
                    model: modelName,
                    modelId,
                    status: 'CLEARED',
                    tivSource: 'ICO'
                };
                store.dispatch({ type: types.ICO_TRADE_IN_COMPLETED, payload });
                store.dispatch(actions.tradeInEstimateSuccess());
                store.dispatch(actions.sendIcoExitClickedAnalytics());
                store.dispatch(actions.updateTradeInCurrentLocation('/vehicleInfo'));
                break;
            }

            case types.LOOKUP_ICO_TRADE_FAILURE: {
                store.dispatch(actions.sendIcoExitClickedAnalytics());
                store.dispatch(actions.updateTradeInCurrentLocation('/vehicleInfo'));
                break;
            }

            case types.OEM_TRADE_IN_UPDATE: {
                updateOemTradeIn(state, store);
                break;
            }

            case types.OEM_TRADE_IN_SEND_SUCCESS: {
                const tradeInObj = tradeInComponentSelectors.getTradeInForOffer(state.tradeInComponent);
                store.dispatch(actions.updateTradeIn({ id: action.payload?.id, ...tradeInObj }));
                store.dispatch(navigateTo('/dashboard'));
                break;
            }

            case types.SKIP_TRADE_IN: {
                const nextCard = getNextCardDetails(state)?.route;
                store.dispatch(navigateTo(nextCard));
                break;
            }

            case types.SUBARU_ELIGIBILITY_CLICK: {
                const { vin, mileage, fallbackAction, isUniqueEstimateOption } = (action as ISubaruEligibilityAction).payload;
                const dealerId = this.selector.additionalSelectors.dealerSelectors.getDealerState(state).dealerId;
                store.dispatch(
                    tradeInActionCreators.callSubaruEligibilityCheckEndpoint(
                        vin,
                        mileage,
                        dealerId,
                        fallbackAction,
                        isUniqueEstimateOption
                    )
                );
                dispatchActionWithAnalytics(
                    analyticsActions.DR_GTRADE_TRADEIN_ELIGIBILITY_CLOSED,
                    'Subaru Eligibility Requested',
                    store
                );
                break;
            }
            case types.SUBARU_ELIGIBILITY_CHECK_SUCCESS: {
                const actionTyped = action as actions.ISubaruEligibilityCheckSuccessAction;
                const isSubaruGTPV2ServiceEnabled = globalConfigFeatureToggleSelectors.isSubaruGTPV2ServiceEnabled();
                const { mileage: vehicleMileage, fallbackAction, isUniqueEstimateOption } = actionTyped.meta.originalParams;
                const maxMiles = actionTyped.payload?.maxMiles;
                const isEligible = (maxMiles && vehicleMileage < maxMiles) || isSubaruGTPV2ServiceEnabled;
                store.dispatch(tradeInActionCreators.setTradeInIsSubaruGtpEligible(isEligible));
                if (isEligible && isUniqueEstimateOption) {
                    const tradeInObj = tradeInComponentSelectors.getTradeInForOffer(state.tradeInComponent);
                    store.dispatch(actions.updateTradeIn(tradeInObj));

                    const tradeInValuationValues = getTradeInValuation(state);
                    store.dispatch(this.config.offerActionCreators.updatedTradeIn(tradeInValuationValues));

                    store.dispatch(actions.saveOrUpdateTradeIn());
                } else if (isEligible && !isUniqueEstimateOption) {
                    store.dispatch(tradeInActionCreators.updateTradeInCurrentLocation(TRADE_IN_DECISION));
                } else {
                    store.dispatch(
                        actions.setTradeInSubaruGtpFlowError({
                            errorType: TradeInSubaruGtpFlowErrorType.MILEAGE_ERROR
                        })
                    );
                    store.dispatch(fallbackAction);
                }
                break;
            }
            case types.SUBARU_ELIGIBILITY_CHECK_FAILURE: {
                const actionTyped = action as actions.ISubaruEligibilityCheckFailureAction;
                const { fallbackAction } = actionTyped.meta.originalParams;
                store.dispatch(
                    actions.setTradeInSubaruGtpFlowError({
                        errorMessage: actionTyped.payload?.response?.message,
                        errorType: TradeInSubaruGtpFlowErrorType.API_ERROR
                    })
                );
                store.dispatch(tradeInActionCreators.setTradeInIsSubaruGtpEligible(false));
                store.dispatch(fallbackAction);
                break;
            }

            case types.SUBARU_ELIGIBILITY_FALLBACK: {
                const { source, nextRoute } = action.payload;
                const payload = { value: 0, comments: '', source: '' };

                store.dispatch(actions.updateTradeValueSource(payload));
                store.dispatch(actions.updateTradeInSource(source));
                store.dispatch(actions.updateTradeInCurrentLocation(nextRoute));
                store.dispatch(actions.sendTradeDecisionClickedAnalytics(source));
                break;
            }

            case types.SUBARU_ELIGIBILITY_FALLBACK_SALESVIEW_NO_APPRAISAL: {
                const { source } = action.payload;
                const payload = { value: 0, comments: '', source: '' };

                store.dispatch(actions.updateTradeInSource(source));
                store.dispatch(actions.updateTradeValueSource(payload));
                store.dispatch(actions.saveOrUpdateTradeIn());
                store.dispatch(actions.sendTradeDecisionClickedAnalytics(source));
                break;
            }
            default:
                return;
        }
    };

    private handleTradeInLeadActions = (store, action, config: IConfigTradeIn) => {
        const state = store.getState();
        const selectorPropIsP202RedesignEnabled = this.selector.additionalSelectors.configSelectors.isP202RedesignEnabled(state);
        if (!globalConfigFeatureToggleSelectors.isP202RedesignEnabled() && !selectorPropIsP202RedesignEnabled) return;

        switch (action.type) {
            case types.CLOSE_LEAD_FORM_MODAL: {
                const currentRoute = config.utils.getCurrentLocation();
                const hasBeenSent = getHasBeenSent(state);
                if (currentRoute === '/tradeSummary' && hasBeenSent === false) {
                    store.dispatch(actions.removeTradeIn());
                }
                break;
            }
            case types.TRADE_IN_LEAD_SUBMITTED: {
                dispatchActionWithAnalytics(analyticsActions.LEAD_FORM_SUBMIT_CLICKED, 'lead form sent', store);
                store.dispatch(onCloseLeadFormModal());
                break;
            }

            case types.TRADE_IN_PERFORM_UPDATE: {
                const isInTradeInSubaruGtpFlow = tradeInComponentSelectors.inTradeInSubaruGtpFlow(state);
                dispatchActionWithAnalytics(
                    'DR_TRADEIN_ADDED',
                    isInTradeInSubaruGtpFlow ? 'Subaru TradeIn has been applied.' : 'TradeIn has been applied.',
                    store
                );
                saveOrUpdateTradeIn(state, store, config, this.selector);
                break;
            }

            case types.TRADE_IN_SEND_SUCCESS: {
                if (!browserHelper.storageAvailable('localStorage')) {
                    store.dispatch(setLeadFormError('Local storage not available'));
                    break;
                }
                const vin = getVehicleVin(state);
                const connectionId = getConnectionId(state);
                const tradeInValuationProvider = getDealerTradeInValuationProvider(state);
                const shouldResetPreference = tradeInComponentSelectors.isTradeInCompleted(state);

                if (shouldResetPreference) {
                    browserHelper.preferenceStorage(vin, connectionId, tradeInValuationProvider);
                }

                store.dispatch(actions.updateTradeInCompletedState());
                store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_SUMMARY));
                store.dispatch(onLeadFormSubmissionContinue());

                if (isSubaruGtpEnabled(state) && tradeInComponentSelectors.inTradeInSubaruGtpFlow(state)) {
                    const tradeInObj = tradeInComponentSelectors.getTradeInForOffer(state.tradeInComponent);
                    store.dispatch(actions.updateTradeIn(tradeInObj));
                }
                break;
            }

            case types.TRADE_IN_SEND_FAILURE: {
                const errCount = tradeInComponentSelectors.getTradeInErrorCount(state);

                if (errCount > 1) {
                    store.dispatch(actions.removeTradeIn());
                    store.dispatch(onCloseLeadFormModal());
                    store.dispatch(push(tradeInRoutes.TRADE_IN));
                } else {
                    store.dispatch(
                        setLeadFormError(
                            `An error has occurred adding your Trade-In to your deal.
                             Please review your vehicle information and try again or continue without your Trade-In.`
                        )
                    );
                }
                break;
            }

            case types.REMOVE_TRADE_IN_AND_CLOSE_MODAL: {
                store.dispatch(actions.removeTradeIn());
                store.dispatch(onCloseLeadFormModal());
                store.dispatch(push(tradeInRoutes.TRADE_IN));
                break;
            }

            case types.APPLY_AMOUNT_OWED_AND_UPDATE_VALUE: {
                const tradeIn = tradeInComponentSelectors.getTradeInComponent(state);
                const shouldIncludeTradeIn = tradeInComponentSelectors.getAmountAppliedToFinancing(tradeIn);
                store.dispatch(actions.applyToAmountFinanced(shouldIncludeTradeIn));
                store.dispatch(actions.updateTradeInValuation());
                break;
            }

            case types.TRADE_IN_SAVE_OR_UPDATE: {
                const tradeIn = tradeInComponentSelectors.getTradeInComponent(state) as ITradeInState;
                const shouldIncludeTradeIn = tradeInComponentSelectors.getAmountAppliedToFinancing(tradeIn);
                const haveVehicleOptionsChanged = hasVehicleOptChanged(state.tradeInComponent);
                const isLeadFormSubmitted = leadFormSelectors.isLeadFormSubmittedSuccess(state);
                const isLeadFormEnabled = tradeInComponentSelectors.shouldShowLeadForm(state);
                const leadFormSubmittedAndOfferSent = getHasBeenSent(state) && isLeadFormSubmitted;
                const getIsDealerUser = isDealerUser(state);

                if (haveVehicleOptionsChanged || leadFormSubmittedAndOfferSent) {
                    store.dispatch(actions.updateTradeInValuation());
                }
                if (haveVehicleOptionsChanged) {
                    store.dispatch(actions.changedTradeIn(haveVehicleOptionsChanged));
                    store.dispatch(actions.applyToAmountFinanced(shouldIncludeTradeIn));
                }
                if (leadFormSubmittedAndOfferSent || getIsDealerUser || !isLeadFormEnabled) {
                    store.dispatch(actions.saveTradeIn());
                }

                store.dispatch(actions.updateTradeInCurrentLocation(tradeInRoutes.TRADE_SUMMARY));
            }
        }
    };

    private isActivityEnabled(state: ActivityStateTree) {
        return this.selector.additionalSelectors.configSelectors.isActivityEnabled(state);
    }
}

// externals
import React from 'react';

// utils
import { normalizeVin } from '../../../utils/validation';
import { Tooltip } from '@makemydeal/ui-bricks/dist/cox';
import { buildApiUrl } from '../../../common/utils/searchUiUtils';
import { formatYMMT } from './utils';

// components
import SearchInput from './SearchInput';
import TradePage from '../../../common/components/TradePage/TradePage';
import { SearchTitle, TabsContainer, Tab, SearchContainer, ToolTipContainer } from './VehicleInfoComponents';
import VehicleInfoDetails from '../VehicleInfoDetails/VehicleInfoDetails';

// consts/enums
import { TRADE_MANUAL_ENTRY } from '../../../utils';
import * as routes from '../../../utils/routes';

// interfaces
import { vehicleInfoUIProps } from './VehicleInfoInterfaces';
import { StepperRoute } from '../../../types';

// config
import config from '../../../store/mmd/config';
import { ITradeInSearchItem } from '../../../store';

export const vehicleInfoUI: React.FC<vehicleInfoUIProps> = ({
    amountOwed,
    isTradeInValid,
    isSearchingForVin,
    isSearchingForYMMT,
    isTrimLoading,
    isVinFetchRequired,
    externalTradeInConditions,
    tradeInStarted,
    tabSelected,
    setVehicle,
    renderDisclaimer,
    goToRoute,
    dispatchAnalytics,
    next,
    initializeOwnership,
    initializeSource,
    subaruEligibilityClickMultiEstimateOptions,
    subaruEligibilityClickUniqueEstimateOption,
    setTradeInIsSubaruGtpEligible,
    setTradeInSubaruGtpFlowLeaseError,
    resetTradeInSubaruGtpFlowError,
    uniqueEstimateOption,
    isShowAppraisalValueScreenSalesView,
    stepperRoutes,
    isGtpEligible,
    vin,
    eligibilityFields,
    isSubaruGtpOn,
    isLease,
    nextStepRoute,
    prevStepRoute,
    showBackButton,
    showSkipButton,
    onNavigationButtonClick,
    onSkip,
    showZipCodeModalIfApplicable
}) => {
    const {
        services: {
            bff: { protocol, host, port, base }
        }
    } = config;

    const [apiUrl] = React.useState(buildApiUrl(protocol, host, port, base));
    const [isVinActive, setIsVinActive] = React.useState(true);
    const [isYMMTActive, setIsYMMTActive] = React.useState(false);
    const [isNextDisabled, setIsNextDisabled] = React.useState(true);
    const [continueWasClicked, setContinueWasClicked] = React.useState(false);
    const [applyToAmountFinanced, setApplyToAmountFinanced] = React.useState(!!amountOwed);

    const fetchVin = (value: string) => fetch(`${apiUrl}?vin=${value}`);

    const formatVin = (item: any, value: string) => `${item.yearId} ${item.makeName} ${item.modelName}`;

    React.useEffect(() => {
        if (isTradeInValid) {
            setIsVinActive(isSearchingForVin);
            setIsYMMTActive(isSearchingForYMMT);
            setIsNextDisabled(!isTradeInValid || isTrimLoading);
        }
    }, [isTradeInValid, isSearchingForVin, isSearchingForYMMT, isTrimLoading]);

    React.useEffect(() => {
        tradeInStarted();
        if (externalTradeInConditions.isPartialTrade) {
            if (externalTradeInConditions.hasAmountOwed) {
                initializeOwnership();
            }
            initializeSource();
            setIsVinActive(false);
            setIsYMMTActive(true);
        }
    }, []);

    React.useEffect(() => {
        if (!isVinFetchRequired) return;

        fetchVin(vin)
            .then((response) => {
                if (response.ok) return response.json();
                return [];
            })
            .then((searchResults) => {
                if (!searchResults.length) return;

                const vehicle = searchResults[0];
                setVehicle(vehicle);
            });
    }, []);

    React.useEffect(() => {
        showZipCodeModalIfApplicable();
    }, []);

    const handleVinSelect = (trade: ITradeInSearchItem, vin: string | false): void => {
        trade.vin = vin ? vin : '';
        setVehicle(trade);
        setIsNextDisabled(false);
    };

    const setVinActive = () => {
        tabSelected('vin');
        setIsVinActive(true);
        setIsYMMTActive(false);
        setContinueWasClicked(false);
    };

    const setYMMTActive = () => {
        tabSelected('ymm');
        setIsVinActive(false);
        setIsYMMTActive(true);
        setContinueWasClicked(false);
    };

    const processStepperRoutesSubaruGTP = (stepperRoutes: StepperRoute[]) => {
        if (isSubaruGtpOn && isVinActive && isGtpEligible && uniqueEstimateOption.hasUniqueOption) {
            return stepperRoutes.filter(
                (stepRoute) => stepRoute.route !== routes.TRADE_IN_DECISION && stepRoute.route !== routes.TRADE_VEHICLE_CONDITION
            );
        }
        return stepperRoutes;
    };

    const renderTabs = () => {
        return (
            <>
                <Tab className={`tab-vin common-header ${isVinActive ? 'active' : ''}`} onClick={setVinActive}>
                    VIN
                </Tab>
                <Tab className={`tab-ymmt common-header ${isYMMTActive ? 'active' : ''}`} onClick={setYMMTActive}>
                    Enter Manually
                </Tab>
            </>
        );
    };

    const TooltipWrapper = () => {
        const ToolTipContent = () => (
            <>
                <label>Where's my VIN?</label>
                <p>A Vehicle Identification Number (VIN) is unique to your car. It's 17 characters long (digits and letters)</p>
                <br />
                <label>You can typically find your VIN in a few places:</label>
                <ol>
                    <li>Stamped on the dashboard near the windshield, on the driver's side of the vehicle.</li>
                    <li>On a plate or sticker on the driver's side door jamb.</li>
                    <li>On your insurance card and insurance policy.</li>
                    <li>On your vehicle title and registration.</li>
                </ol>
            </>
        );
        const TooltipWrapper = ({ content }) => <Tooltip>{content}</Tooltip>;

        return (
            <ToolTipContainer>
                <TooltipWrapper content={<ToolTipContent />} />
            </ToolTipContainer>
        );
    };

    const renderVinSearch = () => {
        if (!isVinActive) return <></>;

        return (
            <>
                <SearchTitle>
                    <label htmlFor="trade-search-vin">VIN</label>
                    <TooltipWrapper />
                </SearchTitle>
                <SearchInput
                    inputId="trade-search-vin"
                    name="vin"
                    placeholder="e.g. RTG567908THG"
                    fetchCallback={fetchVin}
                    normalizeCallback={normalizeVin}
                    formatResult={formatVin}
                    selectCallback={(item, vin) => handleVinSelect(item, vin)}
                />
                <VehicleInfoDetails
                    nextDisabled={setIsNextDisabled}
                    setApplyToAmountFinanced={setApplyToAmountFinanced}
                    isApplyToAmountFinanced={applyToAmountFinanced}
                    isVinActive={isVinActive}
                    continueWasClicked={continueWasClicked}
                />
            </>
        );
    };

    const fetchYMMT = (value: string) => fetch(`${apiUrl}?ymm=${value}`);

    const handleYMMTSelect = (item: any) => {
        setVehicle(item);
        setIsNextDisabled(false);
    };

    const renderYMMTSearch = () => {
        if (!isYMMTActive) return <></>;
        return (
            <>
                <SearchTitle>
                    <label htmlFor="trade-search-ymmt">Search</label>
                </SearchTitle>
                <SearchInput
                    inputId="trade-search-ymmt"
                    name="ymmt"
                    placeholder="e.g. 2015 Honda Pilot"
                    fetchCallback={fetchYMMT}
                    formatResult={formatYMMT}
                    selectCallback={handleYMMTSelect}
                />
                <hr />
                <VehicleInfoDetails
                    nextDisabled={setIsNextDisabled}
                    setApplyToAmountFinanced={setApplyToAmountFinanced}
                    isApplyToAmountFinanced={applyToAmountFinanced || externalTradeInConditions.hasAmountOwed}
                    continueWasClicked={continueWasClicked}
                />
            </>
        );
    };

    const isSubaruGtpFlowAvailable = isVinActive && isGtpEligible && isSubaruGtpOn;

    const handleNext = () => {
        if (!isTradeInValid) {
            setContinueWasClicked(true);
            return;
        }

        resetTradeInSubaruGtpFlowError();
        setTradeInIsSubaruGtpEligible(false);

        if (isSubaruGtpFlowAvailable) {
            if (isLease) {
                setTradeInSubaruGtpFlowLeaseError();
            } else if (uniqueEstimateOption.hasUniqueOption) {
                subaruEligibilityClickUniqueEstimateOption(
                    eligibilityFields,
                    uniqueEstimateOption,
                    isShowAppraisalValueScreenSalesView
                );
                return;
            } else if (uniqueEstimateOption.hasUniqueOption === false) {
                subaruEligibilityClickMultiEstimateOptions(eligibilityFields);
                return;
            }
        }

        next(
            externalTradeInConditions.isPartialTrade ? TRADE_MANUAL_ENTRY : '',
            uniqueEstimateOption,
            isShowAppraisalValueScreenSalesView
        );
    };

    const onBackButtonClick = () => {
        if (prevStepRoute) {
            onNavigationButtonClick(prevStepRoute);
        }
    };
    const onSkipButtonClick = () => {
        if (nextStepRoute) {
            onSkip(nextStepRoute);
        }
    };
    const getNextButtonText = () => {
        if (isSubaruGtpFlowAvailable && uniqueEstimateOption.hasUniqueOption) {
            return 'Continue to Summary';
        }
        if (uniqueEstimateOption.hasUniqueOption) {
            return 'Continue to Details';
        }
        return 'Continue to Estimate Method';
    };

    const PageSubTitle =
        "Let's start with the basics. You can look up your current vehicle or enter the details manually. All input fields are required unless marked as optional.";

    return (
        <>
            <TradePage
                headerOnTop
                showCustomTitle
                pageClass="vehicle-info-page"
                pageTitle="Tell Us About Your Vehicle"
                pageSubTitle={PageSubTitle}
                renderDisclaimer={renderDisclaimer}
                goToRoute={goToRoute}
                dispatchAnalytics={dispatchAnalytics}
                footerProps={{
                    onActionButtonClick: handleNext,
                    showBackButton,
                    showSkipButton,
                    buttonText: getNextButtonText(),
                    skipButtonText: 'Skip',
                    disableActionButton: isNextDisabled,
                    fullWidthLayout: true,
                    onSkipButtonClick,
                    onBackButtonClick
                }}
                stepperRoutes={processStepperRoutesSubaruGTP(stepperRoutes)}
                showTradeErrorMessage={false}
                staticAssetsConfig={config.staticImages}
                stepLabelVisible={true}
                isStepperClickable={true}
            >
                <div className="search-tabs-container">
                    <TabsContainer className="search-tabs">{renderTabs()}</TabsContainer>
                    <SearchContainer className="search-body">
                        {renderVinSearch()}
                        {renderYMMTSearch()}
                    </SearchContainer>
                </div>
            </TradePage>
        </>
    );
};

export default vehicleInfoUI;

// externals
import * as React from 'react';

// libraries
import { CardSelectButtonsUI, ITradeInVehicle } from '@makemydeal/dr-activities-common';

// components
import TradePage from '../../../common/components/TradePage/TradePage';
import VehicleConditionDescriptionModalUI from './VehicleConditionDescriptionModalUI';
import { Grid, SVGIcon } from './VehicleConditionComponents';

// config
import config from '../../../store/mmd/config';

// consts/enums
import { TRADE_IN_VEHICLE } from '../../../utils/constants';
import { StepperRoute } from '../../../types';
import GTPFlowPageSubtitle from '../GTPFlowPageSubtitle/GTPFlowPageSubtitle';

export interface IVehicleConditionUIStateProps {
    conditionMileageZipSubtitle: string;
    mileage: string;
    pageSubTitle: string;
    pageTitle: string;
    renderDisclaimer?: { () };
    selectedCondition: string;
    stepperRoutes: StepperRoute[];
    vehicle: ITradeInVehicle;
    vehicleName: string;
    hasUniqueEstimateOption: boolean;
    zip?: string;
}

export interface IVehicleConditionUIDispatchProps {
    dispatchAnalytics?: { (type: string, description: string) };
    goToRoute: { (route: string): void };
    next: { () };
    onValueChange: { (vehicle: any) };
    removeTrade: { () };
    goBack: { (hasUniqueEstimateOption: boolean) };
}

export interface IVehicleConditionUIProps extends IVehicleConditionUIStateProps, IVehicleConditionUIDispatchProps {
    isExcellentHiddenWebsite: boolean;
    isExcellentHiddenListing: boolean;
    vehicle: ITradeInVehicle;
    vehicleName: string;
    zip?: string;
}

export interface IVehicleConditionUIState {
    selectedCondition: string;
    selectedConditionIndex: number;
    vehicleConditionModalIsOpen: boolean;
}

class VehicleConditionUI extends React.Component<IVehicleConditionUIProps, IVehicleConditionUIState> {
    constructor(props) {
        super(props);
        const { selectedCondition } = this.props;
        const selectedConditionItem = TRADE_IN_VEHICLE.find((item: any) => item.label === selectedCondition);
        const selectedConditionIndex = selectedConditionItem ? selectedConditionItem.index : -1;
        this.state = { selectedConditionIndex, selectedCondition, vehicleConditionModalIsOpen: false };
    }

    onClickConditionPanel = (event): void => {
        const conditionId = event.currentTarget.getAttribute('data-id');
        const id = conditionId.split('_')[1];
        const condition = TRADE_IN_VEHICLE[id].label;

        if (this.state.selectedConditionIndex.toString() !== id) {
            this.props.onValueChange(condition);
        }

        this.setState({
            ...this.state,
            selectedConditionIndex: id,
            selectedCondition: condition
        });
    };

    composeConditionPanels = (item): JSX.Element => {
        const { selectedConditionIndex } = this.state;
        const activeConditionId = `condition_${selectedConditionIndex}`;
        const { id, label, statistic, description } = item;
        const checked = id === activeConditionId;
        const panelId = `panel_${id}`;

        return (
            <CardSelectButtonsUI
                id={id}
                panelId={panelId}
                headingText={label}
                secondaryText={statistic}
                bodyText={description}
                onClick={this.onClickConditionPanel}
                selected={checked}
                key={statistic}
            ></CardSelectButtonsUI>
        );
    };

    renderTitle = (): JSX.Element => {
        return (
            <>
                {this.props.pageTitle}
                <span
                    onClick={() => this.setState({ ...this.state, vehicleConditionModalIsOpen: true })}
                    data-testid="vehicle-info"
                    id="vehicle-info-icon"
                >
                    <SVGIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="16" x2="12" y2="12" />
                        <line x1="12" y1="8" x2="12.01" y2="8" />
                    </SVGIcon>
                </span>
            </>
        );
    };

    closeVehicleConditionDetailsModal(): void {
        this.setState({ ...this.state, vehicleConditionModalIsOpen: false });
    }

    PageSubTitle = () => (
        <GTPFlowPageSubtitle
            pageSubtitle={this.props.pageSubTitle}
            suffix={
                <>
                    You can still get an estimated value for your vehicle using our Kelley Blue Book<sup>®</sup> Trade-In Value
                    Tool.
                </>
            }
        />
    );

    render(): JSX.Element {
        const { dispatchAnalytics, goToRoute, stepperRoutes, next, removeTrade, hasUniqueEstimateOption, goBack } = this.props;
        const isHidden = this.props.isExcellentHiddenWebsite || this.props.isExcellentHiddenListing;
        const cards = isHidden ? TRADE_IN_VEHICLE.filter((card) => card.label !== 'Excellent') : TRADE_IN_VEHICLE;
        const index = this.state.selectedConditionIndex;

        const handleBackButtonClick = () => {
            goBack(hasUniqueEstimateOption);
        };

        return (
            <TradePage
                pageClass="vehicle-condition-page"
                pageTitle={this.renderTitle() as unknown as string | JSX.Element}
                pageSubTitle={this.PageSubTitle()}
                stepperRoutes={stepperRoutes}
                goToRoute={goToRoute}
                dispatchAnalytics={dispatchAnalytics}
                footerProps={{
                    onActionButtonClick: next,
                    showBackButton: true,
                    buttonText: 'Continue To Details',
                    disableActionButton: index === -1,
                    fullWidthLayout: true,
                    showSkipButton: true,
                    skipButtonText: 'Cancel Trade-In',
                    onSkipButtonClick: removeTrade,
                    onBackButtonClick: handleBackButtonClick
                }}
                renderDisclaimer={this.props.renderDisclaimer}
                showTradeErrorMessage={false}
                staticAssetsConfig={config.staticImages}
                stepLabelVisible={true}
            >
                <Grid id="dr-p202-vc-grid" data-hidden={isHidden}>
                    {cards.map(this.composeConditionPanels)}
                </Grid>
                <VehicleConditionDescriptionModalUI
                    isOpen={this.state.vehicleConditionModalIsOpen}
                    handleClose={this.closeVehicleConditionDetailsModal.bind(this)}
                    isExcellentHiddenWebsite={this.props.isExcellentHiddenWebsite}
                    isExcellentHiddenListing={this.props.isExcellentHiddenListing}
                />
            </TradePage>
        );
    }
}

export default VehicleConditionUI;

// interfaces/types
import { FeatureTogglesAndUrlOnly, IUrlOnlyFeatureToggles } from './featureToggleTypes';

export interface IGlobalConfig {
    featureToggles: IUrlOnlyFeatureToggles;
}

export const globalConfig = (): IGlobalConfig => {
    const result = (window as any).drs__SpGlobalConfig || {};
    return result;
};

export function isP202RedesignEnabled(): boolean {
    const featureToggles = (globalConfig().featureToggles as FeatureTogglesAndUrlOnly) || {};
    return featureToggles.enableP202Redesign;
}

export const useHeaderFooterState = () => {
    const featureToggles = (globalConfig().featureToggles as FeatureTogglesAndUrlOnly) || {};
    return featureToggles.useHeaderFooterState || false;
};

export const isSubaruGTPV2ServiceEnabled = () => {
    const featureToggles = (globalConfig().featureToggles as FeatureTogglesAndUrlOnly) || {};
    return featureToggles.enableSubaruGTPV2Service || false;
};

export const isGhostVinEnabled = () => {
    const featureToggles = (globalConfig().featureToggles as FeatureTogglesAndUrlOnly) || {};
    return featureToggles.enableGhostVinDealer || false;
};

const featureToggles = {
    isP202RedesignEnabled,
    useHeaderFooterState,
    isSubaruGTPV2ServiceEnabled,
    isGhostVinEnabled
};

export default featureToggles;

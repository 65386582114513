import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { formatUtils } from '@makemydeal/dr-common-utils';
import * as tradeInSelector from '../../../store/mmd/tradeIn';
import TradeInSummaryUI, { ITradeInSummaryUIProps } from './TradeInSummaryUI';
import { getGlobalSelector } from '../../../store/mmd/mmd';

export const mapStateToProps = (state): ITradeInSummaryUIProps => {
    const tradeIn = tradeInSelector.getTradeInComponent(state);
    const shouldIncludeTradeIn = tradeInSelector.getShouldIncludeTradeIn(tradeIn);
    const shouldIgnoreValues = shouldIncludeTradeIn === false;
    const rawTradeInValue = shouldIgnoreValues ? 0 : tradeInSelector.getTradeInValue(tradeIn);
    const tradeInEquity = shouldIgnoreValues ? 0 : tradeInSelector.getTradeInEquity(tradeIn);
    const amountOwed = shouldIgnoreValues ? 0 : tradeInSelector.getTradeInAmountOwed(tradeIn);
    const formatTradeIn = formatUtils.formatDollarsAndCents(tradeInEquity);
    const source = getGlobalSelector().additionalSelectors.tradeInSelectors.getTradeInSource(state);
    const hasACV = tradeInSelector.getTradeInHasACV(state);

    return {
        formatTradeIn,
        tradeInValue: tradeInEquity,
        amountOwed,
        hasACV,
        source,
        rawTradeInValue
    };
};

const TradeInSummary = withRouter(connect(mapStateToProps)(TradeInSummaryUI));

export default TradeInSummary;

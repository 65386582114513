// externals
import * as React from 'react';

// components
import TradeInRoutingLegacy from '../legacy/components/TradeInRouting';
import TradeInRouting from '../improvedWorkflow/components/TradeInRouting';

// interfaces/types
import { IRootProps } from './RootProps';

// selectors
import * as globalConfigFeatureToggleSelectors from '../store/globalConfigFeatureToggles';

export const RootComponent: React.FC<IRootProps> = (props: IRootProps) => {
    const globalConfigIsP202RedesignEnabled = globalConfigFeatureToggleSelectors.isP202RedesignEnabled();
    const selectorPropIsP202RedesignEnabled = props.selector.additionalSelectors.configSelectors.isP202RedesignEnabled({});
    const overallIsP202RedesignEnabled = globalConfigIsP202RedesignEnabled || selectorPropIsP202RedesignEnabled;

    if (!overallIsP202RedesignEnabled) {
        return <TradeInRoutingLegacy {...props} />;
    }

    return <TradeInRouting {...props} />;
};

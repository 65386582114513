// externals
import { connect } from 'react-redux';

// libraries
import { ITradeInState, navigateTo } from '@makemydeal/dr-activities-common';
import { onOpenLeadFormModal } from '@makemydeal/dr-activities-lead-form';

// components
import ManualSummaryUI from './ManualSummaryUI';

// interfaces/types
import { IManualSummaryUIDispatchProps, IManualSummaryUIStateProps } from './ManualSummaryUI';
import { IRootProps } from '../../../../components/RootProps';

// selectors
import * as tradeInSelectors from '../../../../store/mmd/tradeIn';

// actions
import * as tradeInComponentActionCreators from '../../../../store/actionCreators';

// consts/enums
import { Actions as types } from '../../../../store/actions';

// utils
import { isLeadFormSubmittedSuccess } from '../../../../store/mmd/leadForm';
import { getNextCardDetails, getNextStepInfo } from '../../../../store/mmd/navigationCard';
import { isShareDealEnabled, isTradeinLeadformValuationEnabled } from '../../../../store/featureToggles';
import * as tradeInRoutes from '../../../../utils/routes';

// config
// import config from '../../../store/mmd/config';
import { isDealerUser, isDeskingUser, isShowroomExperience } from '../../../../utils/configUtils';

import * as tradeInActionCreators from '../../../../store/actionCreators';
import * as dealerSelectors from '../../../../store/mmd/dealerSelectors';
import { processStepperRoutes } from '../../../../utils/tradeInDecisionLogic';
import { STEPPER_ROUTES } from '../../../../utils';
import * as globalConfigFeatureToggleSelectors from '../../../../store/globalConfigFeatureToggles';

const mapStateToProps = (state: any, ownProps: IRootProps): IManualSummaryUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const cardDetails = getNextCardDetails(state);
    const isTradeLeadFormEnabled = isTradeinLeadformValuationEnabled(state);
    // const imageFPO = urlBuilder.buildFromConfig(config.staticImages, Resources.FPO_IMAGE);
    const isLeadFormSubmitted = isLeadFormSubmittedSuccess(state);
    const isSubaruGtpEligible = tradeInSelectors.isSubaruGtpEligible(state);
    const tradeInSubaruGtpFlowError = tradeInSelectors.getTradeInSubaruGtpFlowError(state);
    const isIcoTradeInFlow = tradeInSelectors.isIcoTradeInFlow(state);
    const hasAcv = tradeInSelectors.getTradeInHasACV(state);
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);
    const tradePaymentFailed = tradeInSelectors.getTradePaymentFailed(state);
    const vehicleId = tradeInSelectors.getTradeInVehicleId(tradeIn);
    const zipCode = tradeInSelectors.getTradeInZip(tradeIn);
    const vehicleMileage = tradeInSelectors.getTradeInVehicleMileage(tradeIn);
    const vehicleCondition = tradeInSelectors.getTradeInVehicleCondition(tradeIn);
    const year = tradeInSelectors.getTradeInVehicleYear(tradeIn);
    const make = tradeInSelectors.getTradeInVehicleMakeName(tradeIn);
    const model = tradeInSelectors.getTradeInVehicleModelName(tradeIn);
    const vin = tradeInSelectors.getTradeInVehicleVin(tradeIn);
    const tradeInValue = tradeInSelectors.getTradeInValue(tradeIn, true);
    const loanBalance = tradeInSelectors.getTradeInAmountOwed(tradeIn);
    const calculatedTradeInValue = tradeInValue - loanBalance;
    const currentDate = new Date();
    const expirationDate = tradeInSelectors.getExpirationDate(tradeIn);
    const showShareDeal = isShareDealEnabled(state);
    const userIsDealer = isDealerUser(ownProps.config);
    const userIsDesking = isDeskingUser(ownProps.config);
    const dealerPhoneNumber = dealerSelectors.getDealerPhone(state);
    const isLeadFormEnabled = tradeInSelectors.shouldShowLeadForm(state);
    const isManualEntryFlow = tradeInSelectors.isManualEntryFlow(state);
    const valueSource = tradeInSelectors.getValueSource(tradeIn);
    const isSalesView = isShowroomExperience(ownProps.config);
    const stepperRoutes = processStepperRoutes(state, STEPPER_ROUTES.summarySubmitForAppraisal, isSalesView);
    const ghostVinDealerConfig = dealerSelectors.isGhostVinDealerEnabled(state);
    const isGhostVinEnabled = globalConfigFeatureToggleSelectors.isGhostVinEnabled();
    const nextStepInfo = getNextStepInfo(state);
    const showActionButton = nextStepInfo ? true : false;
    const actionButtonText = nextStepInfo ? nextStepInfo.title : '';

    return {
        cardDetails,
        isTradeLeadFormEnabled,
        imageFPO: '',
        isSubaruGtpEligible,
        isLeadFormSubmitted,
        isIcoTradeInFlow,
        hasAcv,
        showShareDeal,
        vehicleOptions,
        tradePaymentFailed,
        vehicleId,
        zipCode,
        vehicleMileage,
        vehicleCondition,
        year,
        make,
        model,
        vin,
        tradeInValue,
        loanBalance,
        calculatedTradeInValue,
        currentDate,
        expirationDate,
        userIsDealer,
        userIsDesking,
        dealerPhoneNumber,
        isLeadFormEnabled,
        isManualEntryFlow,
        valueSource,
        stepperRoutes,
        ghostVinDealerConfig,
        isGhostVinEnabled,
        actionButtonText,
        showActionButton,
        tradeInSubaruGtpFlowError
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: IRootProps): IManualSummaryUIDispatchProps => {
    return {
        goToRoute: (route) => {
            dispatch(navigateTo(route));
        },
        editTradeIn: () => {
            dispatch(tradeInComponentActionCreators.trackEditIconOnCompletionClicked());
        },
        termsDetailsClicked: () => {
            dispatch(tradeInComponentActionCreators.trackTermsDetailsOnCompletionClicked());
        },
        loadLeadForm: (skipLink) => {
            dispatch(
                onOpenLeadFormModal(
                    'Almost to Your Trade-In Value',
                    "Your trade-in value is on the next page.  Let's submit your deal status to unlock.",
                    skipLink,
                    types.TRADE_IN_LEAD_SUBMITTED,
                    types.TRADE_IN_PERFORM_UPDATE,
                    types.REMOVE_TRADE_IN_AND_CLOSE_MODAL
                )
            );
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        },
        tradeInEstimateDisplayed: () => {
            dispatch(tradeInComponentActionCreators.sendTradeInEstimateDisplayedAnalytics());
        },
        removeTrade: () => {
            dispatch(tradeInActionCreators.removeTradeIn());
            dispatch(navigateTo(tradeInRoutes.TRADE_IN));
        },
        goToNextStep: () => {
            dispatch((dispatch, getState) => {
                const state = getState();
                const nextStepInfo = getNextStepInfo(state);
                if (nextStepInfo) {
                    dispatch(navigateTo(nextStepInfo.route));
                    dispatch(tradeInComponentActionCreators.tradeInCompletedContinue());
                }
            });
        }
    };
};

const ManualSummary = connect(mapStateToProps, mapDispatchToProps)(ManualSummaryUI);

export default ManualSummary;

// externals
// TODO: US775769 - resolve usage of commented out redux-api-middleware types
import {
    RSAA,
    RSAAAction
    // RSAAFailureType,
    // RSAAFailureTypeDescriptor,
    // RSAARequestType,
    // RSAARequestTypeDescriptor,
    // RSAASuccessType,
    // RSAASuccessTypeDescriptor
} from 'redux-api-middleware';

// libraries
import type { ITradeInState, ITradeInVehicle } from '@makemydeal/dr-activities-common';
import type { AnyFSA, FSA, FSAWithMeta, SimpleFSA } from '@makemydeal/dr-platform-shared';

// internal
import config from './mmd/config';

// consts/enums
import { Actions as types, devHelperActions as devHelperTypes } from './actions';

// interfaces/types
import { ISubaruEligibilityFields } from '../common/components/subaruEligibility/SubaruEligibilityUI';
import type { ITradeInSubaruGtpFlowError } from '../types';

// selectors
import * as globalConfigFeatureToggleSelectors from './globalConfigFeatureToggles';
import * as tradeInComponentSelectors from './mmd/tradeIn';

// utils
import { getFormattedTradeInVehicleOptions } from '../utils/tradeUtils';

const { INIT_SUCCESS, ROUTE_TRADE_IN, SYNC_REMEMBER_ME_TRADE_PREFERENCE } = types;

export const DECISION_TYPES = {
    ICO: 'instant cash offer',
    TRADE_IN_VALUE: 'trade-in value',
    ENTER_OWN_VALUE: 'enter my own value',
    SUBARU_GTP: 'subaru gtp'
};

export const dispatchAnalytics = (key: string, description: string) => {
    return {
        type: types.TRADE_IN_ANALYTICS,
        payload: { key, description }
    };
};

export const skipTradeIn = () => {
    return {
        isCompleted: false,
        isSkipped: true,
        type: types.SKIP_TRADE_IN
    };
};

export const updateTradeInEntryPoint = (entryPoint: string) => {
    return {
        entryPoint,
        type: types.UPDATE_TRADE_IN_ENTRY_POINT
    };
};

export const tradeInStarted = () => {
    return {
        isCompleted: true,
        type: types.TRADE_IN_OPENED
    };
};

export const tradeInEstimateOnLoad = () => {
    return {
        type: types.TRADE_IN_ESTIMATE_RECEIVED
    };
};

export const chooseTradeIn = () => {
    return {
        isCompleted: false,
        type: types.CHOOSE_TRADE_IN
    };
};

export const tradeInLoanStatusApplied = () => {
    return {
        type: types.TRADE_IN_LOAN_STATUS_APPLIED
    };
};

export const financeTypeChanged = () => {
    return {
        type: types.TRADE_IN_FINANCE_TYPE_CHANGED
    };
};

export const trackFinanceTypeAnalytics = (shouldIncludeTradeIn: boolean) => {
    return {
        type: types.REDESIGN_TRADE_IN_FINANCE_TYPE_CHANGED,
        payload: { shouldIncludeTradeIn }
    };
};

export const sendTradeInEstimateDisplayedAnalytics = () => {
    return {
        type: types.TRADE_IN_ESTIMATE_DISPLAYED
    };
};

export const sendVehicleInfoAnalytics = (type: string) => {
    return {
        type: types[`TRADE_IN_VEHICLE_${type.toUpperCase()}_CHANGED`]
    };
};

export const sendVehicleAmountOwedAnalytics = () => {
    return {
        type: types.TRADE_IN_FINANCE_BALANCE_CHANGED
    };
};

export const sendTradeDecisionChangedAnalytics = (option: string, isShopperEnteredTradeEnabled) => {
    const type = types.TRADE_IN_DECISION_TYPE_CHANGED;
    const payload = { option: '', isShopperEnteredTradeEnabled: false };
    payload.isShopperEnteredTradeEnabled = isShopperEnteredTradeEnabled;
    switch (option) {
        case DECISION_TYPES.ICO:
            payload.option = 'ICO';
            break;
        case DECISION_TYPES.TRADE_IN_VALUE:
            payload.option = 'STANDARD';
            break;
        default:
            payload.option = 'SHOPPER_ENTERED';
    }
    return {
        type,
        payload
    };
};

export const shopperInManualEntry = () => {
    return { type: types.SHOPPER_IN_MANUAL_ENTRY };
};

export const sendTradeDecisionClickedAnalytics = (option: string) => {
    return {
        type: types.TRADE_IN_DECISION_CLICKED,
        payload: option === 'instant cash offer' ? 'ICO' : 'STANDARD'
    };
};

export const sendTradeDecisionDisplayedAnalytics = () => {
    return {
        type: types.TRADE_IN_DECISION_PAGE_DISPLAYED
    };
};

export const externalIcoCompleted = (completed) => {
    return {
        type: types.TRADE_IN_EXTERNAL_ICO_COMPLETED,
        payload: completed
    };
};

export const sendAddICOAnalytics = () => {
    return {
        type: types.TRADE_IN_ADD_ICO_VALUE
    };
};

export const sendIcoPageOpenedAnalytics = () => {
    return {
        type: types.TRADE_IN_ICO_DISPLAYED
    };
};

export const sendIcoExitClickedAnalytics = () => {
    return {
        type: types.TRADE_IN_ICO_EXIT_CLICKED
    };
};

export const sendIcoBalanceCardDisplayedAnalytics = () => {
    return {
        type: types.TRADE_IN_ICO_BALANCE_CARD_DISPLAYED
    };
};

export const sendIcoActiveLoanAnalytics = (option: string) => {
    return {
        type: types.TRADE_IN_ICO_ACTIVE_LOAN,
        payload: option.toUpperCase() || ''
    };
};

export const sendIcoBalanceChangedAnalytics = () => {
    return {
        type: types.TRADE_IN_ICO_BALANCE_CHANGED
    };
};

export const sendIcoBalanceClickedAnalytics = (option: string) => {
    return {
        type: types.TRADE_IN_ICO_BALANCE_CLICKED,
        payload: option.toLowerCase() === 'yes' ? 'ACTIVE' : 'NO'
    };
};

export const trackVehicleConditionAnalytics = (condition: string) => {
    return {
        type: types.TRADE_IN_CONDITION_SELECTED,
        payload: condition
    };
};

export const tradeInConditionApplied = () => {
    return {
        type: types.TRADE_IN_CONDITION_APPLIED
    };
};

export const tradeInSkipped = () => {
    return {
        type: types.TRADE_IN_SKIPPED
    };
};

export const tradeInCompletedContinue = () => {
    return {
        type: types.TRADE_IN_COMPLETED_CONTINUE
    };
};

export const updateTradeInCompletedState = () => {
    return {
        isCompleted: true,
        type: types.COMPLETE_TRADE_IN
    };
};

export const resetTradeIn = () => {
    return {
        type: types.RESET_TRADE_IN
    };
};

export const resetFieldsNewVin = () => {
    return {
        type: types.RESET_FIELDS_NEW_VIN
    };
};

export function tradeInEstimateSuccess() {
    return {
        type: types.TRADE_IN_ESTIMATION_SUCCESS
    };
}

export function addMockValidTrade() {
    return {
        type: devHelperTypes.ADD_MOCK_VALID_TRADE
    };
}

export function addMockPaymentFailureTrade() {
    return {
        type: devHelperTypes.ADD_MOCK_PAYMENT_FAILURE_TRADE
    };
}

export function addMockNegativeEquityTrade() {
    return {
        type: devHelperTypes.ADD_NEGATIVE_EQUITY_TRADE
    };
}

export type TradeInEndpointDefn = {
    path: string;
    types: AnyRsaaTypes;
};

export type TradeInEndpointDefns = {
    [key: string]: TradeInEndpointDefn;
};

export function showZipCodeModal() {
    return {
        type: types.SHOW_ZIP_CODE_MODAL,
        payload: {
            hasDarkBackground: true
        }
    };
}

/**
 * VEHICLE INFO ACTIONS
 */
export const tradeInEndpoints: TradeInEndpointDefns = {
    estimate: {
        path: 'trade/estimate',
        types: [types.TRADE_IN_ESTIMATION_REQUEST, tradeInEstimateSuccess(), types.TRADE_IN_ESTIMATION_FAILURE]
    },
    makes: {
        path: 'trade/lookup/makes',
        types: [types.TRADE_IN_MAKES_REQUEST, types.TRADE_IN_MAKES_SUCCESS, types.TRADE_IN_MAKES_FAILURE]
    },
    models: {
        path: 'trade/lookup/models',
        types: [types.TRADE_IN_MODELS_REQUEST, types.TRADE_IN_MODELS_SUCCESS, types.TRADE_IN_MODELS_FAILURE]
    },
    validateOptions: {
        path: 'trade/validate/options',
        types: [
            types.TRADE_IN_VALIDATE_OPTIONS_REQUEST,
            types.TRADE_IN_VALIDATE_OPTIONS_SUCCESS,
            types.TRADE_IN_VALIDATE_OPTIONS_FAILURE
        ]
    },
    vehicleOptions: {
        path: 'trade/lookup/options',
        types: [types.TRADE_IN_OPTIONS_REQUEST, types.TRADE_IN_OPTIONS_SUCCESS, types.TRADE_IN_OPTIONS_FAILURE]
    },
    trims: {
        path: 'trade/lookup/trims',
        types: [types.TRADE_IN_TRIMS_REQUEST, types.TRADE_IN_TRIMS_SUCCESS, types.TRADE_IN_TRIMS_FAILURE]
    },
    years: {
        path: 'trade/lookup/years',
        types: [types.TRADE_IN_YEARS_REQUEST, types.TRADE_IN_YEARS_SUCCESS, types.TRADE_IN_YEARS_FAILURE]
    },
    vehicles: {
        path: 'trade/lookup/vehicles',
        types: [types.TRADE_IN_VEHICLES_REQUEST, types.TRADE_IN_VEHICLES_SUCCESS, types.TRADE_IN_VEHICLES_FAILURE]
    },
    lookupTradeUsingIds: {
        path: 'trade/lookup-ico-trade',
        types: [types.LOOKUP_ICO_TRADE_REQUEST, types.LOOKUP_ICO_TRADE_SUCCESS, types.LOOKUP_ICO_TRADE_FAILURE]
    }
};

export type ComplexRsaaType = { type: string; payload?: any; meta?: any };
export type BaseRsaaType = string | ComplexRsaaType;
// TODO: US775769 - resolve usage of commented out redux-api-middleware types
export type AnyRsaaTypes = any[]; // [RSAARequestType, RSAASuccessType, RSAAFailureType];
export type FullRsaaTypes = any[]; // [RSAARequestTypeDescriptor, RSAASuccessTypeDescriptor, RSAAFailureTypeDescriptor];

export interface IExcludeCallRequest {
    endpoint: string;
    method: string;
    headers: { [key: string]: string };
    types: AnyRsaaTypes;
    body?: any; // TODO: Define type - it isn't string
}

export function updateVehicle(vehicle) {
    return {
        type: types.UPDATE_TRADE_IN_VEHICLE_INFO,
        payload: { vehicle }
    };
}

export const handleSetVehicleNavigation = () => {
    return {
        type: types.HANDLE_TRADE_IN_SET_VEHICLE_NAVIGATION
    };
};

export interface IElement {
    value: string;
    text: string;
}

export const buildRsaaType = (rsaaType: BaseRsaaType): ComplexRsaaType => {
    const complexRsaaType = rsaaType as ComplexRsaaType;
    if (complexRsaaType.type) {
        return complexRsaaType;
    }
    return {
        type: rsaaType as string
    };
};

export const executeSpBffCall = (
    endpointObject: TradeInEndpointDefn,
    params = '',
    httpVerb = 'GET',
    payload = '',
    callSite: string = null,
    meta: any = undefined
) => {
    const url = config.utils.buildApiUrlWithServiceEndpoint({ service: 'bff', endPoint: '' });
    const endpoint = `${url}/${endpointObject.path}?${params}`;
    const headers: any = {
        'Content-Type': 'application/json'
    };
    if (callSite) {
        headers['x-dr-call-site'] = callSite;
    }

    const metaFunction = (_action: RSAAAction, _state: any, _res: Response) => meta;
    const types = !meta
        ? endpointObject.types
        : ([
              {
                  ...buildRsaaType(endpointObject.types[0] as BaseRsaaType),
                  meta: metaFunction
              },
              {
                  ...buildRsaaType(endpointObject.types[1] as BaseRsaaType),
                  meta: metaFunction
              },
              {
                  ...buildRsaaType(endpointObject.types[2] as BaseRsaaType),
                  meta: metaFunction
              }
          ] as FullRsaaTypes);
    const request: IExcludeCallRequest = {
        endpoint,
        method: httpVerb,
        headers,
        types
    };

    if (httpVerb === 'POST') {
        request.body = payload;
    }

    const result = {};
    result[RSAA] = request;
    return result;
};

export function lookupIcoTradeUsingIds(yearId: number, makeId: number, modelId: number, trimId: number, vehicleClass = 'used') {
    const payload = {
        yearId,
        makeId,
        modelId,
        trimId
    };
    return executeSpBffCall(tradeInEndpoints.lookupTradeUsingIds, `vehicleClass=${vehicleClass}`, 'POST', JSON.stringify(payload));
}

// We only care about "used" cars here because we're using the KBB trade-in service
// for trading in a used car- not for doing a valuation on a new car.
export function fetchTradeInYears(vehicleClass = 'used') {
    return executeSpBffCall(tradeInEndpoints.years, `vehicleClass=${vehicleClass}`);
}

export function fetchTradeInMakes(year, vehicleClass = 'used') {
    return executeSpBffCall(tradeInEndpoints.makes, `year=${year}&vehicleClass=${vehicleClass}`);
}

export function fetchTradeInModels(year, makeid, vehicleClass = 'used', callSite: string = null) {
    return executeSpBffCall(
        tradeInEndpoints.models,
        `year=${year}&makeid=${makeid}&vehicleClass=${vehicleClass}`,
        undefined,
        undefined,
        callSite
    );
}

export function fetchTradeInEquipmentOptions(vehicle: ITradeInVehicle, zip) {
    return executeSpBffCall(
        tradeInEndpoints.vehicleOptions,
        `year=${vehicle.year}&makeid=${vehicle.make.id}&modelid=${vehicle.model.id}` +
            `&vehicleId=${vehicle.trim.vehicleId}&zip=${zip}&trimid=${vehicle.trim.id}`
    );
}

export function fetchTradeInVehicleId(vehicle: ITradeInVehicle) {
    return executeSpBffCall(
        tradeInEndpoints.vehicles,
        // tslint:disable-next-line: max-line-length

        `year=${vehicle.year}&makeid=${vehicle.make.id}&modelid=${vehicle.model.id}&trimid=${vehicle.trim.id}`
    );
}

export function fetchTradeInTrims(year, makeid, modelid, vehicleClass = 'used') {
    return executeSpBffCall(
        tradeInEndpoints.trims,
        `year=${year}&makeid=${makeid}&modelid=${modelid}&vehicleClass=${vehicleClass}`
    );
}

export function goToEquipmentOptions() {
    return {
        type: types.ROUTE_TRADE_IN_EQUIPMENT_OPTIONS
    };
}

export function goToValuation() {
    return {
        type: types.ROUTE_TRADE_IN_VALUATION
    };
}

// TODO: Fix all calls to this
export function validateTradeInOptions(tradeIn: ITradeInState) {
    const year = tradeInComponentSelectors.getTradeInVehicleYear(tradeIn);
    const makeId = tradeInComponentSelectors.getTradeInVehicleMakeId(tradeIn);
    const modelId = tradeInComponentSelectors.getTradeInVehicleModelId(tradeIn);
    const trimId = tradeInComponentSelectors.getTradeInVehicleTrimId(tradeIn);

    const payload = JSON.stringify({
        vehicleId: tradeInComponentSelectors.getTradeInVehicleId(tradeIn),
        year,
        makeId,
        modelId,
        trimId,
        zip: tradeInComponentSelectors.getTradeInZip(tradeIn),
        vehicleClass: 'used',
        condition: tradeInComponentSelectors.getTradeInVehicleCondition(tradeIn),
        vehicleOptions: tradeInComponentSelectors.getTradeInVehicleOptions(tradeIn)
    });

    return executeSpBffCall(tradeInEndpoints.validateOptions, '', 'POST', payload);
}

export function updateVehicleOptions(vehicleOptions) {
    return {
        type: types.UPDATE_TRADE_IN_VEHICLE_INFO,
        payload: { vehicleOptions }
    };
}

export function updatePurchasePreference(ownership) {
    return {
        type: types.UPDATE_TRADE_IN_PAYMENT_PREFERENCE,
        payload: { ownership }
    };
}

export function clearYMMT(ymmtObject) {
    return {
        type: types.TRADE_IN_CLEAR_VEHICLE_YMMT,
        payload: { ...ymmtObject }
    };
}

export function updateZip(zip) {
    return {
        type: types.UPDATE_TRADE_IN_ZIP,
        payload: { zip }
    };
}

export function updateTradeInCondition(condition) {
    return {
        type: types.UPDATE_TRADE_IN_CONDITION,
        payload: { condition }
    };
}

export function updateTradeInBalanceOwed(amountOwed) {
    return {
        type: types.UPDATE_TRADE_IN_BALANCE_OWED,
        payload: { amountOwed }
    };
}

export function applyTradeInSelections() {
    return {
        type: types.APPLY_TRADE_IN_SELECTION
    };
}

export const cancelTradeIn = () => (dispatch) => {
    dispatch({
        type: types.CANCEL_TRADE_IN
    });
};

export function goToSelection() {
    return {
        type: types.ROUTE_TRADE_IN_SELECTION
    };
}

export interface ISubaruEligibilitySummaryClickAction
    extends FSA<typeof types.SUBARU_ELIGIBILITY_SUMMARY_CLICK, ISubaruEligibilityFields> {}

export function subaruEligibilitySummaryClick(eligibilityFields: ISubaruEligibilityFields): ISubaruEligibilitySummaryClickAction {
    return {
        type: types.SUBARU_ELIGIBILITY_SUMMARY_CLICK,
        payload: eligibilityFields
    };
}
export interface ISubaruEligibilityAction extends FSA<typeof types.SUBARU_ELIGIBILITY_CLICK, ISubaruEligibilityFields> {}

export function subaruEligibilityClick(eligibilityFields: ISubaruEligibilityFields): ISubaruEligibilityAction {
    return {
        type: types.SUBARU_ELIGIBILITY_CLICK,
        payload: eligibilityFields
    };
}

export interface ISubaruEligibilityNoVinClickAction
    extends FSA<typeof types.SUBARU_ELIGIBILITY_NO_VIN_CLICK, Partial<ISubaruEligibilityFields>> {}

export function subaruEligibilityNoVinClick(
    eligibilityFields: Partial<ISubaruEligibilityFields>
): ISubaruEligibilityNoVinClickAction {
    return {
        type: types.SUBARU_ELIGIBILITY_NO_VIN_CLICK,
        payload: eligibilityFields
    };
}

export function applyTradeInEquipmentOptions(page?: string) {
    return {
        type: types.APPLY_TRADE_IN_EQUIPMENT_OPTIONS,
        payload: page
    };
}

export function toggleShowAllVehicleEquipment(showAll: boolean) {
    return {
        type: types.TRADE_IN_EQUIPMENT_DISPLAY,
        payload: showAll ? 'SHOWALL' : 'HIDEALL'
    };
}

export function trackSearchTypeAnalytics(searchType: string) {
    return {
        type: types.TRADE_IN_SEARCHTYPE_CLICKED,
        payload: searchType.toUpperCase() || ''
    };
}

export function skipTradeInSearch() {
    return {
        type: types.TRADE_IN_SEARCH_CLICKED
    };
}

// Generic completion page events
export function trackTermsDetailsOnCompletionClicked() {
    return {
        type: types.COMPLETION_TERMS_DETAILS_CLICKED
    };
}

export function trackEditIconOnCompletionClicked() {
    return {
        type: types.COMPLETION_PAGE_EDIT_CLICKED
    };
}

export type UpdateTradeVehicleColorAction = {
    type: typeof types.UPDATE_TRADE_VEHICLE_COLOR;
    payload: string;
};

export const updateVehicleColor = (colorValue: string): UpdateTradeVehicleColorAction => {
    return {
        type: types.UPDATE_TRADE_VEHICLE_COLOR,
        payload: colorValue
    };
};

export function changeEquipmentOption(categoryName, optionValueId, isChecked, enableVRS = false) {
    return {
        type: types.UPDATE_TRADE_IN_EQUIPMENT_OPTION,
        payload: {
            categoryName,
            optionValueId,
            isChecked,
            enableVRS
        }
    };
}

export function initTradeIn(tradeInObj) {
    return {
        type: INIT_SUCCESS,
        payload: {
            offer: {
                tradeIn: tradeInObj
            }
        }
    };
}

export enum TradeInEvaluationStep {
    None = 0,
    EquipmentOptionStep1,
    EquipmentOptionStep2
}

export function performTradeInValuation(tradeIn: ITradeInState, step: TradeInEvaluationStep = TradeInEvaluationStep.None) {
    const useHeaderFooterState =
        globalConfigFeatureToggleSelectors.isP202RedesignEnabled() && globalConfigFeatureToggleSelectors.useHeaderFooterState();
    const estimationPayload = JSON.stringify({
        vehicleId: tradeInComponentSelectors.getTradeInVehicleId(tradeIn),
        mileage: `${tradeInComponentSelectors.getTradeInVehicleMileage(tradeIn)}`,
        zip: `${tradeInComponentSelectors.getTradeInZip(tradeIn)}`,
        vehicleClass: 'used',
        selectedCondition: tradeInComponentSelectors.getTradeInVehicleCondition(tradeIn),
        vehicleOptions: useHeaderFooterState
            ? getFormattedTradeInVehicleOptions(tradeIn)
            : tradeInComponentSelectors.getTradeInVehicleOptions(tradeIn),
        yearId: `${tradeIn.vehicle.year}`,
        modelId: tradeIn.vehicle.model.id,
        makeId: tradeIn.vehicle.make.id,
        trimId: tradeIn.vehicle.trim.id
    });

    const callSite = undefined;
    const meta = { step };
    return executeSpBffCall(tradeInEndpoints.estimate, '', 'POST', estimationPayload, callSite, meta);
}

/**
 *
 * @param { boolean } shouldIncludeTradeIn
 */
export function applyToAmountFinanced(shouldIncludeTradeIn) {
    return {
        type: types.UPDATE_TRADE_IN_AMOUNT_FINANCED,
        payload: { shouldIncludeTradeIn }
    };
}
/**
 * @param { Object } tradeIn
 * @param { number } tradeIn.ammountOwed
 * @param { number } tradeIn.value
 * @param { boolean } tradeIn.shouldIncludeTradeIn
 */

export const updateTradeInValuation = () => ({
    type: types.UPDATE_TRADE_IN_VALUATION
});

export const updateTradeIn = (payload) => ({
    type: types.UPDATE_TRADE_IN,
    payload
});

export const resetValuation = (payload) => ({
    type: types.RESET_TRADE_IN_VALUATION,
    payload
});

export const updateResetValuationFlag = (payload) => ({
    type: types.UPDATE_RESET_VALUATION_FLAG,
    payload
});

export const removeTradeIn = () => {
    return {
        type: types.REMOVE_TRADE_IN
    };
};

export const updateTradeInValue = (payload) => ({
    type: types.UPDATE_TRADE_IN_VALUE,
    payload
});

export const setInTradeInSubaruGtpFlow = () => ({
    type: types.SET_IN_TRADE_IN_SUBARU_GTP_FLOW
});

export interface IGoToTradeInFlowPayload {
    isSubaruGTPRetry: boolean;
    option: string;
}
export interface IGoToTradeInFlowAction extends FSA<typeof types.GO_TO_TRADE_IN_FLOW, IGoToTradeInFlowPayload> {}

export function goToTradeInFlow(option: string, isSubaruGTPRetry = false): IGoToTradeInFlowAction {
    return {
        type: types.GO_TO_TRADE_IN_FLOW,
        payload: {
            isSubaruGTPRetry,
            option
        }
    };
}

export const updateTradeInCurrentLocation = (nextRoute: string) => {
    const action = {
        type: types.UPDATE_TRADE_IN_CURRENT_LOCATION,
        payload: {
            nextRoute
        }
    };
    return action;
};

export const setTradeInIsSubaruGtpEligible = (isSubaruGtpEligible = true) => {
    return {
        type: types.SET_TRADE_IN_IS_SUBARU_GTP_ELIGIBLE,
        payload: {
            isSubaruGtpEligible
        }
    };
};

export interface ISubaruEligibilitySetErrorActionPayload {
    tradeInSubaruGtpFlowError: ITradeInSubaruGtpFlowError;
}
export interface ISubaruEligibilitySetErrorAction
    extends FSA<typeof types.SUBARU_ELIGIBILITY_SUMMARY_CLICK, ISubaruEligibilitySetErrorActionPayload> {}

export function setTradeInSubaruGtpFlowError(
    tradeInSubaruGtpFlowError: ITradeInSubaruGtpFlowError
): ISubaruEligibilitySetErrorAction {
    return {
        type: types.SET_TRADE_IN_SUBARU_GTP_FLOW_ERROR,
        payload: {
            tradeInSubaruGtpFlowError
        }
    };
}

export const resetTradeInSubaruGtpFlowError = () => {
    return {
        type: types.RESET_TRADE_IN_SUBARU_GTP_FLOW_ERROR
    };
};

export const resetInTradeInSubaruGtpFlow = () => {
    return {
        type: types.RESET_IN_TRADE_IN_SUBARU_GTP_FLOW
    };
};

export const routeToTradeInStart = () => {
    return {
        type: ROUTE_TRADE_IN
    };
};

export const updateTradeInPaymentStatus = (payload) => ({
    type: types.UPDATE_TRADE_IN_PAYMENT_STATUS,
    payload
});

export const changedTradeIn = (payload) => {
    return {
        type: types.TRADE_IN_CHANGED,
        payload
    };
};

export const leadFormSubmit = () => {
    return {
        type: types.TRADE_IN_LEADFORM_SUBMITTED
    };
};

export const saveTradeIn = () => {
    return {
        type: types.TRADE_IN_PERFORM_UPDATE
    };
};

export const saveOrUpdateTradeIn = () => {
    return {
        type: types.TRADE_IN_SAVE_OR_UPDATE
    };
};

export const valuePageClikedTradein = () => {
    return {
        type: types.TRADE_IN_VALUE_PAGE_CLICKED
    };
};

export const testDriveRequestedFromTradeIn = () => {
    return {
        type: types.TEST_DRIVE_REQUESTED_FROM_TRADE_IN
    };
};

export const syncRememberedTradePreference = (payload) => {
    return {
        type: SYNC_REMEMBER_ME_TRADE_PREFERENCE,
        payload
    };
};

export const goToNext = (currentFlowRoute: string) => {
    return {
        type: types.ROUTE_NEXT,
        payload: {
            currentFlowRoute
        }
    };
};

export const goToSummaryLeadForm = () => {
    return {
        type: types.ROUTE_SUMMARY_LEAD_FORM
    };
};

export const goToDealSummary = () => {
    return {
        type: types.ROUTE_DEAL_SUMMARY
    };
};

export const goToTestDrive = () => {
    return {
        type: types.ROUTE_TEST_DRIVE
    };
};

export const handleNavigationCardSelected = (id?: string, cardName?: string, actualUserClick = false): any => {
    // actualUserClick is because this same action type is dispatched in navigation as an automated
    // action and not as a result of a user's click on the navigation card, which is what we want here
    return {
        type: types.NAVIGATION_CARD_SELECTED,
        payload: { id, cardName, actualUserClick }
    };
};

export const goToMenuProducts = () => {
    return {
        type: types.ROUTE_MENU_PRODUCTS
    };
};

// nextRouteOnSuccess if specified will be used to calculate the next available route to route to.
export const submitDealSummary = (nextRouteOnSuccess?: string) => {
    return {
        type: types.SUBMIT_DEAL_SUMMARY,
        payload: {
            nextRouteOnSuccess
        }
    };
};

export const submitOffer = (isFromLeadForm = false, offerUpdateType?: string): any => {
    const action: any = {
        type: types.SUBMIT_OFFER,
        payload: {
            isFromLeadForm
        }
    };
    if (offerUpdateType && offerUpdateType !== '') {
        action.payload.offerUpdateType = offerUpdateType;
    }
    return action;
};

export const applyAmountOwedAndUpdateValue = () => {
    return {
        type: types.APPLY_AMOUNT_OWED_AND_UPDATE_VALUE
    };
};

export const updateTradeValueSource = (payload?: { source: string; value: number; comments: string }) => {
    return {
        type: types.UPDATE_TRADE_VALUE_SOURCE,
        payload
    };
};

export const routeToManualEntry = (nextRoute: string) => {
    const action = {
        type: types.UPDATE_TRADE_IN_CURRENT_LOCATION,
        payload: {
            nextRoute
        }
    };
    return action;
};

export interface IHandleTradeInSearchNextAction extends SimpleFSA<typeof types.HANDLE_TRADE_IN_SEARCH_NEXT> {}
export const handleTradeInSearchNext = (): IHandleTradeInSearchNextAction => {
    return {
        type: types.HANDLE_TRADE_IN_SEARCH_NEXT
    };
};

export interface ITradeInSearchItem {
    bodyStyle?: string;
    displayName?: string;
    doors?: number;
    generationEndYear?: string;
    generationId?: number;
    generationInstanceId?: number;
    generationName?: string;
    generationSequence?: number;
    generationStartYear?: string;
    genericBodyStyle?: string;
    isConsumer?: boolean;
    kbbCategories?: string[];
    luxuryType?: string;
    makeId: number;
    makeName: string;
    marketingCategories?: string[];
    modelId: number;
    modelMarketName?: string;
    modelName: string;
    modelPlusTrimName?: string;
    modelYearId: number;
    oemBodyStyle?: string;
    originalMSRP?: number;
    relatedVehicleIds?: number[];
    size?: string;
    sortOrder?: number;
    sportType?: string;
    standardDrivetrain?: string;
    standardEngine?: string;
    standardTransmission?: string;
    trimId: number;
    trimName: string;
    vehicleClass?: string;
    vehicleId: string;
    vehicleName?: string;
    vin?: string;
    yearId: number;
}
export interface IHandleTradeInSearchSetVehicleActionPayload {
    item: ITradeInSearchItem;
}
export interface IHandleTradeInSearchNextAction
    extends FSA<typeof types.HANDLE_TRADE_IN_SEARCH_SET_VEHICLE, IHandleTradeInSearchSetVehicleActionPayload> {}
export const handleTradeInSearchSetVehicle = (item: any): IHandleTradeInSearchNextAction => {
    return {
        payload: {
            item
        },
        type: types.HANDLE_TRADE_IN_SEARCH_SET_VEHICLE
    };
};

export interface ISubaruEligibilityCheckActionMeta {
    originalParams: {
        vin: string;
        mileage: number;
        fallbackAction: { () };
        isUniqueEstimateOption: boolean;
    };
}

export interface ISubaruEligibilityCheckFailureActionMeta extends ISubaruEligibilityCheckActionMeta {
    error: boolean;
}

export interface ISubaruEligibilityCheckSuccessActionPayload {
    expirationDateString: string;
    maxMiles: number;
    price: number;
}

export interface ISubaruEligibilityCheckFailureActionPayload {
    name: string; // e.g. ApiError
    status: number; // e.g. 400
    statusText: string; // e.g. Bad Request
    response: {
        statusCode: number; // e.g. 400
        error: string; // e.g. Bad Request
        message: string; // e.g. GTP service call failed to retrieve GTP trade eligibility result
    };
    message: string; // e.g. 400 - Bad Request
}

export interface ISubaruEligibilitySummaryCheckSuccessAction
    extends FSAWithMeta<
        typeof types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_SUCCESS,
        ISubaruEligibilityCheckSuccessActionPayload,
        ISubaruEligibilityCheckActionMeta
    > {}

export interface ISubaruEligibilitySummaryCheckFailureAction
    extends FSAWithMeta<
        typeof types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_FAILURE,
        ISubaruEligibilityCheckFailureActionPayload,
        ISubaruEligibilityCheckFailureActionMeta
    > {}

export interface ISubaruEligibilityCheckSuccessAction
    extends FSAWithMeta<
        typeof types.SUBARU_ELIGIBILITY_CHECK_SUCCESS,
        ISubaruEligibilityCheckSuccessActionPayload,
        ISubaruEligibilityCheckActionMeta
    > {}

export interface ISubaruEligibilityCheckFailureAction
    extends FSAWithMeta<
        typeof types.SUBARU_ELIGIBILITY_CHECK_FAILURE,
        ISubaruEligibilityCheckFailureActionPayload,
        ISubaruEligibilityCheckActionMeta
    > {}

export const callSubaruEligibilitySummaryCheckEndpoint = (vin: string, mileage: number, dealerId: number) => {
    const isSubaruGTPV2ServiceEnabled = globalConfigFeatureToggleSelectors.isSubaruGTPV2ServiceEnabled();
    const endPoint = isSubaruGTPV2ServiceEnabled ? `gtp-trade/v2/eligible/${vin}?mileage=${mileage}` : `gtp-trade/eligible/${vin}`;

    const endpointService = config.utils.buildApiUrlWithServiceEndpoint({
        service: 'bff',
        endPoint
    });

    const meta = {
        originalParams: {
            vin,
            mileage
        }
    };

    return {
        [RSAA]: {
            endpoint: endpointService,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                dealerId
            },
            types: [
                {
                    type: types.SUBARU_ELIGIBILITY_CHECK_REQUEST,

                    meta: (_action: AnyFSA, _state: any, _res: any) => {
                        return meta;
                    }
                },
                {
                    type: types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_SUCCESS,

                    meta: (_action: AnyFSA, _state: any, _res: any) => {
                        return meta;
                    }
                },
                {
                    type: types.SUBARU_ELIGIBILITY_SUMMARY_CHECK_FAILURE,

                    meta: (_action: AnyFSA, _state: any, _res: any) => {
                        return meta;
                    }
                }
            ]
        }
    };
};

export const callSubaruEligibilityCheckEndpoint = (
    vin: string,
    mileage: number,
    dealerId: number,
    fallbackAction: { () },
    isUniqueEstimateOption: boolean
) => {
    const isSubaruGTPV2ServiceEnabled = globalConfigFeatureToggleSelectors.isSubaruGTPV2ServiceEnabled();
    const endPoint = isSubaruGTPV2ServiceEnabled ? `gtp-trade/v2/eligible/${vin}?mileage=${mileage}` : `gtp-trade/eligible/${vin}`;

    const endpointService = config.utils.buildApiUrlWithServiceEndpoint({
        service: 'bff',
        endPoint
    });

    const meta = {
        originalParams: {
            vin,
            mileage,
            fallbackAction,
            isUniqueEstimateOption
        }
    };

    return {
        [RSAA]: {
            endpoint: endpointService,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                dealerId
            },
            types: [
                {
                    type: types.SUBARU_ELIGIBILITY_CHECK_REQUEST,

                    meta: (_action: AnyFSA, _state: any, _res: any) => {
                        return meta;
                    }
                },
                {
                    type: types.SUBARU_ELIGIBILITY_CHECK_SUCCESS,

                    meta: (_action: AnyFSA, _state: any, _res: any) => {
                        return meta;
                    }
                },
                {
                    type: types.SUBARU_ELIGIBILITY_CHECK_FAILURE,

                    meta: (_action: AnyFSA, _state: any, _res: any) => {
                        return meta;
                    }
                }
            ]
        }
    };
};

export const updatePaymentWithTrade = (): AnyFSA => ({
    type: types.UPDATE_PAYMENT_WITH_TRADE,
    meta: {
        middleware: {
            payment: true
        }
    }
});

export const updateTradeInSource = (source: string) => {
    let payload;
    switch (source) {
        case DECISION_TYPES.TRADE_IN_VALUE:
            payload = 'SD';
            break;
        case DECISION_TYPES.ENTER_OWN_VALUE:
            payload = 'USER';
            break;
        case DECISION_TYPES.ICO:
            payload = 'ICO';
            break;
        case DECISION_TYPES.SUBARU_GTP:
            payload = 'SUBARUGTP';
            break;
    }
    return {
        type: types.UPDATE_TRADE_IN_SOURCE,
        payload
    };
};

export const closeTradeInToastMessage = () => {
    return {
        type: types.TRADE_IN_TOAST_CLOSED
    };
};

export const subaruEligibilityFallback = (source, nextRoute) => {
    return {
        type: types.SUBARU_ELIGIBILITY_FALLBACK,
        payload: { source, nextRoute }
    };
};

export const subaruEligibilitySalesViewNoAppraisalFallback = (source) => {
    return {
        type: types.SUBARU_ELIGIBILITY_FALLBACK_SALESVIEW_NO_APPRAISAL,
        payload: { source }
    };
};

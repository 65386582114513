// externals
import React, { useState } from 'react';
import { Input, Textarea } from '@makemydeal/ui-bricks/dist/cox';
import { formatUtils } from '@makemydeal/dr-common-utils';

// config
import config from '../../../store/mmd/config';

// components
import TradePage from '../../../common/components/TradePage/TradePage';
import { IGetInputFieldInput } from '../../../common/components/VehicleInfo/VehicleInfoUI';
import GTPFlowPageSubtitle from '../GTPFlowPageSubtitle/GTPFlowPageSubtitle';

// types
import { StepperRoute } from '../../../types';
import { ITradeInSubaruGtpFlowError } from '../../../types';

const MANUAL_ENTRY = {
    VALUE: 'value',
    SOURCE: 'source',
    COMMENTS: 'comments'
};
export interface IManualEntryProps {
    value: number;
    source: string;
    comments: string;
    stepperRoutes?: StepperRoute[];
}

export interface IManualEntryUIStateProps extends IManualEntryProps {
    backRoute: string;
    tradeInSubaruGtpFlowError: ITradeInSubaruGtpFlowError;
}

export interface IManualEntryUIDispatchProps {
    next: { (payload: IManualEntryProps) };
    goToRoute: { (route: string): void };
    removeTrade: { (): void };
}

export interface IManualEntryUIProps extends IManualEntryUIStateProps, IManualEntryUIDispatchProps {}

export const ManualEntryUI: React.FC<IManualEntryUIProps> = ({
    value,
    source,
    comments,
    next,
    goToRoute,
    stepperRoutes,
    removeTrade,
    backRoute,
    tradeInSubaruGtpFlowError
}) => {
    const [formData, setFormData] = useState({
        value: value || 0,
        comments: comments || '',
        source: source || ''
    });

    const validateVehicleValue = (value) => {
        const tradeInValue = typeof value === 'string' ? value.replace(/[^0-9]+/g, '') : value;
        return (parseFloat(tradeInValue) || 0) > 0;
    };

    const validateSource = (source) => source !== '';

    const handleChange = (e) => {
        const { currentTarget } = e;
        const key = currentTarget.name;
        let value = currentTarget.value;

        if (key === MANUAL_ENTRY.VALUE) {
            const tradeInValue = value.replace(/[^0-9]+/g, '');
            value = parseFloat(tradeInValue) || 0;
            const source = value ? formData.source : '';

            setFormData({ ...formData, [key]: value, source });
        } else {
            setFormData({ ...formData, [key]: value });
        }
    };

    const handleSubmit = () => {
        next(formData);
    };

    const input: IGetInputFieldInput = {
        inputType: 'tradeInValue',
        maxlength: 7,
        pattern: '[0-9]*',
        inputmode: 'numeric',
        type: 'text'
    };

    const normalizer = {
        tradeInValue: formatUtils.formatCurrencyAmount
    };

    const PageTitle = () => (
        <div id="page-title">
            Did you already receive an estimate? <span className="optional-text">(optional)</span>
        </div>
    );

    const pageSubTitle = () => (
        <GTPFlowPageSubtitle
            pageSubtitle="Please add any additional comments about how you received your vehicle's estimated value."
            suffix="You can still add your Trade-In by providing your vehicle's estimated value."
        />
    );

    const handleBackButtonClick = () => {
        goToRoute(backRoute);
    };

    return (
        <TradePage
            pageClass="improved-manual-entry-page"
            pageTitle={<PageTitle />}
            pageSubTitle={pageSubTitle()}
            headerOnTop
            showCustomTitle
            tradeInSubaruGtpFlowError={tradeInSubaruGtpFlowError}
            stepperRoutes={stepperRoutes}
            footerProps={{
                onActionButtonClick: handleSubmit,
                showBackButton: true,
                buttonText: 'Continue to Summary',
                disableActionButton: formData.value !== 0 && formData.source === '',
                fullWidthLayout: true,
                showSkipButton: true,
                skipButtonText: 'Cancel Trade-In',
                onSkipButtonClick: removeTrade,
                onBackButtonClick: handleBackButtonClick
            }}
            showTradeErrorMessage={false}
            staticAssetsConfig={config.staticImages}
            hideDisclaimerImg={true}
            stepLabelVisible={true}
            isStepperClickable={true}
            goToRoute={goToRoute}
        >
            <div className="container-fluid">
                <div className="row-responsive">
                    <div className="responsive">
                        <div className="form-row form-group">
                            <Input
                                {...input}
                                type="text"
                                name="value"
                                inputId="vehicle-value"
                                label="Estimated Trade-In Value"
                                placeholder="$0.00"
                                value={formData.value.toString()}
                                onChangeFunction={handleChange}
                                validationFunction={validateVehicleValue}
                                data-testid="manualentryform-value"
                                formatValueFunction={normalizer[input.inputType]}
                            />
                        </div>
                    </div>

                    <div className="responsive">
                        <div className="form-row form-group">
                            <Input
                                type="text"
                                name="source"
                                inputId="vehicle-source"
                                label="How Did You Get The Vehicle Value?"
                                placeholder="ex. Another Dealership, Online, etc."
                                value={formData.source}
                                onChangeFunction={handleChange}
                                validationFunction={validateSource}
                                hasError={formData.value !== 0 && formData.source === ''}
                                errorMessage="Please enter an option"
                                data-testid="manualentryform-source"
                                disabled={formData.value === 0}
                                maxlength={100}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Textarea
                            name="comments"
                            inputId="comments"
                            className="ui-bricks"
                            data-testid="manualEntryForm-comments"
                            rows={5}
                            label="Comments"
                            value={formData.comments}
                            onChange={handleChange}
                            placeholder="Leave additional comments."
                        />
                    </div>
                </div>
            </div>
        </TradePage>
    );
};

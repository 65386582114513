// externals
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

// libraries
import { ITradeInState } from '@makemydeal/dr-activities-common';
import { formatUtils } from '@makemydeal/dr-common-utils';
import { validator } from '@makemydeal/dr-platform-shared';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as dealerSelectors from '../../../store/mmd/dealerSelectors';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// consts/enums
import * as tradeInRoutes from '../../../utils/routes';
import { getTradeInSelectors, getUniqueEstimateOption, processStepperRoutes } from '../../../utils/tradeInDecisionLogic';
import { STEPPER_ROUTES } from '../../../utils/constants';
import { isShowroomExperience } from '../../../utils/configUtils';

// components
import VehicleConditionUI from './VehicleConditionUI';

// interfaces/types
import { IVehicleConditionUIDispatchProps } from './VehicleConditionUI';
import { getSponsor } from '../../../store/mmd/widgetSelectors';
import { IRootProps } from '../../../components/RootProps';

const mapStateToProps = (state: any, ownProps: IRootProps) => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const { vehicle, zip } = tradeIn;
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const selectedCondition = vehicle.condition;
    const mileage = formatUtils.formatNumber(tradeInSelectors.getTradeInVehicleMileage(tradeIn));
    const isWebsiteExcellentHidden = dealerSelectors.getTradeInHideExcellentConditionWebsite(state);
    const isListingSiteExcellentHidden = dealerSelectors.getTradeInHideExcellentConditionListingSite(state);
    const conditionMileageZipSubtitle = `${selectedCondition} Condition | ${mileage} Miles | ${tradeIn.zip}`;
    const pageTitle = 'Tell Us About Its Condition';
    const pageSubTitle = `Please select your condition carefully, as it will affect the trade-in value of your ${vehicleName}. This step is required.`;
    const sponsor = getSponsor(state).toString();
    const isListingDealer = validator.isListingSite(sponsor);
    const isWebSiteDealer = !isListingDealer;
    const isExcellentHiddenWebsite = isWebSiteDealer && isWebsiteExcellentHidden;
    const isExcellentHiddenListing = isListingDealer && isListingSiteExcellentHidden;
    const isSalesView = isShowroomExperience(ownProps.config);
    const stepperRoutes = processStepperRoutes(state, STEPPER_ROUTES.detailsKBBCondition, isSalesView);
    const tradeInAssertionSelectors = getTradeInSelectors(state, isSalesView);
    const { hasUniqueOption: hasUniqueEstimateOption } = getUniqueEstimateOption(tradeInAssertionSelectors, isSalesView);

    return {
        zip,
        vehicle,
        vehicleName,
        selectedCondition,
        mileage,
        pageTitle,
        pageSubTitle,
        conditionMileageZipSubtitle,
        isExcellentHiddenWebsite,
        isExcellentHiddenListing,
        stepperRoutes,
        hasUniqueEstimateOption
    };
};

const mapDispatchToProps = (dispatch: any): IVehicleConditionUIDispatchProps => {
    return {
        onValueChange: (condition) => {
            dispatch((_dispatch, getState) => {
                const state = getState();
                const savedVehicle = tradeInSelectors.getTradeInSavedVehicle(state.tradeInComponent);
                const hasConditionChanged = !isEqual(condition, savedVehicle.condition);
                if (hasConditionChanged) {
                    dispatch(tradeInComponentActionCreators.changedTradeIn(hasConditionChanged));
                    dispatch(
                        tradeInComponentActionCreators.trackVehicleConditionAnalytics(condition?.replace(/\s/g, '').toLowerCase())
                    );
                }
            });
            dispatch(tradeInComponentActionCreators.updateTradeInCondition(condition));
        },
        next: () => {
            dispatch((_dispatch) => {
                dispatch(tradeInComponentActionCreators.tradeInConditionApplied());
                dispatch(
                    tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE)
                );
            });
        },
        goToRoute: (route: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(route));
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        },
        removeTrade: () => {
            dispatch(tradeInComponentActionCreators.removeTradeIn());
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN));
        },
        goBack: (hasUniqueEstimateOption: boolean) => {
            dispatch(
                tradeInComponentActionCreators.updateTradeInCurrentLocation(
                    hasUniqueEstimateOption ? tradeInRoutes.TRADE_VEHICLE_INFO : tradeInRoutes.TRADE_IN_DECISION
                )
            );
        }
    };
};

const VehicleCondition = connect(mapStateToProps, mapDispatchToProps)(VehicleConditionUI);

export default VehicleCondition;

export const Actions = {
    INIT_SUCCESS: 'INIT_SUCCESS',
    OFFER_SUBMISSION_SUCCESS: 'OFFER_SUBMISSION_SUCCESS',
    OFFER_SUBMISSION_FAILURE: 'OFFER_SUBMISSION_FAILURE',
    PAYMENT_FAILURE: 'PAYMENT_FAILURE',
    PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
    UPDATE_PAYMENT_WITH_TRADE: 'UPDATE_PAYMENT_WITH_TRADE',
    SAVE_COUNTEROFFER: 'SAVE_COUNTEROFFER',
    SUMMARY_START_TRADE_IN_CLICKED: 'SUMMARY_START_TRADE_IN_CLICKED',
    SUMMARY_EDIT_TRADE_IN_CLICKED: 'SUMMARY_EDIT_TRADE_IN_CLICKED',
    SUMMARY_REMOVE_TRADE_IN_CLICKED: 'SUMMARY_REMOVE_TRADE_IN_CLICKED',
    LEAD_FORM_SUBMISSION_SUCCESS: 'LEAD_FORM_SUBMISSION_SUCCESS',
    ROUTE_NEXT: 'ROUTE_NEXT',
    SUBMIT_DEAL_SUMMARY: 'SUBMIT_DEAL_SUMMARY',
    SUBMIT_OFFER: 'SUBMIT_OFFER',
    ROUTE_SUMMARY_LEAD_FORM: 'ROUTE_SUMMARY_LEAD_FORM',
    ROUTE_TEST_DRIVE: 'ROUTE_TEST_DRIVE',
    DR_BACK_TO_DASHBOARD_CLICKED: 'DR_BACK_TO_DASHBOARD_CLICKED',
    SHOW_ZIP_CODE_MODAL: 'SHOW_ZIP_CODE_MODAL',

    TRADEIN_ACTION: 'TRADEIN_ACTION',

    TRADE_IN_MAKES_REQUEST: 'TRADE_IN_MAKES_REQUEST',
    TRADE_IN_MAKES_SUCCESS: 'TRADE_IN_MAKES_SUCCESS',
    TRADE_IN_MAKES_FAILURE: 'TRADE_IN_MAKES_FAILURE',

    TRADE_IN_OPENED: 'TRADE_IN_OPENED',

    TRADE_IN_MODELS_REQUEST: 'TRADE_IN_MODELS_REQUEST',
    TRADE_IN_MODELS_SUCCESS: 'TRADE_IN_MODELS_SUCCESS',
    TRADE_IN_MODELS_FAILURE: 'TRADE_IN_MODELS_FAILURE',

    TRADE_IN_TRIMS_REQUEST: 'TRADE_IN_TRIMS_REQUEST',
    TRADE_IN_TRIMS_SUCCESS: 'TRADE_IN_TRIMS_SUCCESS',
    TRADE_IN_TRIMS_FAILURE: 'TRADE_IN_TRIMS_FAILURE',

    TRADE_IN_OPTIONS_REQUEST: 'TRADE_IN_OPTIONS_REQUEST',
    TRADE_IN_OPTIONS_SUCCESS: 'TRADE_IN_OPTIONS_SUCCESS',
    TRADE_IN_OPTIONS_FAILURE: 'TRADE_IN_OPTIONS_FAILURE',
    TRADE_IN_VALUE_PAGE_CLICKED: 'TRADE_IN_VALUE_PAGE_CLICKED',

    TRADE_IN_YEARS_REQUEST: 'TRADE_IN_YEARS_REQUEST',
    TRADE_IN_YEARS_SUCCESS: 'TRADE_IN_YEARS_SUCCESS',
    TRADE_IN_YEARS_FAILURE: 'TRADE_IN_YEARS_FAILURE',

    TRADE_IN_ESTIMATION_REQUEST: 'TRADE_IN_ESTIMATION_REQUEST',
    TRADE_IN_ESTIMATION_SUCCESS: 'TRADE_IN_ESTIMATION_SUCCESS',
    TRADE_IN_ESTIMATION_FAILURE: 'TRADE_IN_ESTIMATION_FAILURE',
    TRADE_IN_ESTIMATE_DISPLAYED: 'TRADE_IN_ESTIMATE_DISPLAYED',

    TRADE_IN_VALIDATE_OPTIONS_REQUEST: 'TRADE_IN_VALIDATE_OPTIONS_REQUEST',
    TRADE_IN_VALIDATE_OPTIONS_SUCCESS: 'TRADE_IN_VALIDATE_OPTIONS_SUCCESS',
    TRADE_IN_VALIDATE_OPTIONS_FAILURE: 'TRADE_IN_VALIDATE_OPTIONS_FAILURE',

    TRADE_IN_SEND_REQUEST: 'TRADE_IN_SEND_REQUEST',
    TRADE_IN_SEND_SUCCESS: 'TRADE_IN_SEND_SUCCESS',
    TRADE_IN_SEND_FAILURE: 'TRADE_IN_SEND_FAILURE',

    TRADE_IN_REMOVE_REQUEST: 'TRADE_IN_REMOVE_REQUEST',
    TRADE_IN_REMOVE_SUCCESS: 'TRADE_IN_REMOVE_SUCCESS',
    TRADE_IN_REMOVE_FAILURE: 'TRADE_IN_REMOVE_FAILURE',

    TRADE_IN_VEHICLES_REQUEST: 'TRADE_IN_VEHICLES_REQUEST',
    TRADE_IN_VEHICLES_SUCCESS: 'TRADE_IN_VEHICLES_SUCCESS',
    TRADE_IN_VEHICLES_FAILURE: 'TRADE_IN_VEHICLES_FAILURE',

    LOOKUP_ICO_TRADE_REQUEST: 'LOOKUP_ICO_TRADE_REQUEST',
    LOOKUP_ICO_TRADE_SUCCESS: 'LOOKUP_ICO_TRADE_SUCCESS',
    LOOKUP_ICO_TRADE_FAILURE: 'LOOKUP_ICO_TRADE_FAILURE',

    APPLY_TRADE_IN_SELECTION: 'APPLY_TRADE_IN_SELECTION',
    APPLY_TRADE_IN_EQUIPMENT_OPTIONS: 'APPLY_TRADE_IN_EQUIPMENT_OPTIONS',
    UPDATE_TRADE_IN_EQUIPMENT_OPTION: 'UPDATE_TRADE_IN_EQUIPMENT_OPTION',
    TRADE_IN_LOAN_STATUS_APPLIED: 'TRADE_IN_LOAN_STATUS_APPLIED',
    TRADE_IN_CONDITION_APPLIED: 'TRADE_IN_CONDITION_APPLIED',
    TRADE_IN_SKIPPED: 'TRADE_IN_SKIPPED',
    TRADE_IN_COMPLETED_CONTINUE: 'TRADE_IN_COMPLETED_CONTINUE',
    TRADE_IN_FINANCE_TYPE_CHANGED: 'TRADE_IN_FINANCE_TYPE_CHANGED',

    ROUTE_TRADE_IN_SELECTION: 'ROUTE_TRADE_IN_SELECTION',
    ROUTE_TRADE_IN_VALUATION: 'ROUTE_TRADE_IN_VALUATION',
    ROUTE_TRADE_IN_EQUIPMENT_OPTIONS: 'ROUTE_TRADE_IN_EQUIPMENT_OPTIONS',
    ROUTE_TRADE_SUMMARY: 'ROUTE_TRADE_SUMMARY',

    UPDATE_TRADE_IN_AMOUNT_OWED: 'UPDATE_TRADE_IN_AMOUNT_OWED',
    UPDATE_TRADE_IN_VALUE: 'UPDATE_TRADE_IN_VALUE',
    ICO_TRADE_IN_NO_VALUE: 'ICO_TRADE_IN_NO_VALUE',
    ROUTE_EXIT_SHELL: 'ROUTE_EXIT_SHELL',
    ROUTE_TRADE_IN_ICO_VALUATION: 'ROUTE_TRADE_IN_ICO_VALUATION',
    ICO_TRADE_IN_SUCCESS: 'ICO_TRADE_IN_SUCCESS',
    ICO_TRADE_IN_PENDING: 'ICO_TRADE_IN_PENDING',
    ICO_TRADE_IN_COMPLETED: 'ICO_TRADE_IN_COMPLETED',
    ICO_PASS_BACK_FAILURE: 'ICO_PASS_BACK_FAILURE',

    TRADE_IN_CLEAR_VEHICLE_YMMT: 'TRADE_IN_CLEAR_VEHICLE_YMMT',

    UPDATE_TRADE_IN_VEHICLE_INFO: 'UPDATE_TRADE_IN_VEHICLE_INFO',
    HANDLE_TRADE_IN_SET_VEHICLE_NAVIGATION: 'HANDLE_TRADE_IN_SET_VEHICLE_NAVIGATION',
    UPDATE_TRADE_IN_PAYMENT_PREFERENCE: 'UPDATE_TRADE_IN_PAYMENT_PREFERENCE',
    UPDATE_TRADE_IN_ZIP: 'UPDATE_TRADE_IN_ZIP',
    UPDATE_TRADE_IN_CONDITION: 'UPDATE_TRADE_IN_CONDITION',
    UPDATE_TRADE_IN_BALANCE_OWED: 'UPDATE_TRADE_IN_BALANCE_OWED',
    UPDATE_TRADE_IN_CURRENT_LOCATION: 'UPDATE_TRADE_IN_CURRENT_LOCATION',
    GO_TO_TRADE_IN_FLOW: 'GO_TO_TRADE_IN_FLOW',
    COMPLETE_TRADE_IN: 'COMPLETE_TRADE_IN',
    RESET_FIELDS_NEW_VIN: 'RESET_FIELDS_NEW_VIN',
    RESET_IN_TRADE_IN_SUBARU_GTP_FLOW: 'RESET_IN_TRADE_IN_SUBARU_GTP_FLOW',
    RESET_TRADE_IN: 'RESET_TRADE_IN',
    RESET_TRADE_IN_SUBARU_GTP_FLOW_ERROR: 'RESET_TRADE_IN_SUBARU_GTP_FLOW_ERROR',
    REMOVE_TRADE_IN: 'REMOVE_TRADE_IN',
    SET_IN_TRADE_IN_SUBARU_GTP_FLOW: 'SET_IN_TRADE_IN_SUBARU_GTP_FLOW',
    SET_TRADE_IN_IS_SUBARU_GTP_ELIGIBLE: 'SET_TRADE_IN_IS_SUBARU_GTP_ELIGIBLE',
    SET_TRADE_IN_SUBARU_GTP_FLOW_ERROR: 'SET_TRADE_IN_SUBARU_GTP_FLOW_ERROR',

    CANCEL_TRADE_IN: 'CANCEL_TRADE_IN',

    ROUTE_TRADE_IN: 'ROUTE_TRADE_IN',
    SKIP_TRADE_IN: 'SKIP_TRADE_IN',
    CHOOSE_TRADE_IN: 'CHOOSE_TRADE_IN',
    UPDATE_TRADE_IN_AMOUNT_FINANCED: 'UPDATE_TRADE_IN_AMOUNT_FINANCED',
    UPDATE_TRADE_IN: 'UPDATE_TRADE_IN',
    UPDATE_TRADE_IN_VALUATION: 'UPDATE_TRADE_IN_VALUATION',
    UPDATE_RESET_VALUATION_FLAG: 'UPDATE_RESET_VALUATION_FLAG',
    UPDATE_TRADE_IN_PAYMENT_STATUS: 'UPDATE_TRADE_IN_PAYMENT_STATUS',
    RESET_TRADE_IN_VALUATION: 'RESET_TRADE_IN_VALUATION',

    TRADE_IN_CHANGED: 'TRADE_IN_CHANGED',
    TRADE_IN_LEADFORM_SUBMITTED: 'TRADE_IN_LEADFORM_SUBMITTED',

    UPDATE_ICO_TRADE_COMPLETED: 'UPDATE_ICO_TRADE_COMPLETED',
    TRADE_IN_ESTIMATE_RECEIVED: 'TRADE_IN_ESTIMATE_RECEIVED',

    DR_TRADEIN_ADDED: 'DR_TRADEIN_ADDED',
    DR_TRADEIN_UPDATED: 'DR_TRADEIN_UPDATED',
    DR_TRADEIN_REMOVED: 'DR_TRADEIN_REMOVED',

    TRADE_IN_PERFORM_UPDATE: 'TRADE_IN_PERFORM_UPDATE',

    ROUTE_DEAL_SUMMARY: 'ROUTE_DEAL_SUMMARY',
    ROUTE_MY_TERMS_DEAL_SUMMARY: 'ROUTE_MY_TERMS_DEAL_SUMMARY',
    TRADE_IN_LEAD_SUBMITTED: 'TRADE_IN_LEAD_SUBMITTED',

    UPDATED_TRADE_IN: 'UPDATED_TRADE_IN',

    SYNC_REMEMBER_ME_TRADE_PREFERENCE: 'SYNC_REMEMBER_ME_TRADE_PREFERENCE',

    SHOPPER_IN_MANUAL_ENTRY: 'SHOPPER_IN_MANUAL_ENTRY',
    TRADE_IN_DECISION_TYPE_CHANGED: 'TRADE_IN_DECISION_TYPE_CHANGED',
    TRADE_IN_DECISION_CLICKED: 'TRADE_IN_DECISION_CLICKED',
    TRADE_IN_DECISION_PAGE_DISPLAYED: 'TRADE_IN_DECISION_PAGE_DISPLAYED',
    TRADE_IN_ICO_DISPLAYED: 'TRADE_IN_ICO_DISPLAYED',
    TRADE_IN_ADD_ICO_VALUE: 'TRADE_IN_ADD_ICO_VALUE',
    TRADE_IN_ICO_EXIT_CLICKED: 'TRADE_IN_ICO_EXIT_CLICKED',
    TRADE_IN_ICO_BALANCE_CARD_DISPLAYED: 'TRADE_IN_ICO_BALANCE_CARD_DISPLAYED',
    TRADE_IN_ICO_ACTIVE_LOAN: 'TRADE_IN_ICO_ACTIVE_LOAN',
    TRADE_IN_ICO_BALANCE_CHANGED: 'TRADE_IN_ICO_BALANCE_CHANGED',
    TRADE_IN_ICO_BALANCE_CLICKED: 'TRADE_IN_ICO_BALANCE_CLICKED',
    TRADE_IN_EXTERNAL_ICO_COMPLETED: 'TRADE_IN_EXTERNAL_ICO_COMPLETED',
    TRADE_IN_VEHICLE_YEAR_CHANGED: 'TRADE_IN_VEHICLE_YEAR_CHANGED',
    TRADE_IN_VEHICLE_MAKE_CHANGED: 'TRADE_IN_VEHICLE_MAKE_CHANGED',
    TRADE_IN_VEHICLE_MODEL_CHANGED: 'TRADE_IN_VEHICLE_MODEL_CHANGED',
    TRADE_IN_VEHICLE_TRIM_CHANGED: 'TRADE_IN_VEHICLE_TRIM_CHANGED',
    TRADE_IN_VEHICLE_OWNERSHIP_CHANGED: 'TRADE_IN_VEHICLE_OWNERSHIP_CHANGED',
    TRADE_IN_VEHICLE_MILEAGE_CHANGED: 'TRADE_IN_VEHICLE_MILEAGE_CHANGED',
    TRADE_IN_VEHICLE_ZIPCODE_CHANGED: 'TRADE_IN_VEHICLE_ZIPCODE_CHANGED',
    TRADE_IN_FINANCE_BALANCE_CHANGED: 'TRADE_IN_FINANCE_BALANCE_CHANGED',
    TRADE_IN_CONDITION_SELECTED: 'TRADE_IN_VEHICLE_CONDITION_SELECTED',
    TRADE_IN_EQUIPMENT_DISPLAY: 'TRADE_IN_EQUIPMENT_DISPLAY',
    TRADE_IN_SEARCHTYPE_CLICKED: 'TRADE_IN_SEARCHTYPE_CLICKED',
    TRADE_IN_SEARCH_CLICKED: 'TRADE_IN_SEARCH_CLICKED',
    REDESIGN_TRADE_IN_FINANCE_TYPE_CHANGED: 'REDESIGN_TRADE_IN_FINANCE_TYPE_CHANGED',
    TRADE_IN_SAVE_OR_UPDATE: 'TRADE_IN_SAVE_OR_UPDATE',

    COMPLETION_PAGE_EDIT_CLICKED: 'COMPLETION_PAGE_EDIT_CLICKED',
    COMPLETION_TERMS_DETAILS_CLICKED: 'COMPLETION_TERMS_DETAILS_CLICKED',
    TEST_DRIVE_REQUESTED_FROM_TRADE_IN: 'TEST_DRIVE_REQUESTED_FROM_TRADE_IN',

    NAVIGATION_CARD_SELECTED: 'NAVIGATION_CARD_SELECTED',
    ROUTE_MENU_PRODUCTS: 'ROUTE_MENU_PRODUCTS',
    REMOVE_TRADE_IN_AND_CLOSE_MODAL: 'REMOVE_TRADE_IN_AND_CLOSE_MODAL',
    TRADE_IN_ANALYTICS: 'TRADE_IN_ANALYTICS',
    APPLY_AMOUNT_OWED_AND_UPDATE_VALUE: 'APPLY_AMOUNT_OWED_AND_UPDATE_VALUE',
    UPDATE_VEHICLE_AND_REDIRECT_TO_TRADE_IN: 'UPDATE_VEHICLE_AND_REDIRECT_TO_TRADE_IN',
    CLOSE_LEAD_FORM_MODAL: 'CLOSE_LEAD_FORM_MODAL',
    UPDATE_TRADE_VALUE_SOURCE: 'UPDATE_TRADE_VALUE_SOURCE',
    UPDATE_TRADE_IN_SOURCE: 'UPDATE_TRADE_IN_SOURCE',
    UPDATE_TRADE_FLOW_PICKED: 'UPDATE_TRADE_FLOW_PICKED',
    UPDATE_TRADE_VEHICLE_COLOR: 'UPDATE_TRADE_VEHICLE_COLOR',
    HANDLE_TRADE_IN_SEARCH_NEXT: 'HANDLE_TRADE_IN_SEARCH_NEXT',
    HANDLE_TRADE_IN_SEARCH_SET_VEHICLE: 'HANDLE_TRADE_IN_SEARCH_SET_VEHICLE',

    SUBARU_ELIGIBILITY_SUMMARY_CLICK: 'SUBARU_ELIGIBILITY_SUMMARY_CLICK',
    SUBARU_ELIGIBILITY_CLICK: 'SUBARU_ELIGIBILITY_CLICK',
    SUBARU_ELIGIBILITY_CHECK_SUCCESS: 'SUBARU_ELIGIBILITY_CHECK_SUCCESS',
    SUBARU_ELIGIBILITY_CHECK_FAILURE: 'SUBARU_ELIGIBILITY_CHECK_FAILURE',
    SUBARU_ELIGIBILITY_FALLBACK: 'SUBARU_ELIGIBILITY_FALLBACK',
    SUBARU_ELIGIBILITY_FALLBACK_SALESVIEW_NO_APPRAISAL: 'SUBARU_ELIGIBILITY_FALLBACK_SALESVIEW_NO_APPRAISAL',
    SUBARU_ELIGIBILITY_NO_VIN_CLICK: 'SUBARU_ELIGIBILITY_NO_VIN_CLICK',
    SUBARU_ELIGIBILITY_CHECK_REQUEST: 'SUBARU_ELIGIBILITY_CHECK_REQUEST',
    SUBARU_ELIGIBILITY_SUMMARY_CHECK_SUCCESS: 'SUBARU_ELIGIBILITY_SUMMARY_CHECK_SUCCESS',
    SUBARU_ELIGIBILITY_SUMMARY_CHECK_FAILURE: 'SUBARU_ELIGIBILITY_SUMMARY_CHECK_FAILURE',
    SUBARU_GTP_RULES_CLICKED: 'SUBARU_GTP_RULES_CLICKED',

    OEM_TRADE_IN_SEND_FAILURE: 'OEM_TRADE_IN_SEND_FAILURE',
    OEM_TRADE_IN_SEND_REQUEST: 'OEM_TRADE_IN_SEND_REQUEST',
    OEM_TRADE_IN_SEND_SUCCESS: 'OEM_TRADE_IN_SEND_SUCCESS',
    OEM_TRADE_IN_UPDATE: 'OEM_TRADE_IN_UPDATE',

    TRADE_IN_TOAST_CLOSED: 'TRADE_IN_TOAST_CLOSED',
    TRADE_IN_DEAL_CHANGE_MESSAGE_BOX_CLOSED: 'TRADE_IN_DEAL_CHANGE_MESSAGE_BOX_CLOSED',

    UPDATE_TRADE_IN_ENTRY_POINT: 'UPDATE_TRADE_IN_ENTRY_POINT',
    SAVE_OFFER: 'SAVE_OFFER'
};

export const analyticsActions = {
    // GTP Subaru Actions
    DR_GTRADE_ELIGIBILITY_SCREEN_OPENED: 'DR_GTRADE_ELIGIBILITY_SCREEN_OPENED',
    DR_GTRADE_TRADEIN_VIN_CHANGED: 'DR_GTRADE_TRADEIN_VIN_CHANGED',
    DR_GTRADE_TRADEIN_VEHICLE_MILEAGE_CHANGED: 'DR_GTRADE_TRADEIN_VEHICLE_MILEAGE_CHANGED',
    DR_GTRADE_TRADEIN_FINANCE_TYPE_CHANGED: 'DR_GTRADE_TRADEIN_FINANCE_TYPE_CHANGED',
    DR_GTRADE_TRADEIN_FINANCE_BALANCE_CHANGED: 'DR_GTRADE_TRADEIN_FINANCE_BALANCE_CHANGED',
    DR_GTRADE_TRADEIN_ELIGIBILITY_CLOSED: 'DR_GTRADE_TRADEIN_ELIGIBILITY_CLOSED',
    DR_GTRADE_TRADEIN_VIN_UNAVAILABLE: 'DR_GTRADE_TRADEIN_VIN_UNAVAILABLE',
    DR_GTRADE_RETURN_TO_ELIGIBILITY_SCREEN: 'DR_GTRADE_RETURN_TO_ELIGIBILITY_SCREEN',
    LEAD_FORM_SUBMIT_CLICKED: 'LEAD_FORM_SUBMIT_CLICKED'
};

export const devHelperActions = {
    ADD_MOCK_VALID_TRADE: 'devHelper/ADD_MOCK_VALID_TRADE',
    ADD_MOCK_PAYMENT_FAILURE_TRADE: 'devHelper/ADD_MOCK_PAYMENT_FAILURE_TRADE',
    ADD_NEGATIVE_EQUITY_TRADE: 'devHelper/ADD_NEGATIVE_EQUITY_TRADE'
};

// externals
import * as React from 'react';

// components
import TradeSummary from './TradeSummary/TradeSummary';
import ManualSummary from './ManualSummary/ManualSummary';
import SubaruGTPSummary from './SubaruGTPSummary/SubaruGTPSummary';
import globalConfigFeatureToggles from '../../../store/globalConfigFeatureToggles';

export interface ITradeSummaryUIStateProps {
    source: string;
}

export interface ITradeSummaryUIProps extends ITradeSummaryUIStateProps {}

class TradeSummaryUI extends React.Component<ITradeSummaryUIProps> {
    constructor(public props) {
        super(props);
    }

    render() {
        const { source } = this.props;

        switch (source) {
            case 'ICO':
            case 'SD':
                return <TradeSummary {...this.props} />;

            case 'SUBARUGTP':
                return <SubaruGTPSummary {...this.props} />;

            default:
                return <ManualSummary {...this.props} />;
        }
    }
}

export default TradeSummaryUI;

// externals
import isEmpty_ from 'lodash.isempty';

// libraries
import { validator, formatUtils, urlSlicer, isValidNumber } from '@makemydeal/dr-common-utils';
import {
    BaseSelectors,
    IActivitySnapshotState,
    ILookups,
    ILookupsMake,
    ILookupsModel,
    ILookupsTrim,
    ILookupsYear,
    ITradeInState,
    ITradeInVehicle,
    IVehicleOption,
    NavigationItemStatus,
    TIV_SOURCE as source
} from '@makemydeal/dr-activities-common';

// selectors
import * as offerSelectors from './mmd/offerSelectors';

// interfaces/types
import { IAdditionalSelectors } from '../types/IAdditionalSelectors';
import {
    ITradeInComponentState,
    ITradeInMake,
    ITradeInModel,
    ITradeInSaved,
    ITradeInSubaruGtpFlowError,
    ITradeInTrim,
    ITradeInPayoffQuote
} from '../types/tradeInTypes';

// utils
import * as validation from '../utils/validation';
import * as featureToggleSelectors from './featureToggles';
import * as globalConfigFeatureToggleSelectors from './globalConfigFeatureToggles';
import { TRADE_IN_DECISION } from '../utils/routes';

// consts/enums
import { Actions } from './actions';
import * as TradeInRoutes from '../utils/routes';

type IStateTree = any;

const isEmpty = isEmpty_;

function getTradeInLookupsObj(tradeIn: ITradeInState): Partial<ILookups> {
    return tradeIn.lookups || {};
}

function getTradeInYearsObj(lookups: ILookups): Partial<ILookupsYear> {
    return lookups.years || {};
}

function getTradeInMakesObj(lookups: ILookups): Partial<ILookupsMake> {
    return lookups.makes || {};
}

function getTradeInModelsObj(lookups: ILookups): Partial<ILookupsModel> {
    return lookups.models || {};
}

function getTradeInTrimsObj(lookups: ILookups): Partial<ILookupsTrim> {
    return lookups.trims || {};
}

function isPayoffQuoteAvailable(payoffQuote: ITradeInPayoffQuote): boolean {
    return Object.values(payoffQuote ?? {}).some((value) => value);
}

export class Selectors extends BaseSelectors {
    constructor(
        public sliceName: string,
        public additionalSelectors: IAdditionalSelectors
    ) {
        super(sliceName);
    }

    getState = (state: any): ITradeInState => {
        return super.getState(state);
    };

    getId = (tradeIn: IActivitySnapshotState): string | undefined => {
        return tradeIn.id;
    };

    icoTradeFlow = (state: IStateTree) => {
        if (featureToggleSelectors.fakeDealerIcoOff(state)) {
            return false;
        }
        return (
            state.dealer.tradeInValuationProvider === 'kelley_blue_book_ico' && state.dealer.tradeInValuationICOProviderUrl !== ''
        );
    };

    getICODealerId = (tradeInValuationICOProviderUrl: string): string => {
        let icoDealerId = '';

        if (tradeInValuationICOProviderUrl) {
            const parsedParams = urlSlicer.parseParams(tradeInValuationICOProviderUrl);
            icoDealerId = parsedParams['Dealer_ID'];
        }

        return icoDealerId;
    };

    private isP202RedesignEnabled = (state) => {
        const selectorPropIsP202RedesignEnabled = this.additionalSelectors.configSelectors.isP202RedesignEnabled(state);
        const isP202RedesignEnabled =
            selectorPropIsP202RedesignEnabled || globalConfigFeatureToggleSelectors.isP202RedesignEnabled();
        return isP202RedesignEnabled;
    };

    getInstantCashOfferProviderUrl = (state: IStateTree): string => {
        let instantCashOfferProviderUrl: string = state.dealer.tradeInValuationICOProviderUrl;

        if (this.isP202RedesignEnabled(state)) {
            const icoDealerId = this.getICODealerId(instantCashOfferProviderUrl);
            const dealerIdQuery = icoDealerId ? `Dealer_ID=${icoDealerId}&` : '';
            instantCashOfferProviderUrl = `https://www.kbb.com/instant-cash-offer/?${dealerIdQuery}OfferCode=D#/`;
        }

        return instantCashOfferProviderUrl;
    };

    getTradeDecisionPage = (state: IStateTree): boolean => {
        return state.dealer.enableTradeDecisionPage;
    };

    getManualTradeDecision = (state: IStateTree): boolean => {
        return state.dealer.enableManualTradeDecision;
    };

    getTradeInValueIcoStatus = (state: IStateTree) => {
        const { tradeIn } = state;
        return (tradeIn && tradeIn.tivIcoStatus) || null;
    };

    hasTradeIn = (state: IStateTree): boolean => {
        const { tradeInComponent } = state;
        const { vehicle } = tradeInComponent;
        if (vehicle) {
            const { mileage, year } = vehicle;
            return !!mileage && !!year;
        }
        return false;
    };

    isAmsiDealer = (state: IStateTree): boolean => {
        const providerUrl = this.getInstantCashOfferProviderUrl(state);
        const matchAmsiIcoUrl = providerUrl ? providerUrl.match(/.*kbb-ico-cox./gi) : undefined;
        return (matchAmsiIcoUrl && matchAmsiIcoUrl.length > 0) || false;
    };

    isTradeInIcoEnabled = (state: IStateTree): boolean => {
        const icoEnabled = featureToggleSelectors.isTradeInIcoEnabledOnSP(state);
        const amsiIcoEnabled = featureToggleSelectors.isTradeInIcoEnabledForAmsi(state);
        if (this.isAmsiDealer(state)) {
            if (amsiIcoEnabled && icoEnabled) {
                return true;
            } else {
                return false;
            }
        } else {
            return icoEnabled || false;
        }
    };

    isICO = (state: IStateTree) => {
        return state.tradeInComponent.tivSource === 'ICO';
    };

    isIcoTradeInFlow = (state: IStateTree) => {
        // tslint:disable-next-line:react-hooks-nesting
        return this.isTradeInIcoEnabled(state) && this.icoTradeFlow(state);
    };

    isIcoTradeInNotSiteDriverFlow = (state: IStateTree) => {
        const tradeInComponent = this.getTradeInComponent(state);
        return this.isIcoTradeInFlow(state) && this.getTradeInValueSource(tradeInComponent) !== 'SD';
    };

    getTradeInIsOwnershipEnabled = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.trim.id.length ? true : false;
    };

    getTradeInIsMileageEnabled = (tradeIn: ITradeInState, state: IStateTree) => {
        if (this.isP202RedesignEnabled(state)) {
            const vehicle = this.getTradeInVehicleObj(tradeIn);
            return vehicle.trim?.id?.length ? true : false;
        }

        return tradeIn.ownership ? true : false;
    };

    getTradeInIsMileageEnabledFromState = (state: IStateTree) => {
        // NOTE: when getTradeInComponentRaw was implemented it was part of a refactor to remove the dependence on the global config
        //   object for determining feature toggle state so that it would work the same way in CMD as it does in Shopper Platform.
        //   It appears that there may be a bug in the old code (it didn't handle undefined trade-in in state) but the logic was
        //   preserved as closely as possible to avoid introducing new issues.  If this code fails when `return tradeIn.ownership`
        //   is called below in some scenario because tradeIn is undefined, then switch this to use this.getTradeInComponent(state).
        const tradeIn = this.getTradeInComponentRaw(state);
        if (this.isP202RedesignEnabled(state)) {
            const vehicle = this.getTradeInVehicleObj(tradeIn);
            return vehicle.trim?.id?.length ? true : false;
        }

        return tradeIn.ownership ? true : false;
    };

    getTradeInIsZipCodeEnabled = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.mileage ? true : false;
    };

    getTradeInAreOptionalFieldsEnabled = (tradeIn: ITradeInState) => {
        return tradeIn.zip ? true : false;
    };

    getTradeInLookupTrims = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn);
        const trims = getTradeInTrimsObj(lookups);
        return trims.options || [];
    };

    getTradeInIsTrimEnabled = (tradeIn: ITradeInState) => {
        const trims = this.getTradeInLookupTrims(tradeIn);
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return !!(trims.length > 0 && vehicle.model?.id);
    };

    getTradeInIsTrimLoading = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn) as ILookups;
        const trims = getTradeInTrimsObj(lookups) as ILookupsTrim;
        return trims.isLoading || false;
    };

    getTradeInIsTrimSelected = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.trim && vehicle.trim.name !== '';
    };

    getTradeInVehicleOptions(tradeIn: ITradeInState): IVehicleOption {
        return tradeIn.vehicleOptions || {};
    }

    getTradeInAmountOwed = (tradeIn: ITradeInState) => {
        return (tradeIn && tradeIn.amountOwed) || 0;
    };

    getTradeInValueOfferDate = (tradeIn: ITradeInState) => {
        return tradeIn.offerDate || '';
    };

    getTradeInValueOfferId = (tradeIn: ITradeInState) => {
        return tradeIn.offerId || '';
    };

    getTradeInValueSource = (tradeIn) => {
        return (tradeIn && tradeIn.tivSource) || null;
    };

    getTradeInEstimate = (tradeIn) => {
        return tradeIn.estimate || [];
    };

    getTradeInEstimateSelectedValue = (tradeIn: ITradeInState) => {
        if (!tradeIn) {
            return 0;
        }
        if (tradeIn.tivSource === source.SITEDRIVER) {
            return tradeIn.value;
        }
        const estimate = this.getTradeInEstimate(tradeIn);
        const selected = estimate.find((item) => item['selected']);
        return selected ? selected['value'] : 0;
    };

    getTradeInValue = (tradeIn: ITradeInState, shouldUseValue?: boolean) => {
        if (tradeIn && (tradeIn.value !== 0 || (shouldUseValue && isValidNumber(tradeIn.value)))) {
            return tradeIn.value;
        }

        return this.getTradeInEstimateSelectedValue(tradeIn);
    };

    getTradeInEquity = (tradeIn: ITradeInState) => {
        const tradeInValue = this.getTradeInValue(tradeIn);
        const amountOwed = this.getTradeInAmountOwed(tradeIn);
        const equity = tradeInValue - amountOwed;
        return equity;
    };

    getAmountAppliedToFinancing = (tradeIn: ITradeInState) => {
        return tradeIn.shouldIncludeTradeIn || false;
    };

    getTradeInZip(tradeIn: ITradeInState): string {
        return tradeIn.zip;
    }

    getTradeInVehicleObj(tradeIn: ITradeInState): Partial<ITradeInVehicle> {
        return tradeIn.vehicle || {};
    }

    getTradeInVehicleCondition = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.condition || '';
    };

    getTradeInVehicleVin = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.vin || '';
    };

    getTradeInVehicleYear = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        const { year } = vehicle;
        switch (typeof year) {
            // TODO: It is possible year is type number at runtime, though is declared with type string
            case 'number': {
                return String(year);
            }
            case 'string': {
                return year;
            }
            default: {
                return '';
            }
        }
    };

    getTradeInVehicleMake = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.make || ({} as ITradeInMake);
    };

    getTradeInVehicleMakeName = (tradeIn: ITradeInState) => {
        const make = this.getTradeInVehicleMake(tradeIn);
        return make.name || '';
    };

    getTradeInVehicleMakeId = (tradeIn: ITradeInState) => {
        const make = this.getTradeInVehicleMake(tradeIn);
        return make.id?.toString() || '';
    };

    getTradeInVehicleModel = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.model || ({} as ITradeInModel);
    };

    getTradeInVehicleModelName = (tradeIn: ITradeInState) => {
        const model = this.getTradeInVehicleModel(tradeIn);
        return model.name || '';
    };

    getTradeInVehicleModelId = (tradeIn: ITradeInState) => {
        const model = this.getTradeInVehicleModel(tradeIn);
        return model.id?.toString() || '';
    };

    getTradeInVehicleTrim = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.trim || ({} as ITradeInTrim);
    };

    getTradeInVehicleTrimName = (tradeIn: ITradeInState) => {
        const trim = this.getTradeInVehicleTrim(tradeIn);
        return trim.name || '';
    };

    getTradeInVehicleTrimId = (tradeIn: ITradeInState) => {
        const trim = this.getTradeInVehicleTrim(tradeIn);
        return trim.id || '';
    };

    getTradeInTrimVehicleId = (tradeIn: ITradeInState) => {
        const trim = this.getTradeInVehicleTrim(tradeIn);
        return trim.vehicleId || '';
    };

    getTradeInVehicleColor = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.color || '';
    };

    getTradeInVehicleInteriorColor = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.interiorColor || '';
    };

    getTradeInVehicleMileage = (tradeIn: ITradeInState): number => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.mileage || 1;
    };

    getTradeInEstimateFairValue = (tradeIn: ITradeInState) => {
        if (tradeIn && tradeIn.estimate.length === 0) {
            return tradeIn.value;
        }

        const estimate = this.getTradeInEstimate(tradeIn);
        const selected = estimate.find((item) => item['name'] === 'Fair');
        return selected ? selected['value'] : 0;
    };

    getTradeInEstimateExcellentValue = (tradeIn: ITradeInState) => {
        if (tradeIn && tradeIn.estimate.length === 0) {
            return tradeIn.value;
        }

        const estimate = this.getTradeInEstimate(tradeIn);
        const selected = estimate.find((item) => item['name'] === 'Excellent' || false);

        return selected ? selected['value'] : 0;
    };

    getTradeInValueFair = (tradeIn: ITradeInState) => {
        const tivSource = this.getTradeInValueSource(tradeIn);
        if (tivSource === source.ICO || tivSource === source.SITEDRIVER) {
            return (tradeIn && tradeIn.value) || 0;
        }
        return this.getTradeInEstimateFairValue(tradeIn);
    };

    getTradeInValueExcellent = (tradeIn: ITradeInState) => {
        const tivSource = this.getTradeInValueSource(tradeIn);
        if (tivSource === source.ICO || tivSource === source.SITEDRIVER) {
            return (tradeIn && tradeIn.value) || 0;
        }
        return this.getTradeInEstimateExcellentValue(tradeIn);
    };

    getTradeInLookupYears = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn) as ILookups;
        const years = getTradeInYearsObj(lookups) as ILookupsYear;
        return years.options || [];
    };

    getTradeInIsYearEnabled = (tradeIn: ITradeInState) => {
        const years = this.getTradeInLookupYears(tradeIn);
        return years.length > 0 ? true : false;
    };

    getTradeInIsYearLoading = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn) as ILookups;
        const years = getTradeInYearsObj(lookups) as ILookupsYear;
        return years.isLoading || false;
    };

    getTradeInLookupMakes = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn) as ILookups;
        const makes = getTradeInMakesObj(lookups) as ILookupsMake;
        return makes.options || [];
    };

    getTradeInIsMakeEnabled = (tradeIn: ITradeInState) => {
        const makes = this.getTradeInLookupMakes(tradeIn);
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return makes.length > 0 && vehicle.year !== '' ? true : false;
    };

    getTradeInIsMakeLoading = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn) as ILookups;
        const makes = getTradeInMakesObj(lookups) as ILookupsMake;
        return makes.isLoading || false;
    };

    getTradeInLookupModels = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn);
        const models = getTradeInModelsObj(lookups);
        return models.options || [];
    };

    getTradeInIsModelEnabled = (tradeIn: ITradeInState) => {
        const models = this.getTradeInLookupModels(tradeIn);
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return !!(models.length > 0 && vehicle.make?.id);
    };

    getTradeInIsModelLoading = (tradeIn: ITradeInState) => {
        const lookups = getTradeInLookupsObj(tradeIn) as ILookups;
        const models = getTradeInModelsObj(lookups) as ILookupsModel;
        return models.isLoading || false;
    };

    getTradeInOwnership = (tradeIn: ITradeInState) => {
        return tradeIn.ownership || '';
    };

    getTradeInVehicle(tradeIn: ITradeInState): ITradeInVehicle {
        return this.getTradeInVehicleObj(tradeIn) as ITradeInVehicle;
    }

    // Trade-in Saved - only updated when completing the trade in

    getTradeInSavedObj(tradeIn): Partial<ITradeInSaved> {
        return tradeIn?.saved || {};
    }

    getTradeInSavedVehicle(tradeIn: ITradeInState): ITradeInVehicle {
        const saved = this.getTradeInSavedObj(tradeIn);
        return saved.vehicle || {};
    }

    getTradeInSavedVehicleOpts(tradeIn: ITradeInState): any {
        const saved = this.getTradeInSavedObj(tradeIn);
        return saved.vehicleOptions || {};
    }

    getTradeInSavedMileage(tradeIn: ITradeInState): number {
        const saved = this.getTradeInSavedVehicle(tradeIn);
        return saved.mileage || 0;
    }

    getTradeInSavedZip(tradeIn: ITradeInState): string {
        const saved = this.getTradeInSavedObj(tradeIn);
        return saved.zip || '';
    }

    getTradeInSavedAmountOwed(tradeIn: ITradeInState): number {
        const saved = this.getTradeInSavedObj(tradeIn);
        return saved.amountOwed || 0;
    }

    getTradeInIsValid = (tradeIn: ITradeInState, state: IStateTree) => {
        // TODO: remove hiddenOwnership instances when ownership dropdown for iDeal creditProvider is no more requied to be hidden
        const hiddenOwnership =
            this.additionalSelectors.dealerSelectors.getCreditProvider(state) === 'ideal' &&
            this.additionalSelectors.dealerSelectors.hasNoTradeOwnership(state);
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        const ownership = this.getTradeInOwnership(tradeIn);
        const mileage = tradeIn.vehicle ? tradeIn.vehicle.mileage : null;
        const validateMileageAndZip = validation.validateForMileageValues(mileage) && validator.validateZipCode(tradeIn.zip, 'US');
        const validateOwnership = hiddenOwnership ? ownership === '' : ownership !== '';
        return (
            vehicle.year !== '' &&
            !!vehicle.make?.id &&
            !!vehicle.model?.id &&
            vehicle.trim?.id?.length > 0 &&
            validateOwnership &&
            validateMileageAndZip
        );
    };

    getTradeVehicleNeedsFetch = (tradeIn: ITradeInState, state: IStateTree) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return (vehicle.year === '' || !vehicle.make?.id || !vehicle.model?.id || vehicle.trim?.id?.length < 1) && !!vehicle.vin;
    };

    getTradeInHasACV = (state: IStateTree) => {
        return !!state.tradeIn?._c;
    };

    getOemTradeInIsValid = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);

        return !!vehicle.year && vehicle.make?.id?.length > 0 && vehicle.model?.id?.length > 0 && vehicle.trim?.id?.length > 0;
    };

    getTradeInComponentRaw(state: IStateTree): ITradeInComponentState {
        return state.tradeInComponent;
    }

    getTradeInComponent = (state: IStateTree): ITradeInComponentState => {
        return this.getTradeInComponentRaw(state) || ({} as ITradeInComponentState);
    };

    getTradeIn = (state: IStateTree): ITradeInState => {
        return state.tradeIn || ({} as ITradeInState);
    };

    getTradeInVehicleId = (tradeIn: ITradeInState) => {
        const vehicle = this.getTradeInVehicleObj(tradeIn);
        return vehicle.trim.vehicleId || '';
    };

    getTradeInOptionInConflict = (tradeIn: ITradeInState) => {
        const conflicts = isEmpty(tradeIn.conflicts) ? null : tradeIn.conflicts;
        let optionInConflict = null;
        let categoryInConflict = null;
        let optionInConflictIndex = null;

        if (conflicts) {
            optionInConflict = conflicts.find((conflict) => conflict.lhs === true && conflict.path[3] === 'isSelected');
        }

        if (optionInConflict) {
            categoryInConflict = optionInConflict.path[0];
            optionInConflictIndex = optionInConflict.path[2];
        }

        return {
            categoryInConflict,
            optionInConflictIndex
        };
    };

    isEquipOptionsEstimate1InProgress(tradeIn: ITradeInState): boolean {
        return (tradeIn as ITradeInComponentState).isEquipOptionsEstimate1InProgress || false;
    }

    getTradeInCurrentLocationRoute = (state: IStateTree) => {
        const { tradeInComponent } = state;
        let currentRoute = tradeInComponent.currentUserLocationRoute;
        if (this.isTradeInCompleted(state) || !currentRoute) {
            currentRoute = TradeInRoutes.TRADE_VEHICLE_INFO;
        } else {
            return currentRoute;
        }

        return currentRoute;
    };

    getTradeInCurrentLocationRouteP202(state: IStateTree) {
        const { tradeInComponent } = state;
        // default route is TRADE_IN_SEARCH
        let currentRoute = tradeInComponent.currentUserLocationRoute || TradeInRoutes.TRADE_IN_SEARCH;
        if (this.isTradeInCompleted(state) && this.hasTradeIn(state)) {
            currentRoute = TradeInRoutes.TRADE_VEHICLE_INFO;
        }
        return currentRoute;
    }

    isSubaruGTPV2ServiceEnabled() {
        return globalConfigFeatureToggleSelectors.isSubaruGTPV2ServiceEnabled();
    }

    getTradeInCurrentLocationRouteForIco = (state: IStateTree) => {
        const { tradeInComponent } = state;

        let currentRoute = tradeInComponent.currentUserLocationRoute;

        if (this.isTradeInCompleted(state)) {
            currentRoute = '/equity';
        } else if (this.isP202RedesignEnabled(state) && this.getTradeDecisionPage(state)) {
            currentRoute = '/tradeInDecision';
        } else {
            currentRoute = '/tradeInIco';
        }
        return currentRoute;
    };

    checkIsSubaruGtpEligible = (state: IStateTree): boolean => {
        const isCompleted = this.isTradeInCompleted(state);
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        const make = this.getTradeInVehicleMake(tradeInComponent).name || '';
        const tradeInVehicleYear = this.getTradeInVehicleYear(tradeInComponent)?.trim();
        const vin = this.getTradeInVehicleVin(tradeInComponent);
        const year = Number(tradeInVehicleYear);
        if (!tradeInVehicleYear || isNaN(year)) {
            return false;
        }
        const thisYear = new Date().getFullYear();
        const isEligible = make.toLowerCase() === 'subaru' && thisYear - year <= 8;
        return isCompleted ? isEligible && !!vin : isEligible;
    };

    isSubaruGtpEligible = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.isSubaruGtpEligible || false;
    };

    inTradeInSubaruGtpFlow = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.inTradeInSubaruGtpFlow || false;
    };

    getTradeInSubaruGtpFlowError = (state: IStateTree): ITradeInSubaruGtpFlowError => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.tradeInSubaruGtpFlowError;
    };

    isTradeInCompleted = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state);
        const tradeIn = this.getTradeIn(state);
        const isTradeInComponentCompleted = tradeInComponent?.isCompleted || false;
        const isTradeInCompleted = tradeIn?.isCompleted || false;

        const shouldIncludeTradeIn = this.getShouldIncludeTradeIn(tradeInComponent);
        const includesTradeIn = shouldIncludeTradeIn !== false;

        return includesTradeIn && (isTradeInComponentCompleted || isTradeInCompleted);
    };

    isTradeInUnavailable = (state: IStateTree): boolean => {
        if (this.isP202RedesignEnabled(state)) {
            return false;
        }
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.isUnavailable || false;
    };

    isTradeInSkipped = (state: IStateTree): boolean => {
        const isTradeInComponentSkipped = this.getTradeInComponent(state)?.skippedTradeIn || false;
        const isTradeInSkipped = this.getTradeIn(state)?.isSkipped || false;
        return isTradeInComponentSkipped || isTradeInSkipped;
    };

    isTradeValuationReset = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.isTradeValuationReset || false;
    };

    showTradeCard = (state: IStateTree): boolean => {
        if (this.isTradeInIcoEnabled(state)) {
            return featureToggleSelectors.isTradeInAppEnabled(state);
        } else {
            return (
                featureToggleSelectors.isTradeInAppEnabled(state) &&
                this.additionalSelectors.dealerSelectors.isTradeValuationKbb(state)
            );
        }
    };

    isTradeInAppCardCompleted = (state: IStateTree): boolean => {
        return this.isTradeInCompleted(state);
    };

    isTradeSaveFailed = (state: IStateTree): boolean => {
        return this.getTradeInComponent(state).tradeSaveFailed;
    };

    isTradeSavePending = (state: IStateTree): boolean => {
        return this.getTradeInComponent(state).tradeSavePending;
    };

    hasTradeInChanged = (state: IStateTree): boolean => {
        return this.getTradeInComponent(state).hasTradeInChanged;
    };

    tradeInStatus = (state: IStateTree): NavigationItemStatus => {
        const offerSubmitted = this.additionalSelectors.offerSelectors.getHasBeenSent(state);
        const hasChanged = this.hasTradeInChanged(state);
        const isCompleted = this.isTradeInCompleted(state);
        const tradeInSent = this.isTradeInSent(state);
        const creditAppCompleted = this.additionalSelectors.creditSelectors.isCreditAppCompleted(state);
        const creditOverwriteBlockToggle = featureToggleSelectors.creditOverwriteBlockEnabled(state);
        const isOfferFromDealer = this.additionalSelectors.offerSelectors.isOfferFromDealer(state);

        const isTradeInOnCreditLock = creditOverwriteBlockToggle && creditAppCompleted;
        const isTradeInOnSentState = isOfferFromDealer
            ? tradeInSent && !hasChanged && isCompleted
            : tradeInSent && offerSubmitted && !hasChanged && isCompleted;
        const isTradeInOnReadyToSend = !hasChanged && isCompleted;
        const isDDP = this.additionalSelectors.routeSelectors.isDDPPage(state);

        if (isTradeInOnCreditLock && !isDDP) {
            return NavigationItemStatus.CREDIT_LOCK;
        } else if (isTradeInOnSentState) {
            return NavigationItemStatus.SENT;
        } else if (isTradeInOnReadyToSend) {
            return NavigationItemStatus.READY_TO_SEND;
        } else if (hasChanged) {
            return NavigationItemStatus.DEFAULT;
        } else {
            return NavigationItemStatus.DEFAULT;
        }
    };

    isTradeInSent = (state: IStateTree) => {
        return state.tradeInComponent.tradeInSent || false;
    };

    isTradeInDisabled = (state: IStateTree): boolean => {
        const creditOverwriteBlockToggle = featureToggleSelectors.creditOverwriteBlockEnabled(state);
        const isPaymentBuildingCardsEnabled = featureToggleSelectors.isPaymentBuildingCardsEnabled(state);
        if (creditOverwriteBlockToggle && !isPaymentBuildingCardsEnabled) {
            return this.additionalSelectors.creditSelectors.isCreditAppCompleted(state);
        }
        return false;
    };

    getExpirationDate = (tradeIn: ITradeInState) => {
        if (tradeIn.expirationDate) {
            return tradeIn.expirationDate;
        }
        // if there is no trade in expiration date, create one to match the ui logic (6 days non-ico);
        const expDate = new Date();
        expDate.setDate(expDate.getDate() + 6);
        const expDateStr = new Date(expDate).toISOString();
        return expDateStr; // TODO: Fix state mutation here
    };

    getTradeInForOffer = (tradeIn: ITradeInState) => {
        if (!isEmpty(tradeIn)) {
            return {
                amountOwed: this.getTradeInAmountOwed(tradeIn),
                color: this.getTradeInVehicleColor(tradeIn),
                condition: this.getTradeInVehicleCondition(tradeIn),
                expirationDate: this.getExpirationDate(tradeIn),
                shouldIncludeTradeIn: this.getAmountAppliedToFinancing(tradeIn),
                isCompleted: true,
                isSkipped: false,
                make: this.getTradeInVehicleMakeName(tradeIn),
                makeId: this.getTradeInVehicleMakeId(tradeIn),
                mileage: this.getTradeInVehicleMileage(tradeIn),
                model: this.getTradeInVehicleModelName(tradeIn),
                modelId: this.getTradeInVehicleModelId(tradeIn),
                offerDate: tradeIn.offerDate || '',
                offerId: tradeIn.offerId || '',
                owed: tradeIn.amountOwed || 0,
                ownershipType: tradeIn.ownership || null,
                source: tradeIn.source || tradeIn.tivSource || '',
                status: tradeIn.status || '',
                trim: this.getTradeInVehicleTrimName(tradeIn),
                trimId: `${this.getTradeInVehicleTrimId(tradeIn)}`,
                value: tradeIn.value || 0,
                vehicleId: `${this.getTradeInTrimVehicleId(tradeIn)}`,
                vehicleOptions: this.getTradeInVehicleOptions(tradeIn),
                vin: this.getTradeInVehicleVin(tradeIn),
                year: this.getTradeInVehicleYear(tradeIn),
                valueSource: this.getValueSource(tradeIn),
                valueComments: this.getValueComments(tradeIn)
            };
        }

        return {};
    };

    isSearchingForVin = (state: IStateTree) => {
        return state.tradeInComponent.isSearchingForVin || false;
    };

    isSearchingForYMMT = (state: IStateTree) => {
        return state.tradeInComponent.isSearchingForYMMT || false;
    };

    getSearchResultsForVin = (state: IStateTree) => {
        return state.tradeInComponent.searchResultsForVin || [];
    };

    getSearchResultsForYMMT = (state: IStateTree) => {
        return state.tradeInComponent.searchResultsForYMMT || [];
    };

    isTradeInStatusSent = (state: IStateTree): boolean => this.tradeInStatus(state) === NavigationItemStatus.SENT;

    getTradeInCompletionDate = (state: IStateTree): Date | undefined => {
        if (this.isTradeInStatusSent(state)) {
            // TODO: Use when the activity completion date when available
            let completionDate = new Date(offerSelectors.getOfferCreationDateString(state));

            const tradeInCompletionDate = state.tradeIn.tradeInCompletionDate;
            if (tradeInCompletionDate) {
                completionDate = new Date(tradeInCompletionDate);
            }

            return completionDate;
        } else {
            return undefined;
        }
    };
    getTradeInStatusText = (state: IStateTree): string => {
        let statusText = 'Trade-In';
        if (this.isTradeInStatusSent(state)) {
            const { make, model, mileage, year } = this.getTradeInForOffer(state.tradeInComponent);
            statusText = `Trading-In a **${year} ${make} ${model}** with **${formatUtils.formatNumber(mileage)} miles**`;
        }
        return statusText;
    };
    getTradeInStatus = (state: IStateTree): any => {
        // Uncomment the 2 consts after this sentence when the IMenu gets these types once the work
        // related to tech-debt story for error-handling is completed
        // const tradeIn = getTradeIn(state);
        // const { isHidden, isUnavailable } = tradeIn;
        // TODO: EValuate these from TradeIn
        // TODO: isHidden and isUnavailable props will be available when the tech-debt story on
        // error handling from server side is addressed. Until then their value will remain false
        // here and after that we can add conditional logic for error handling
        const isHidden = false;
        return {
            isHidden,
            isUnavailable: this.isTradeInUnavailable(state),
            completionDate: this.getTradeInCompletionDate(state),
            statusText: this.getTradeInStatusText(state),
            className: 'dr-ui-activity-status-item-trade-in'
        };
    };

    getTradeInErrorCount = (state: IStateTree): number => {
        return state.tradeInComponent.errorCount || 0;
    };

    getTestDriveRequestFromTradeIn = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.testDriveRequestFromTradeIn || false;
    };

    getTradePaymentFailed = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.tradePaymentFailed || false;
    };

    shouldRevertPaymentOnTradeFailure = (action: any) => {
        return action?.actionForPayment === Actions.UPDATED_TRADE_IN;
    };

    isICOTradeAlreadySubmitted = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.isICOTradeAlreadySubmitted || false;
    };

    isExternalIcoCompleted = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent.isExternalIcoCompleted || false;
    };

    isTradeUpdateRequired = (state: IStateTree): boolean => {
        const isICOTradeSubmitted = this.isICOTradeAlreadySubmitted(state);
        const isTradeInCompleted = this.isTradeInCompleted(state);
        return isTradeInCompleted || isICOTradeSubmitted;
    };

    isShopperEnteredTrade = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        const { valueSource, value, isShopperEnteredTrade } = tradeInComponent;
        const isShopperEnteredTradeEnabled = featureToggleSelectors.isShopperEnteredTradeEnabled(state);
        const isManualEntryFormFilled = value > 0 && !!valueSource;
        return isShopperEnteredTradeEnabled && (isManualEntryFormFilled || isShopperEnteredTrade);
    };

    getTradeInCurrentRoute = (state: IStateTree): string => {
        return state?.router?.location?.pathname || '/';
    };

    hideKBBDisclaimer = (state: IStateTree): boolean => {
        const route = this.getTradeInCurrentRoute(state);
        const isShopperEnteredTrade = this.isShopperEnteredTrade(state);
        return isShopperEnteredTrade && route !== TRADE_IN_DECISION;
    };

    getValueSource = (tradeIn: ITradeInComponentState | ITradeInState): string => {
        return tradeIn?.valueSource || '';
    };

    getValueComments = (tradeIn: ITradeInComponentState | ITradeInState): string => {
        return tradeIn?.valueComments || '';
    };

    isTradeSourceSubaruGTP = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent?.source === 'SUBARUGTP';
    };

    isOemInfoRequestFailed = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent?.isOemInfoRequestFailed;
    };

    isOemInfoRequestInProgress = (state: IStateTree): boolean => {
        const tradeInComponent = this.getTradeInComponent(state) as ITradeInComponentState;
        return tradeInComponent?.isOemInfoRequestInProgress;
    };

    hasDealChangeMessageBoxBeenClosed = (state: IStateTree): boolean => {
        return this.getTradeInComponent(state)?.dealChangeMessageHasBeenClosed === true;
    };

    getTradeInEntryPoint = (state: IStateTree): string => {
        return this.getTradeInComponent(state)?.entryPoint || '';
    };

    getPayoffQuote = (state: IStateTree): ITradeInPayoffQuote | undefined => {
        const { tradeIn } = state;
        const payoffQuote = isPayoffQuoteAvailable(tradeIn?.payoffQuote) ? tradeIn.payoffQuote : undefined;

        return tradeIn ? payoffQuote : undefined;
    };

    tradeInValuationProviderDefault = (state: IStateTree): string => {
        return state.dealer.tradeInValuationProviderDefault;
    };

    isTradeInValuationProviderKBB = (state: IStateTree): boolean => {
        return state.dealer.tradeInValuationProviderKBB;
    };

    isTradeInValuationProviderKBBICO = (state: IStateTree): boolean => {
        return state.dealer.tradeInValuationProviderKBBICO;
    };

    isTradeInValuationProviderKBBICOSalesView = (state: IStateTree): boolean => {
        return state.dealer.tradeInValuationProviderKBBICOSalesView;
    };

    isTradeInValuationProviderKBBSalesView = (state: IStateTree): boolean => {
        return state.dealer.tradeInValuationProviderKBBSalesView;
    };

    isTradeInValuationProviderManual = (state: IStateTree): boolean => {
        return state.dealer.tradeInValuationProviderManual;
    };

    isTradeInValuationProviderManualSalesView = (state: IStateTree): boolean => {
        return state.dealer.tradeInValuationProviderManualSalesView;
    };

    tradeInValuationProviderDefaultSalesView = (state: IStateTree): string => {
        return state.dealer.tradeInValuationProviderDefaultSalesView;
    };

    isShowAppraisalValueScreenSalesView = (state: IStateTree): boolean => {
        return state.dealer.showAppraisalValueScreenSalesView;
    };

    getShouldIncludeTradeIn = (tradeIn: ITradeInState): boolean | undefined => {
        return tradeIn.shouldIncludeTradeIn;
    };
}

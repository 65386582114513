// externals
import * as React from 'react';

// libraries
import { INextStepCardDetails, Modal, PageUI } from '@makemydeal/dr-activities-common';
import { IconFactory } from '@makemydeal/dr-activities-common';

// utils
import { formatDollarsAndCents } from '../../../../store/mmd/formatUtils';
import { milesToStringShort } from '../../../../store/mmd/uiHelpersUtil';

// config
import config from '../../../../store/mmd/config';

// components
import TradePage from '../../../../common/components/TradePage/TradePage';
import { errorMessageUserIsDealer, getErrorMessageUserIsNotDealer } from '../ManualSummary/constants';
import RulesUI from '../../../../common/components/subaruEligibility/RulesUI';

// types
import { StepperRoute } from '../../../../types';

export interface IGTPSummaryUIStateProps {
    calculatedTradeInValue: number;
    cardDetails: INextStepCardDetails;
    dealerPhoneNumber: string;
    isLeadFormEnabled: boolean;
    isLeadFormSubmitted: boolean;
    isTradeLeadFormEnabled: boolean;
    loanBalance: number;
    make: string;
    model: string;
    showShareDeal: boolean;
    stepperRoutes?: StepperRoute[];
    subaruGtpLogoAlt?: any; // This is purely for local dev use (storybook can't show the static assets)
    tradeInValue: number;
    tradePaymentFailed: boolean;
    userIsDealer: boolean;
    userIsDesking: boolean;
    vehicleCondition: string;
    vehicleMileage: number;
    year: string;
    ghostVinDealerConfig: boolean;
    isGhostVinEnabled: boolean;
    actionButtonText: string;
    showActionButton: boolean;
    backRoute: string;
}

export interface IManualSummaryUIDispatchProps {
    dispatchAnalytics: (type: string, description: string) => void;
    editTradeIn: () => void;
    goToRoute: (route: string) => void;
    loadLeadForm: (skiplink: boolean) => void;
    removeTrade: () => void;
    termsDetailsClicked: () => void;
    tradeInEstimateDisplayed: () => void;
    goToNextStep: () => void;
}

interface IManualSummaryUIState {
    showPaymentError: boolean;
    showModal: boolean;
}

export interface IManualSummaryUIProps extends IGTPSummaryUIStateProps, IManualSummaryUIDispatchProps {}

class SubaruGTPSummaryUI extends React.Component<IManualSummaryUIProps, IManualSummaryUIState> {
    constructor(public props) {
        super(props);
        this.state = {
            showPaymentError: props.tradePaymentFailed,
            showModal: false
        };
    }

    componentDidMount() {
        const {
            loadLeadForm,
            isTradeLeadFormEnabled,
            isLeadFormSubmitted,
            userIsDealer,
            tradeInEstimateDisplayed,
            isLeadFormEnabled
        } = this.props;

        if (!isLeadFormSubmitted && isLeadFormEnabled) {
            if (!userIsDealer) {
                loadLeadForm(!isTradeLeadFormEnabled);
            } else {
                tradeInEstimateDisplayed();
            }
        } else {
            tradeInEstimateDisplayed();
        }
    }

    componentDidUpdate(prevProps: IManualSummaryUIProps) {
        if (prevProps.isLeadFormSubmitted === false && this.props.isLeadFormSubmitted) {
            this.props.tradeInEstimateDisplayed();
        }
        if (prevProps.tradePaymentFailed !== this.props.tradePaymentFailed) {
            this.setState({ showPaymentError: this.props.tradePaymentFailed });
        }
    }

    handlePencilEditClicked = () => {
        this.props.editTradeIn();
    };

    renderError = () => {
        const errorMessage = this.props.userIsDealer
            ? errorMessageUserIsDealer
            : getErrorMessageUserIsNotDealer(this.props.dealerPhoneNumber);
        return this.state.showPaymentError ? (
            <div className="trade-in-payment-failed">
                <i className="fa fa-info-circle"></i>
                <p>
                    {errorMessage}
                    <a href="#" onClick={() => this.props.removeTrade()}>
                        Remove Trade-In
                    </a>
                </p>
            </div>
        ) : null;
    };

    renderTradeConfirmationHeader() {
        const { staticImages } = config;
        const { year, make, model, calculatedTradeInValue, tradeInValue } = this.props;

        return (
            <div className="trade-in-iw-estimate">
                {tradeInValue ? (
                    <IconFactory icon="checkmark" className="icon checkmark" staticImages={staticImages} />
                ) : (
                    <IconFactory icon="clock" className="icon clock" staticImages={staticImages} />
                )}
                <div className="estimated-value">
                    <div className="estimated-value__teaser common-header">
                        Your {year} {make} {model} has an
                    </div>
                    <div className={`estimated-value__content${tradeInValue === 0 || !tradeInValue ? '__pa' : ''} common-header`}>
                        <div className="estimated-value__content--label">Estimated Trade-In Value*:&nbsp;</div>
                        {tradeInValue && calculatedTradeInValue > 0 && (
                            <div className="estimated-value__content--value positive">
                                {formatDollarsAndCents(calculatedTradeInValue)}
                            </div>
                        )}
                        {tradeInValue && calculatedTradeInValue < 0 && (
                            <div className="estimated-value__content--value negative">
                                {formatDollarsAndCents(calculatedTradeInValue)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { staticImages } = config;
        const {
            calculatedTradeInValue,
            dispatchAnalytics,
            goToRoute,
            hasAcv,
            make,
            model,
            stepperRoutes,
            subaruGtpLogoAlt,
            tradeInValue,
            userIsDealer,
            userIsDesking,
            vehicleCondition,
            vehicleMileage,
            year,
            loanBalance,
            ghostVinDealerConfig,
            isGhostVinEnabled,
            actionButtonText,
            showActionButton,
            goToNextStep,
            removeTrade,
            backRoute
        } = this.props;

        const pencilIcon = <IconFactory icon="pencilIcon" className="icon primary-link-icon" staticImages={staticImages} />;
        const editText = <span className="primary-link">Edit</span>;
        const shouldDisplayConditionLabel = vehicleCondition !== '';
        const subaruGtpLogo = subaruGtpLogoAlt || (
            <IconFactory icon="subaruGtpLogo" className="subaru-gtp-logo" staticImages={staticImages} />
        );

        let dealerDealDescriptionText = '';
        let dealerDealLinkText = '';

        if (isGhostVinEnabled) {
            dealerDealDescriptionText = ghostVinDealerConfig ? 'Submit the completed deal to the CRM for review' : '';
            dealerDealLinkText = ghostVinDealerConfig ? 'Send to CRM' : 'Submit for Review';
        }

        const handleSkipButtonClick = () => {
            removeTrade();
        };

        const handleBackButtonClick = () => {
            goToRoute(backRoute);
        };

        return (
            <>
                <TradePage
                    showCustomTitle
                    headerOnTop
                    stepperRoutes={stepperRoutes}
                    isStepperClickable={false}
                    stepLabelVisible={true}
                    pageTitle="We've Added Your Trade-In."
                    pageSubTitle=""
                    pageClass="trade-in-summary-page"
                    showShareDeal={false}
                    isDealerUser={userIsDealer}
                    isDeskingUser={userIsDesking}
                    staticAssetsConfig={staticImages}
                    cardDetails={null}
                    goToRoute={goToRoute}
                    dispatchAnalytics={dispatchAnalytics}
                    renderError={this.renderError}
                    dealerDealDescription={dealerDealDescriptionText}
                    dealerDealLinkText={dealerDealLinkText}
                    footerProps={{
                        onActionButtonClick: showActionButton ? goToNextStep : undefined,
                        buttonText: actionButtonText,
                        showBackButton: true,
                        showSkipButton: true,
                        skipButtonText: 'Remove Trade-In',
                        fullWidthLayout: true,
                        onSkipButtonClick: handleSkipButtonClick,
                        onBackButtonClick: handleBackButtonClick
                    }}
                >
                    <div className="trade-in-summary-content">
                        {this.renderTradeConfirmationHeader()}
                        <div className="trade-in-iw-vehicle container-fluid">
                            <div className="vehicle row">
                                <div className="vehicle column">
                                    <div className="row">
                                        <div className="vehicle-info-details">
                                            <div className="estimate-header">How'd we come up with the value?</div>
                                            <div className="vehicle-info__content">
                                                <div className="math-stack-iw-container">
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label subaru-gtp-label">
                                                            <div>Subaru Guaranteed </div>
                                                            <div>Trade-In Value</div>
                                                        </div>
                                                        <div className="math-stack-iw-value">
                                                            {!!tradeInValue && formatDollarsAndCents(tradeInValue)}
                                                        </div>
                                                    </div>{' '}
                                                    {loanBalance > 0 && (
                                                        <div className="math-stack-iw">
                                                            <div className="math-stack-iw-label">Amount You Owe</div>
                                                            <div className="math-stack-iw-value">
                                                                {loanBalance ? '-' : ''}
                                                                {formatDollarsAndCents(loanBalance)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <hr className="bar" />
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Trade-In Value</div>
                                                        {tradeInValue && calculatedTradeInValue > 0 && (
                                                            <div className="math-stack-iw-value positive">
                                                                {formatDollarsAndCents(calculatedTradeInValue)}
                                                            </div>
                                                        )}
                                                        {tradeInValue && calculatedTradeInValue < 0 && (
                                                            <div className="math-stack-iw-value negative">
                                                                {formatDollarsAndCents(calculatedTradeInValue)}
                                                            </div>
                                                        )}
                                                        {!tradeInValue && (
                                                            <div className="math-stack-iw-value">
                                                                Pending <br className="hide-lg" />
                                                                Appraisal
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="vehicle-info common-header">
                                            <div className="estimate-header flex">
                                                <div className="bold">Trade-In Details</div>
                                                {!hasAcv && (
                                                    <div
                                                        className={'primary-link-container pencil-edit-icon'}
                                                        onClick={this.handlePencilEditClicked}
                                                    >
                                                        {pencilIcon}
                                                        {editText}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="vehicle-info__content">
                                            <div className="vehicle-info__contentLeft">
                                                <div className="math-stack-iw-container">
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Year</div>
                                                        <div className="math-stack-iw-value">{year}</div>
                                                    </div>
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Make</div>
                                                        <div className="math-stack-iw-value">{make}</div>
                                                    </div>
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Model</div>
                                                        <div className="math-stack-iw-value">{model}</div>
                                                    </div>
                                                    {shouldDisplayConditionLabel && (
                                                        <div className="math-stack-iw">
                                                            <div className="math-stack-iw-label">Condition</div>
                                                            <div className="math-stack-iw-value">{vehicleCondition}</div>
                                                        </div>
                                                    )}
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Mileage</div>
                                                        <div className="math-stack-iw-value">
                                                            {milesToStringShort(vehicleMileage)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="vehicle">
                                    <div className="flex subaru-logo">{subaruGtpLogo}</div>
                                </div>
                            </div>
                            <div className="col-xs-12 trade-in-vehicle__gtprules">
                                Subaru Guaranteed Trade Program value is subject to verification. See&nbsp;
                                <a
                                    className="btn-link link primary-link"
                                    id="dr_trade_subaru_rules_link"
                                    onClick={() => this.setState({ showModal: true })}
                                >
                                    Program Rules and Eligibility
                                </a>
                            </div>
                        </div>
                    </div>
                </TradePage>
                <Modal
                    show={this.state.showModal}
                    elementToAttachModalTo=".checkout-wizard-layout__wrapper"
                    elementToBlur=".checkout-wizard-layout__container"
                    cls="dr_trade_subaru_rules_modal"
                >
                    <PageUI
                        pageClass="dr_trade_subaru_rules_page"
                        pageTitle="Rules and Eligibility"
                        footerProps={{
                            onActionButtonClick: () => this.setState({ showModal: false }),
                            showBackButton: false,
                            buttonText: 'Close',
                            disableActionButton: false
                        }}
                        dispatchAnalytics={dispatchAnalytics}
                    >
                        <RulesUI />
                    </PageUI>
                </Modal>
            </>
        );
    }
}

export default SubaruGTPSummaryUI;

// externals
import * as React from 'react';

// libraries
import { INextStepCardDetails, IVehicleOption } from '@makemydeal/dr-activities-common';
import { IconFactory, Modal, PageUI } from '@makemydeal/dr-activities-common';

// utils
import { formatDollarsAndCents } from '../../../../store/mmd/formatUtils';
import { milesToStringShort } from '../../../../store/mmd/uiHelpersUtil';
import * as tradeInRoutes from '../../../../utils/routes';

// config
import config from '../../../../store/mmd/config';

// components
import { KBBTradeInAdvisor } from './KBBTradeInAdvisor';
import RulesUI from '../../../../common/components/subaruEligibility/RulesUI';

// types
import { StepperRoute } from '../../../../types';

export interface ITradeSummaryUIStateProps {
    calculatedTradeInValue: number;
    cardDetails: INextStepCardDetails;
    currentDate: Date;
    dealerPhoneNumber: string;
    expirationDate: string;
    imageFPO: string;
    isSubaruGtpEligible?: boolean;
    isLeadFormEnabled: boolean;
    isLeadFormSubmitted: boolean;
    isManualEntryFlow: boolean;
    isTradeLeadFormEnabled: boolean;
    loanBalance: number;
    make: string;
    model: string;
    showShareDeal: boolean;
    stepperRoutes: StepperRoute[];
    subaruGtpLogoAlt?: any; // This is purely for local dev use (storybook can't show the static assets)
    tradeInValue: number;
    tradePaymentFailed: boolean;
    userIsDealer: boolean;
    userIsDesking: boolean;
    valueSource: string;
    vehicleCondition: string;
    vehicleId: string;
    vehicleMileage: number;
    vehicleOptions: IVehicleOption;
    vin?: string;
    year: string;
    zipCode: string;
    ghostVinDealerConfig: boolean;
    isGhostVinEnabled: boolean;
    actionButtonText: string;
    showActionButton: boolean;
}

export interface ITradeSummaryUIDispatchProps {
    dispatchAnalytics: (type: string, description: string) => void;
    editTradeIn: () => void;
    goToRoute: (route: string) => void;
    loadLeadForm: (skiplink: boolean) => void;
    termsDetailsClicked: () => void;
    tradeInEstimateDisplayed: () => void;
    removeTrade: () => void;
    goToNextStep: () => void;
}

interface ITradeSummaryUIState {
    showKbbDisclaimer: boolean;
    showPaymentError: boolean;
    showModal: boolean;
}

export interface ITradeSummaryUIProps extends ITradeSummaryUIStateProps, ITradeSummaryUIDispatchProps {}

class TradeSummaryUI extends React.Component<ITradeSummaryUIProps, ITradeSummaryUIState> {
    constructor(public props) {
        super(props);
        this.state = {
            showKbbDisclaimer: false,
            showPaymentError: props.tradePaymentFailed,
            showModal: false
        };
    }

    componentDidMount() {
        const {
            loadLeadForm,
            isTradeLeadFormEnabled,
            isLeadFormSubmitted,
            userIsDealer,
            tradeInEstimateDisplayed,
            isLeadFormEnabled
        } = this.props;

        if (!isLeadFormSubmitted && isLeadFormEnabled) {
            if (!userIsDealer) {
                loadLeadForm(!isTradeLeadFormEnabled);
            } else {
                tradeInEstimateDisplayed();
            }
        } else {
            tradeInEstimateDisplayed();
        }
    }

    componentDidUpdate(prevProps: ITradeSummaryUIProps) {
        if (prevProps.isLeadFormSubmitted === false && this.props.isLeadFormSubmitted) {
            this.props.tradeInEstimateDisplayed();
        }
        if (prevProps.tradePaymentFailed !== this.props.tradePaymentFailed) {
            this.setState({ showPaymentError: this.props.tradePaymentFailed });
        }
    }

    handlePencilEditClicked = () => {
        this.props.editTradeIn();
    };

    toggleKbbDisclaimer = () => {
        this.props.termsDetailsClicked();
        this.setState({ showKbbDisclaimer: !this.state.showKbbDisclaimer });
    };

    toggleShowPaymentError = () => {
        this.setState({ showPaymentError: !this.state.showPaymentError });
    };

    renderError = () => {
        const errorMessage = this.props.userIsDealer
            ? 'A payment could not be calculated based on the added trade-in value. Try removing the trade-in to receive a payment. '
            : `Oops, We couldn't calculate a monthly payment based on your trade-in value. Try removing your trade to receive a payment or contact us at ${this.props.dealerPhoneNumber}. `;
        return this.state.showPaymentError ? (
            <div className="trade-in-payment-failed">
                <i className="fa fa-info-circle"></i>
                <p>
                    {errorMessage}
                    <a href="#" onClick={() => this.props.removeTrade()}>
                        Remove Trade-In
                    </a>
                </p>
            </div>
        ) : null;
    };

    renderTradeConfirmationHeader() {
        const { staticImages } = config;
        const { year, make, model, loanBalance, calculatedTradeInValue, tradeInValue, isManualEntryFlow } = this.props;

        const className = 'estimated-value__subtext';

        return (
            <div className="trade-in-estimate">
                <IconFactory icon="checkmark" className="icon checkmark" staticImages={staticImages} />
                <div className="estimated-value">
                    <div className="estimated-value__teaser common-header hidden-xs">
                        Your {year} {make} {model} is worth
                    </div>
                    <div className="estimated-value__content common-header">
                        <div className="estimated-value__content--label">Estimated Trade-in Value:</div>
                        {calculatedTradeInValue > 0 ? (
                            <div className="estimated-value__content--value positive">
                                {formatDollarsAndCents(calculatedTradeInValue)}
                            </div>
                        ) : null}
                        {calculatedTradeInValue < 0 ? (
                            <div className="estimated-value__content--value negative">
                                {formatDollarsAndCents(calculatedTradeInValue)}
                            </div>
                        ) : null}
                    </div>
                    <div className={className}>
                        Your trade-in equity is the net of your Trade-In Value and any Outstanding Loan amount
                    </div>
                </div>
                {loanBalance > 0 && (
                    <div className="math-stack-container">
                        <div className="math-stack">
                            <div>
                                <div className="math-stack-label">{!isManualEntryFlow ? 'KBB Value' : 'Entered Value'}:</div>
                                <div className="math-stack-value">{formatDollarsAndCents(tradeInValue)}</div>
                            </div>
                            <div>
                                <div className="math-stack-label">Loan Balance:</div>
                                <div className="math-stack-value">{formatDollarsAndCents(loanBalance)}</div>
                            </div>
                            <div>
                                <div className="math-stack-label">Trade-In Value:</div>
                                {calculatedTradeInValue > 0 ? (
                                    <div className="math-stack-value positive">{formatDollarsAndCents(calculatedTradeInValue)}</div>
                                ) : null}
                                {calculatedTradeInValue < 0 ? (
                                    <div className="math-stack-value negative">{formatDollarsAndCents(calculatedTradeInValue)}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { staticImages, kbbTradeInApiKey, renderMenuProducts } = config;
        const {
            currentDate,
            dispatchAnalytics,
            expirationDate,
            goToRoute,
            isManualEntryFlow,
            make,
            model,
            stepperRoutes,
            userIsDealer,
            userIsDesking,
            valueSource,
            vehicleCondition,
            vehicleId,
            vehicleMileage,
            vehicleOptions,
            year,
            zipCode,
            ghostVinDealerConfig,
            isGhostVinEnabled,
            actionButtonText,
            showActionButton,
            goToNextStep,
            removeTrade
        } = this.props;

        const { showKbbDisclaimer } = this.state;
        const columnSize = 'col-sm-5';
        const pencilIcon = <IconFactory icon="pencilIcon" className="icon primary-link-icon" staticImages={staticImages} />;
        const editText = <span className="primary-link">Edit</span>;

        let dealerDealDescriptionText = '';
        let dealerDealLinkText = '';

        if (isGhostVinEnabled) {
            dealerDealDescriptionText = ghostVinDealerConfig ? 'Submit the completed deal to the CRM for review' : '';
            dealerDealLinkText = ghostVinDealerConfig ? 'Send to CRM' : 'Submit for Review';
        }

        const handleSkipButtonClick = () => {
            removeTrade();
        };

        const handleBackButtonClick = () => {
            goToRoute(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS);
        };

        return (
            <>
                <PageUI
                    pageClass="trade-in-summary-page"
                    pageTitle="Trade-In Vehicle"
                    headerOnTop
                    pageSubTitle="Determine how much your current vehicle is worth, and apply that value to your new vehicle."
                    showShareDeal={false}
                    isDealerUser={userIsDealer}
                    isDeskingUser={userIsDesking}
                    staticAssetsConfig={staticImages}
                    cardDetails={null}
                    goToRoute={goToRoute}
                    dispatchAnalytics={dispatchAnalytics}
                    renderError={this.renderError}
                    stepperRoutes={stepperRoutes}
                    stepLabelVisible={true}
                    isStepperClickable={false}
                    dealerDealDescription={dealerDealDescriptionText}
                    dealerDealLinkText={dealerDealLinkText}
                    footerProps={{
                        onActionButtonClick: showActionButton ? goToNextStep : undefined,
                        buttonText: actionButtonText,
                        showBackButton: true,
                        showSkipButton: true,
                        skipButtonText: 'Remove Trade-In',
                        fullWidthLayout: true,
                        onSkipButtonClick: handleSkipButtonClick,
                        onBackButtonClick: handleBackButtonClick
                    }}
                >
                    <div className="trade-in-summary-content">
                        {this.renderTradeConfirmationHeader()}
                        <div className="trade-in-vehicle container-fluid">
                            <div className="row">
                                <div className={`${columnSize} container vehicle-info-details`}>
                                    <div className="title common-header">
                                        Your Trade-In
                                        <div
                                            className="primary-link-container subaru-mobile-view"
                                            onClick={this.handlePencilEditClicked}
                                        ></div>
                                    </div>
                                    <div className="vehicle-info common-header">
                                        <div className="vehicle-info__ymm">
                                            {year} {make} {model}
                                            <div
                                                className="primary-link-container pencil-edit-icon"
                                                onClick={this.handlePencilEditClicked}
                                            >
                                                {pencilIcon}
                                                {editText}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vehicle-info__content">
                                        <div className="vehicle-info__contentLeft">
                                            {isManualEntryFlow && (
                                                <div>
                                                    Value Provided by: <span>{valueSource}</span>
                                                </div>
                                            )}
                                            <div>
                                                Condition: <span>{vehicleCondition}</span>
                                            </div>
                                            <div>
                                                Mileage: <span>{milesToStringShort(vehicleMileage)}</span>
                                            </div>
                                            <div>
                                                Zip Code: <span>{zipCode}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isManualEntryFlow && (
                                    <div className="col-sm-7 vehicle-info-kbb-value">
                                        <div className="kbb-trade-in-advisor-container">
                                            <KBBTradeInAdvisor
                                                apiKey={kbbTradeInApiKey}
                                                vehicleId={vehicleId}
                                                vehicleOptions={vehicleOptions}
                                                zipCode={zipCode}
                                                vehicleMileage={vehicleMileage}
                                                vehicleCondition={vehicleCondition}
                                                showExactValue={false}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!isManualEntryFlow && (
                                <div className="row">
                                    <div className="col-xs-12 trade-in-vehicle__tc">
                                        Actual price of this vehicle may vary. Estimate valid through&nbsp;
                                        {new Date(expirationDate).toLocaleDateString()}. &nbsp;
                                        <a className="primary-link" onClick={this.toggleKbbDisclaimer}>
                                            {showKbbDisclaimer ? 'Hide Terms and Conditions' : 'View Terms and Conditions'}
                                        </a>
                                        {showKbbDisclaimer && (
                                            <p className="kbb-trade-in-advisor-disclaimer">
                                                © {currentDate.getFullYear()} Kelley Blue Book Co., Inc. All rights reserved.{' '}
                                                {new Date(expirationDate).toLocaleDateString()} Edition for {zipCode}. Values and
                                                pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may
                                                vary. The value and pricing information displayed for a particular vehicle is based
                                                upon the specification, mileage and/or condition information provided by the person
                                                generating this report. Kelley Blue Book assumes no responsibility for errors or
                                                omissions.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {renderMenuProducts()}
                </PageUI>
                <Modal
                    show={this.state.showModal}
                    elementToAttachModalTo=".checkout-wizard-layout__wrapper"
                    elementToBlur=".checkout-wizard-layout__container"
                    cls={'dr_trade_subaru_rules_modal'}
                >
                    <PageUI
                        pageClass="dr_trade_subaru_rules_page"
                        pageTitle="Rules and Eligibility"
                        footerProps={{
                            onActionButtonClick: () => this.setState({ showModal: false }),
                            showBackButton: false,
                            buttonText: 'Close',
                            disableActionButton: false
                        }}
                        dispatchAnalytics={dispatchAnalytics}
                    >
                        <RulesUI />
                    </PageUI>
                </Modal>
            </>
        );
    }
}

export default TradeSummaryUI;

import * as tradeInSelectors from '../store/mmd/tradeIn';

// types
import { StepperRoute, SelectedOption, UniqueOption, TradeInAssertionSelectors } from '../types';
import { TRADEIN_SOURCE_TYPES } from '../improvedWorkflow/components/constants';

// constants
import * as routes from './routes';

// actions
import * as tradeInComponentActionCreators from '../store/actionCreators';
import { DECISION_TYPES } from '../store/actionCreators';
import { getGlobalSelector } from '../store/mmd/mmd';
import { decisionOptions, upperCase } from '../improvedWorkflow/components/decisionPage/TradeInDecisionUI';

export enum tradeInMethod {
    ICO,
    STANDARD,
    MANUAL_ENTRY,
    GTP
}

enum tradeInDecisionOptions {
    submitForAppraisal = 'dealerAppraisal',
    kbbEstimate = 'kbbGuide',
    kbbICO = 'kbbIco',
    subaruGTP = 'subaruGTP'
}

export const getTradeInSelectedOption = (option: string) => {
    switch (option) {
        case tradeInDecisionOptions.subaruGTP:
        case TRADEIN_SOURCE_TYPES.subarugtp:
            return upperCase(DECISION_TYPES.SUBARU_GTP);
        case tradeInDecisionOptions.submitForAppraisal:
        case TRADEIN_SOURCE_TYPES.manual:
            return upperCase(DECISION_TYPES.ENTER_OWN_VALUE);
        case tradeInDecisionOptions.kbbICO:
        case TRADEIN_SOURCE_TYPES.ico:
            return upperCase(DECISION_TYPES.ICO);
        case tradeInDecisionOptions.kbbEstimate:
        case TRADEIN_SOURCE_TYPES.kbb:
        default:
            return upperCase(DECISION_TYPES.TRADE_IN_VALUE);
    }
};

const findSelectedOptionIndex = (option: string): number | undefined => {
    return decisionOptions.find((item: any) => item.label === option)?.index;
};

const getSelectedIndexAndOption = (
    optionsToShow: number[],
    tradeInSourceOption: string,
    providerOption: string
): SelectedOption => {
    const selectedProviderOption = getTradeInSelectedOption(providerOption);
    const selectedProviderOptionIndex = findSelectedOptionIndex(selectedProviderOption);
    const selectedTradeInSourceOption = tradeInSourceOption ? getTradeInSelectedOption(tradeInSourceOption) : '';
    const selectedTradeInSourceOptionIndex = findSelectedOptionIndex(selectedTradeInSourceOption);
    const isTradeInSourceOptionValid = optionsToShow.some((item) => item === selectedTradeInSourceOptionIndex);

    return isTradeInSourceOptionValid
        ? { selectedOptionIndex: selectedTradeInSourceOptionIndex, selectedOptionValue: selectedTradeInSourceOption }
        : { selectedOptionIndex: selectedProviderOptionIndex, selectedOptionValue: selectedProviderOption };
};

export const getAmdOptionsToShow = (selectors: TradeInAssertionSelectors): tradeInMethod[] => {
    const optionsToShow = [];

    if (selectors.isTradeInValuationProviderKBBICO) optionsToShow.push(tradeInMethod.ICO);
    if (selectors.isTradeInValuationProviderKBB) optionsToShow.push(tradeInMethod.STANDARD);
    if (selectors.isTradeInValuationProviderManual) optionsToShow.push(tradeInMethod.MANUAL_ENTRY);
    if (selectors.isTradeInValuationProviderGTP && optionsToShow.length !== 1) optionsToShow.unshift(tradeInMethod.GTP);

    return optionsToShow;
};

export const getAmdSelectedOption = (
    state: any,
    optionsToShow: tradeInMethod[],
    isTradeInValuationProviderGTP: boolean
): SelectedOption => {
    const defaultProviderOption = isTradeInValuationProviderGTP
        ? tradeInDecisionOptions.subaruGTP
        : tradeInSelectors.tradeInValuationProviderDefault(state);
    const tradeInSourceOption = getGlobalSelector().additionalSelectors.tradeInSelectors.getTradeInSource(state);

    return getSelectedIndexAndOption(optionsToShow, tradeInSourceOption, defaultProviderOption);
};

export const getAmdSelectors = (state: any): TradeInAssertionSelectors => {
    const isTradeInValuationProviderKBB = tradeInSelectors.isTradeInValuationProviderKBB(state);
    const isTradeInValuationProviderKBBICO = tradeInSelectors.isTradeInValuationProviderKBBICO(state);
    const isTradeInValuationProviderManual = tradeInSelectors.isTradeInValuationProviderManual(state);
    const isTradeInValuationProviderGTP = tradeInSelectors.isSubaruGtpEligible(state);

    return {
        isTradeInValuationProviderKBB,
        isTradeInValuationProviderKBBICO,
        isTradeInValuationProviderManual,
        isTradeInValuationProviderGTP
    };
};

export const getSalesViewOptionsToShow = (selectors: TradeInAssertionSelectors): tradeInMethod[] => {
    const optionsToShow = [];

    if (selectors.isTradeInValuationProviderKBBICOSalesView) optionsToShow.push(tradeInMethod.ICO);
    if (selectors.isTradeInValuationProviderKBBSalesView) optionsToShow.push(tradeInMethod.STANDARD);
    if (selectors.isTradeInValuationProviderManualSalesView) optionsToShow.push(tradeInMethod.MANUAL_ENTRY);
    if (selectors.isTradeInValuationProviderGTP && optionsToShow.length !== 1) optionsToShow.unshift(tradeInMethod.GTP);

    return optionsToShow;
};

export const getSalesViewSelectedOption = (
    state: any,
    optionsToShow: tradeInMethod[],
    isTradeInValuationProviderGTP: boolean
): SelectedOption => {
    const defaultProviderSalesViewOption = isTradeInValuationProviderGTP
        ? tradeInDecisionOptions.subaruGTP
        : tradeInSelectors.tradeInValuationProviderDefaultSalesView(state);
    const tradeInSourceOption = getGlobalSelector().additionalSelectors.tradeInSelectors.getTradeInSource(state);

    return getSelectedIndexAndOption(optionsToShow, tradeInSourceOption, defaultProviderSalesViewOption);
};

export const getSalesViewSelectors = (state: any): TradeInAssertionSelectors => {
    const isTradeInValuationProviderKBBSalesView = tradeInSelectors.isTradeInValuationProviderKBBSalesView(state);
    const isTradeInValuationProviderKBBICOSalesView = tradeInSelectors.isTradeInValuationProviderKBBICOSalesView(state);
    const isTradeInValuationProviderManualSalesView = tradeInSelectors.isTradeInValuationProviderManualSalesView(state);
    const isTradeInValuationProviderGTP = tradeInSelectors.isSubaruGtpEligible(state);
    const isShowAppraisalValueScreenSalesView = tradeInSelectors.isShowAppraisalValueScreenSalesView(state);

    return {
        isTradeInValuationProviderKBBSalesView,
        isTradeInValuationProviderKBBICOSalesView,
        isTradeInValuationProviderManualSalesView,
        isTradeInValuationProviderGTP,
        isShowAppraisalValueScreenSalesView
    };
};

export const getTradeInSelectors = (state: any, isSalesView: boolean): TradeInAssertionSelectors => {
    return isSalesView ? getSalesViewSelectors(state) : getAmdSelectors(state);
};

export const getOptionsToShow = (tradeInSelectors: TradeInAssertionSelectors, isSalesView: boolean): tradeInMethod[] => {
    return isSalesView ? getSalesViewOptionsToShow(tradeInSelectors) : getAmdOptionsToShow(tradeInSelectors);
};

export const getSelectedOption = (
    state: any,
    optionsToShow: tradeInMethod[],
    isTradeInValuationProviderGTP: boolean,
    isSalesView: boolean
): SelectedOption => {
    return isSalesView
        ? getSalesViewSelectedOption(state, optionsToShow, isTradeInValuationProviderGTP)
        : getAmdSelectedOption(state, optionsToShow, isTradeInValuationProviderGTP);
};

export const getUniqueEstimateOption = (tradeInSelectors: TradeInAssertionSelectors, isSalesView: boolean): UniqueOption => {
    const optionsToShow = getOptionsToShow(tradeInSelectors, isSalesView);
    return { hasUniqueOption: optionsToShow.length === 1, uniqueOptionValue: optionsToShow[0] };
};

export const processStepperRoutes = (state: any, stepperRoutes: StepperRoute[], isSalesView: boolean): StepperRoute[] => {
    const tradeInAssertionSelectors = getTradeInSelectors(state, isSalesView);
    const uniqueEstimateOption = getUniqueEstimateOption(tradeInAssertionSelectors, isSalesView);
    const externalTradeInConditions = getExternalTradeInConditions(state);
    let filteredRoutes = [...stepperRoutes];
    if (uniqueEstimateOption.hasUniqueOption) {
        filteredRoutes = filteredRoutes.filter((r) => r.route !== routes.TRADE_IN_DECISION);
    }
    if (externalTradeInConditions.isPartialTrade) {
        filteredRoutes = filteredRoutes.filter((r) => r.route !== routes.TRADE_IN_DECISION);
        filteredRoutes[0].route = routes.TRADE_IN_EXTERNAL;
    } else if (externalTradeInConditions.hasExternalTradeRoute) {
        filteredRoutes[0].route = routes.TRADE_VEHICLE_INFO;
    }
    if (
        isSalesView &&
        !tradeInAssertionSelectors.isShowAppraisalValueScreenSalesView &&
        uniqueEstimateOption.uniqueOptionValue === tradeInMethod.MANUAL_ENTRY
    ) {
        filteredRoutes = filteredRoutes.filter((r) => r.name !== 'Details');
    }
    return filteredRoutes;
};

export const processStepperRoutesSubaruGTP = (state: any, stepperRoutes: StepperRoute[], isSalesView: boolean): StepperRoute[] => {
    const tradeInAssertionSelectors = getTradeInSelectors(state, isSalesView);
    const uniqueEstimateOption = getUniqueEstimateOption(tradeInAssertionSelectors, isSalesView);
    const filteredRoutes = stepperRoutes.filter((r) => r.route !== routes.TRADE_IN_DECISION);
    return uniqueEstimateOption.hasUniqueOption || (isSalesView && tradeInAssertionSelectors.isShowAppraisalValueScreenSalesView)
        ? filteredRoutes
        : stepperRoutes;
};

export const handleNavigationToManualEntry = (
    isSalesView: boolean,
    isShowAppraisalValueScreenSalesView: boolean,
    dispatch: any
): void => {
    if ((isSalesView && isShowAppraisalValueScreenSalesView) || !isSalesView) {
        dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(routes.TRADE_MANUAL_ENTRY));
    }
    if (isSalesView && !isShowAppraisalValueScreenSalesView) {
        const payload = { value: 0, comments: '', source: '' };
        dispatch(tradeInComponentActionCreators.updateTradeValueSource(payload));
        dispatch(tradeInComponentActionCreators.saveOrUpdateTradeIn());
    }
    dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(DECISION_TYPES.ENTER_OWN_VALUE));
};

export const getExternalTradeInConditions = (
    state: any
): { isPartialTrade: boolean; hasAmountOwed: boolean; hasExternalTradeRoute: boolean } => {
    const tradeInComponent = tradeInSelectors.getTradeInComponent(state);
    const { navigation } = state;
    const hasExternalTradeRoute = navigation?.initRedirectLink === routes.TRADE_IN_EXTERNAL;
    if (navigation?.routeIntent !== routes.TRADE_IN_EXTERNAL) {
        return { isPartialTrade: false, hasAmountOwed: false, hasExternalTradeRoute };
    }

    const { amountOwed = 0 } = tradeInComponent || {};

    const hasAmountOwed = amountOwed > 0;

    const result = { isPartialTrade: true, hasAmountOwed, hasExternalTradeRoute };
    return result;
};

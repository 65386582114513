import * as React from 'react';
import { Route, Redirect } from 'react-router';
import { IRootProps } from '../../components/RootProps';

import * as tradeInRoutes from '../../utils/routes';
import VehicleInfo from './vehicleInfo/VehicleInfo';
import TradeInDecision from './decisionPage/TradeInDecision';
import ManualEntry from './manualEntry/ManualEntry';
import TradeInSummary from './summary/Summary';
import VehicleCondition from './vehicleCondition/VehicleCondition';
import EquipmentOptionsStepOne from './equipmentOptions/EquipmentOptionsStepOne';
import EquipmentOptions from './equipmentOptions/EquipmentOptions';
import TradeInIco from './icoValuation/IcoValuation';
import { PropsRoute } from '@makemydeal/dr-activities-common';

const TradeInRouting: React.FC<IRootProps> = (props) => {
    const { currentRoute, isCompleted } = props;

    const getRedirectionRoute = () => {
        return isCompleted ? tradeInRoutes.TRADE_SUMMARY : currentRoute;
    };

    return (
        <div className="dr-p202-trade-in">
            <Route path={tradeInRoutes.TRADE_IN} render={() => <Redirect to={getRedirectionRoute()} />} />
            <Route path={tradeInRoutes.TRADE_IN_SEARCH} render={() => <Redirect to={getRedirectionRoute()} />} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_VEHICLE_INFO} component={VehicleInfo} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_IN_EXTERNAL} component={VehicleInfo} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_VEHICLE_CONDITION} component={VehicleCondition} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_EQUIPMENT_OPTIONS} component={EquipmentOptions} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE} component={EquipmentOptionsStepOne} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_IN_DECISION} component={TradeInDecision} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_MANUAL_ENTRY} component={ManualEntry} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_SUMMARY} component={TradeInSummary} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_IN_ICO} component={TradeInIco} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_SUBARU_ELIGIBILITY} component={VehicleInfo} />
        </div>
    );
};

export default TradeInRouting;
